import { TableGetOptions } from '../models/received-detail';
import { SubmitResponse } from '../models/submit-response';
import { createAction, props } from '@ngrx/store';
import {
  SaveUserSettings,
  UiUser,
  UserSession,
  UserSettings,
  SaveUserSession,
  UserEmailSettings,
  SaveUserQuickAccessPage,
  SaveUsersTrackHistory,
  SaveUserClass,
  PageInfo,
  SaveUserMHISettings,
  UsersPublicationSelection,
  SaveUserPubicationSelection,
  UserApplications,
  UserAccessToken,
  NotificationItem,
  UpdateNotification,
  Vendor,
  AddUserAlternateLoginName,
  UserAlternateLoginName,
  NotificationItemHistory,
  NDCAPharmacy,
  DisclaimerItem,
  UserApplicationPage,
  CSGID,
  LookerUrl,
  UserTokenRequest,
  UserApplicationInfo,
  UserHelpUrl
} from '../models/user';
import { Pharmacy, PharmacyGetOptions, UAMPharmacy } from '../models/pharmacy';
import { Application } from '../models/applications';
import { SubmittedSupportTicket } from '../models/sales-force-ticket';
import { HealthSystemValidationResponse } from 'src/app/models/health-system-view/health-system';

export enum UserActionTypes {
  INIT_USER = '[User] InitUser',
  INIT_USER_RES = '[User] InitUserResults',

  GET_SETTINGS = '[Silent Rest Call] [User] GetUserSettings',
  GET_SETTINGS_RES = '[Silent Rest Result] [User] GetUserSettingsResults',
  GET_SETTINGS_ERR = '[Silent Rest Error] [User] GetUserSettingsError',

  UPDATE_APPLICATION = '[Silent Rest Call] [User] ApplicationUpdate',
  UPDATE_APPLICATION_RES = '[Silent Rest Result] [User] ApplicationUpdateResults',
  UPDATE_APPLICATION_ERR = '[Rest Error] [User] ApplicationUpdateError',
  UPDATE_APPLICATION_AND_AUTHORIZE = '[Silent Rest Call] [User] UpdateUserApplicationAndAuthorize',
  UPDATE_APPLICATION_AND_AUTHORIZE_RES = '[Silent Rest Result] [User] UpdateUserApplicationAndAuthorizeResults',

  GET_SESSION = '[Rest Call] [User] SessionGet',
  GET_SESSION_RES = '[Rest Result] [User] SessionResults',
  GET_SESSION_ERR = '[Rest Error] [User] SessionError',

  UPDATE_SESSION = '[Rest Call] [User] SessionUpdate',
  UPDATE_SESSION_RES = '[Rest Result] [User] SessionUpdateResults',
  UPDATE_SESSION_ERR = '[Rest Error] [User] SessionUpdateError',

  UPDATE_RECALCULATE = '[Rest Call] [User] RecalculateUpdate',
  UPDATE_RECALCULATE_RES = '[Rest Result] [User] RecalculateUpdateResults',
  UPDATE_RECALCULATE_ERR = '[Rest Error] [User] RecalculateUpdateError',

  UPDATE_340BPOPUP = '[Rest Call] [User] Update340BPopUp',
  UPDATE_340BPOPUP_RES = '[Rest Result] [User] Update340BPopUpResults',
  UPDATE_340BPOPUP_ERR = '[Rest Error] [User] Update340BPopUpError',

  SAVE_SETTINGS = '[Rest Call] [User] SaveSettings',
  SAVE_SETTINGS_RES = '[Rest Result] [User] SaveSettingsResults',
  SAVE_SETTINGS_ERR = '[Rest Error] [User] SaveSettingsError',

  GET_PHARMACIES = '[Rest Call] [User] GetPharmacies',
  GET_PHARMACIES_RES = '[Rest Result] [User] GetPharmaciesResults',
  GET_PHARMACIES_ERR = '[Rest Error] [User] GetPharmaciesError',

  GET_ALL_PHARMACIES = '[Silent Rest Call] [User] GetAllPharmacies',
  GET_ALL_PHARMACIES_RES = '[Silent Rest Result] [User] GetAllPharmaciesResults',
  GET_ALL_PHARMACIES_ERR = '[Silent Rest Error] [User] GetAllPharmaciesError',

  GET_USER_EMAIL_SETTINGS = '[User] GetUserEmailSettings',
  GET_USER_EMAIL_SETTINGS_RES = '[User] GetUserEmailSettingsResults',
  GET_USER_EMAIL_SETTINGS_ERR = '[User] GetUserEmailSettingsError',

  UPDATE_USER_EMAIL_SETTINGS = '[Rest Call] [User] UpdateUserEmailSettings',
  UPDATE_USER_EMAIL_SETTINGS_RES = '[Rest Result] [User] UpdateUserEmailSettingsResults',
  UPDATE_USER_EMAIL_SETTINGS_ERR = '[Rest Error] [User] UpdateUserEmailSettingsError',

  UPDATE_USER_QUICK_ACCESS_PAGE = '[Rest Call] [User] UserQuickAccessPage',
  UPDATE_USER_QUICK_ACCESS_PAGE_RES = '[Rest Result] [User] UserQuickAccessPageResults',
  UPDATE_USER_QUICK_ACCESS_PAGE_ERR = '[Rest Error] [User] UserQuickAccessPageError',

  INSERT_USER_TRACK_HISTORY = '[Rest Call] [User] InsertUserTrackHistory',
  INSERT_USER_TRACK_HISTORY_RES = '[Rest Result] [User] InsertUserTrackHistoryResults',
  INSERT_USER_TRACK_HISTORY_ERR = '[Rest Error] [User] InsertUserTrackHistoryError',

  UPDATE_USER_CLASS = '[Rest Call] [User] UpdateUserClass',
  UPDATE_USER_CLASS_RES = '[Rest Result] [User] UpdateUserClassResults',
  UPDATE_USER_CLASS_ERR = '[Rest Error] [User] UpdateUserClassError',

  GET_PAGE_ID = '[Rest Call] [User] GetPageID',
  GET_PAGE_ID_RES = '[Rest Result] [User] GetPageIDResults',
  GET_PAGE_ID_ERR = '[Rest Error] [User] GetPageIDError',

  GET_USER_SUBMITTED_SUPPORT_TICKETS = '[Rest Call] [User] GetUserSubmittedSupportTickets',
  GET_USER_SUBMITTED_SUPPORT_TICKETS_RES = '[Rest Result] [User] GetUserSubmittedSupportTicketsResults',
  GET_USER_SUBMITTED_SUPPORT_TICKETS_ERR = '[Rest Error] [User] GetUserSubmittedSupportTicketsError',

  GET_USER_MHI_SETTINGS = '[Silent Rest Call] [User] GetUserMHISettings',
  GET_USER_MHI_SETTINGS_RES = '[Silent Rest Result] [User] GetUserMHISettingsResults',
  GET_USER_MHI_SETTINGS_ERR = '[Silent Rest Error] [User] GetUserMHISettingsError',

  UPDATE_USER_MHI_SETTINGS = '[Rest Call] [User] UpdateUserMHISettings',
  UPDATE_USER_MHI_SETTINGS_RES = '[Rest Result] [User] UpdateUserMHISettingsResults',
  UPDATE_USER_MHI_SETTINGS_ERR = '[Rest Error] [User] UpdateUserMHISettingsError',

  GET_USER_PUBLICATION_SELECTION = '[User] GetUsersPublicationSelections',
  GET_USER_PUBLICATION_SELECTION_RES = '[User] GetUsersPublicationSelectionsResults',
  GET_USER_PUBLICATION_SELECTION_ERR = '[User] GetUsersPublicationSelectionsError',

  UPDATE_USER_PUBLICATION_SELECTION = '[Rest Call] [User] UpdateUsersPublicationSelections',
  UPDATE_USER_PUBLICATION_SELECTION_RES = '[Rest Result] [User] UpdateUsersPublicationSelectionsResults',
  UPDATE_USER_PUBLICATION_SELECTION_ERR = '[Rest Error] [User] UpdateUsersPublicationSelectionsError',

  GET_USER_ALTERNATE_LOGIN_NAME = '[Rest Call] [User] GetUserAlternateLoginNameList',
  GET_USER_ALTERNATE_LOGIN_NAME_RES = '[Rest Result] [User] GetUserAlternateLoginNameListResults',
  GET_USER_ALTERNATE_LOGIN_NAME_ERR = '[Rest Error] [User] GetUserAlternateLoginNameListError',

  GET_USER_NOTIFICATION_LIST = '[Silent Rest Call] [User] GetUserNotificationList',
  GET_USER_NOTIFICATION_LIST_RES = '[Silent Rest Result] [User] GetUserNotificationListResults',
  GET_USER_NOTIFICATION_LIST_ERR = '[Silent Rest Error] [User] GetUserNotificationListError',

  UPDATE_USER_NOTIFICATION_LIST = '[Rest Call] [User] UpdateUserNotificationList',
  UPDATE_USER_NOTIFICATION_LIST_RES = '[Rest Result] [User] UpdateUserNotificationListResults',
  UPDATE_USER_NOTIFICATION_LIST_ERR = '[Rest Error] [User] UpdateUserNotificationListError',

  UPDATE_SELECTED_USER_NOTIFICATION_LIST = '[Rest Call] [User] UpdateSelectedUserNotificationList',
  UPDATE_SELECTED_USER_NOTIFICATION_LIST_RES = '[Rest Result] [User] UpdateSelectedUserNotificationListResults',
  UPDATE_SELECTED_USER_NOTIFICATION_LIST_ERR = '[Rest Error] [User] UpdateSelectedUserNotificationListError',

  GET_VENDOR_LIST = '[Rest Call] [User] GetVendorList',
  GET_VENDOR_LIST_RES = '[Rest Result] [User] GetVendorListResults',
  GET_VENDOR_LIST_ERR = '[Rest Error] [User] GetVendorListError',

  ADD_NEW_USER_ALTERNATE_LOGIN = '[Rest Call] [User] AddNewUserAlternateLogin',
  ADD_NEW_USER_ALTERNATE_LOGIN_RES = '[Rest Result] [User] AddNewUserAlternateLoginResults',
  ADD_NEW_USER_ALTERNATE_LOGIN_ERR = '[Rest Error] [User] AddNewUserAlternateLoginError',

  GET_USER_NOTIFICATION_HISTORY_LIST = '[Silent Rest Call] [User] GetUserNotificationhistoryList',
  GET_USER_NOTIFICATION_HISTORY_LIST_RES = '[Silent Rest Result] [User] GetUserNotificationhistoryListResults',
  GET_USER_NOTIFICATION_HISTORY_LIST_ERR = '[Silent Rest Error] [User] GetUserNotificationhistoryListError',

  GET_CSGID_LIST = '[Rest Call] [User] GetCSGIDList',
  GET_CSGID_LIST_RES = '[Rest Result] [User] GetCSGIDListResults',
  GET_CSGID_LIST_ERR = '[Rest Error] [User] GetCSGIDListError',

  SET_SELECTED_CSGID = '[User] SetSelectedCSGId',

  GET_LOOKER_URL = '[Silent Rest Call] [User] GetLookerUrl',
  GET_LOOKER_URL_RES = '[Silent Rest Result] [User] GetLookerUrlResults',
  GET_LOOKER_URL_ERR = '[Silent Rest Error] [User] GetLookerUrlError',

  GET_NDCA_PHARMACY = '[User] GetNDCAPharmacy',
  GET_NDCA_PHARMACY_RES = '[User] GetNDCAPharmacyResults',
  GET_NDCA_PHARMACY_ERR = '[User] GetNDCAPharmacyError',

  GETDISCLAIMER = '[Silent Rest Call] [User] GetDisclaimer',
  GETDISCLAIMER_RES = '[Silent Rest Result] [User] GetDisclaimerResult',
  GETDISCLAIMER_ERR = '[Silent Rest Error] [AutoRef] GetDisclaimerError',

  ACKNOWLEDGE = '[Rest Call] [User] acknowledge',
  ACKNOWLEDGE_RES = '[Rest Result] [User] acknowledgeResult',
  ACKNOWLEDGE_ERR = '[Rest Error] [AutoRef] acknowledgeResultError',

  GET_USER_LOGOUT = '[Rest Call] [User] Logout',
  GET_USER_LOGOUT_RES = '[Rest Result] [User] LogoutResult',
  GET_USER_LOGOUT_ERR = '[Rest Error] [User] LogoutError',

  GET_CORPORATE_PARENT_VALIDATION_STATUS = '[Silent Rest Call] [corpIdList] GetCorporateParentValidationStatus',
  GET_CORPORATE_PARENT_VALIDATION_STATUS_RES = '[Silent Rest Result] [corpIdList] GetCorporateParentValidationStatusResults',
  GET_CORPORATE_PARENT_VALIDATION_STATUS_ERR = '[Silent Rest Error] [corpIdList] GetCorporateParentValidationStatusError',

  GET_SELECTED_DASHBOARD = '[Silent Rest Call] [User] GetSelectedDashboard',
  GET_SELECTED_DASHBOARD_RES = '[Silent Rest Result] [User] GetSelectedDashboardResults',
  GET_SELECTED_DASHBOARD_ERR = '[Silent Rest Error] [User] GetSelectedDashboardError',

  REGENERATE_TOKEN = '[Rest Call] [User] RegenerateUserToken',
  REGENERATE_TOKEN_RES = '[Rest Result] [User] RegenerateUserTokenResults',
  REGENERATE_TOKEN_ERR = '[Rest Error] [User] RegenerateUserError',

  REGENERATE_TOKEN_APP_CHANGE = '[Silent Rest Call] [User] RegenerateUserTokenOnAppChange',
  REGENERATE_TOKEN_APP_CHANGE_RES = '[Silent Rest Result] [User] RegenerateUserTokenOnAppChangeResults',
  REGENERATE_TOKEN_APP_CHANGE_ERR = '[Rest Error] [User] RegenerateUserTokenOnAppChangeError',

  GET_UAM_USER_PHARMACY_SESSION = '[Rest Call] [User] GetUAMUserPharmacySession',
  GET_UAM_USER_PHARMACY_SESSION_RES = '[Rest Result] [User] GetUAMUserPharmacySessionResults',
  GET_UAM_USER_PHARMACY_SESSION_ERR = '[Rest Error] [User] GetUAMUserPharmacySessionError',

  GET_UAM_USER_APPLICATION_PAGES = '[Rest Call] [User] GetUAMUserApplicationPages',
  GET_UAM_USER_APPLICATION_PAGES_RES = '[Rest Result] [User] GetUAMUserApplicationPagesResults',
  GET_UAM_USER_APPLICATION_PAGES_ERR = '[Rest Error] [User] GetUAMUserApplicationPagesError',

  SHOW_CLIENT_SELECTION_PAGE = '[User] ShowClientSelectionPage',
  CLOSE_CLIENT_SELECTION_PAGE = '[User] CloseClientSelectionPage',

  SHOW_GATEWAY_CSGIDS = '[User] ShowGatewayCsgids',
  HIDE_GATEWAY_CSGIDS= '[User] HideGatewayCsgids',

  SHOW_ACCESS_DENIED_PAGE = '[User] ShowAccessDeniedPage',
  CLOSE_ACCESS_DENIED_PAGE = '[User] CloseAccessDeniedPage',
  CLEAR_GATEWAY_DATA = '[User] ClearGateWayData',

  GET_HELPAPPLICATION_URL = '[Silent Rest Call] [User] GetHelpApplicationUrl',
  GET_HELPAPPLICATION_URL_RES = '[Silent Rest Result] [User] GetHelpApplicationUrlResults',
  GET_HELPAPPLICATION_URL_ERROR = '[Silent Rest Error] [User] GetHelpApplicationUrlError'

}

export const GetHelpApplicationUrl = createAction(UserActionTypes.GET_HELPAPPLICATION_URL);
export const GetHelpApplicationUrlResult = createAction(UserActionTypes.GET_HELPAPPLICATION_URL_RES, props<{ userHelpUrl: UserHelpUrl }>());
export const GetHelpApplicationUrlError = createAction(UserActionTypes.GET_HELPAPPLICATION_URL_ERROR, props<{ message: string }>());

export const InitUser = createAction(UserActionTypes.INIT_USER);
export const InitUserResults = createAction(UserActionTypes.INIT_USER_RES, props<{ uiUser: UiUser }>());

export const GetUserSettings = createAction(UserActionTypes.GET_SETTINGS);
export const GetUserSettingsResults = createAction(UserActionTypes.GET_SETTINGS_RES, props<{ settings: UserSettings }>());
export const GetUserSettingsError = createAction(UserActionTypes.GET_SETTINGS_ERR, props<{ message: string }>());

export const UpdateUserApplication = createAction(UserActionTypes.UPDATE_APPLICATION, props<{ appId: number }>());
export const UpdateUserApplicationResults = createAction(UserActionTypes.UPDATE_APPLICATION_RES, props<{ appId: number }>());
export const UpdateUserApplicationError = createAction(UserActionTypes.UPDATE_APPLICATION_ERR, props<{ message: string }>());
export const UpdateUserApplicationAndAuthorize = createAction(UserActionTypes.UPDATE_APPLICATION_AND_AUTHORIZE, props<{ appId: number, pid: number }>());
export const UpdateUserApplicationAndAuthorizeResults = createAction(UserActionTypes.UPDATE_APPLICATION_AND_AUTHORIZE_RES, props<{ appId: number, pid: number }>());

export const UpdateUserSettings = createAction(UserActionTypes.SAVE_SETTINGS, props<{ saveData: SaveUserSettings }>());
export const UpdateUserSettingsResults = createAction(UserActionTypes.SAVE_SETTINGS_RES, props<{ saveData: SaveUserSettings }>());
export const UpdateUserSettingsError = createAction(UserActionTypes.SAVE_SETTINGS_ERR, props<{ message: string }>());

export const Update340BPopup = createAction(UserActionTypes.UPDATE_340BPOPUP, props<{ displayDate: number }>());
export const Update340BPopupResults = createAction(UserActionTypes.UPDATE_340BPOPUP_RES, props<{ userSetting: SaveUserSettings  }>());
export const Update340BPopupError = createAction(UserActionTypes.UPDATE_340BPOPUP_ERR, props<{ message: string }>());

export const GetUserSession = createAction(UserActionTypes.GET_SESSION);
export const GetUserSessionResults = createAction(UserActionTypes.GET_SESSION_RES, props<{ session: UserSession }>());
export const GetUserSessionError = createAction(UserActionTypes.GET_SESSION_ERR, props<{ message: string }>());

export const UpdateUserSession = createAction(UserActionTypes.UPDATE_SESSION, props<{ sessionData: SaveUserSession }>());
export const UpdateUserSessionResults = createAction(UserActionTypes.UPDATE_SESSION_RES, props<{ session: UserSession }>());
export const UpdateUserSessionError = createAction(UserActionTypes.UPDATE_SESSION_ERR, props<{ message: string }>());

export const UpdateRecalculate = createAction(UserActionTypes.UPDATE_RECALCULATE, props<{ sessionData: SaveUserSession }>());
export const UpdateRecalculateResults = createAction(UserActionTypes.UPDATE_RECALCULATE_RES, props<{ session: UserSession }>());
export const UpdateRecalculateError = createAction(UserActionTypes.UPDATE_RECALCULATE_ERR, props<{ message: string }>());

export const GetPharmacies = createAction(UserActionTypes.GET_PHARMACIES, props<{ searchText: string, appChange: number }>());
export const GetPharmaciesResults = createAction(UserActionTypes.GET_PHARMACIES_RES, props<{ pharmacies: UAMPharmacy[] }>());
export const GetPharmaciesError = createAction(UserActionTypes.GET_PHARMACIES_ERR, props<{ message: string }>());

export const GetAllPharmacies = createAction(UserActionTypes.GET_ALL_PHARMACIES);
export const GetAllPharmaciesResults = createAction(UserActionTypes.GET_ALL_PHARMACIES_RES, props<{ pharmacies: UAMPharmacy[] }>());
export const GetAllPharmaciesError = createAction(UserActionTypes.GET_ALL_PHARMACIES_ERR, props<{ message: string }>());

// Get User Email Settings
export const GetUserEmailSettings = createAction(UserActionTypes.GET_USER_EMAIL_SETTINGS);
export const GetUserEmailSettingsResults = createAction(UserActionTypes.GET_USER_EMAIL_SETTINGS_RES,
  props<{ userEmailSettings: UserEmailSettings }>());
export const GetUserEmailSettingsError = createAction(UserActionTypes.GET_USER_EMAIL_SETTINGS_ERR, props<{ message: string }>());

// Update User Email Settings
export const UpdateUserEmailSettings = createAction(UserActionTypes.UPDATE_USER_EMAIL_SETTINGS, props<{ saveData: UserEmailSettings }>());
export const UpdateUserEmailSettingsResults = createAction(UserActionTypes.UPDATE_USER_EMAIL_SETTINGS_RES,
  props<{ saveData: UserEmailSettings }>());
export const UpdateUserEmailSettingsError = createAction(UserActionTypes.UPDATE_USER_EMAIL_SETTINGS_ERR, props<{ message: string }>());

// Update User's Quick Access Page
export const UpdateUserQuickAccessPage = createAction(UserActionTypes.UPDATE_USER_QUICK_ACCESS_PAGE,
  props<{ saveData: SaveUserQuickAccessPage }>());
export const UpdateUserQuickAccessPageResults = createAction(UserActionTypes.UPDATE_USER_QUICK_ACCESS_PAGE_RES,
  props<{ saveData: SaveUserQuickAccessPage }>());
export const UpdateUserQuickAccessPageError = createAction(UserActionTypes.UPDATE_USER_QUICK_ACCESS_PAGE_ERR,
  props<{ message: string }>());

// Insert User's Track History
export const InsertUserTrackHistory = createAction(UserActionTypes.INSERT_USER_TRACK_HISTORY,
  props<{ saveData: SaveUsersTrackHistory }>());
export const InsertUserTrackHistoryResults = createAction(UserActionTypes.INSERT_USER_TRACK_HISTORY_RES,
  props<{ message: string }>());
export const InsertUserTrackHistoryErrors = createAction(UserActionTypes.INSERT_USER_TRACK_HISTORY_ERR,
  props<{ message: string }>());

// Update User Class
export const UpdateUserClass = createAction(UserActionTypes.UPDATE_USER_CLASS, props<{ saveData: SaveUserClass }>());
export const UpdateUserClassResults = createAction(UserActionTypes.UPDATE_USER_CLASS_RES,
  props <{ userClassInfo : SaveUserClass }>());
export const UpdateUserClassErrors = createAction(UserActionTypes.UPDATE_USER_CLASS_ERR, props <{ response : SubmitResponse }>());

// Get Page ID
export const GetPageId = createAction(UserActionTypes.GET_PAGE_ID, props<{ app: Application }>());
export const GetPageIdResults = createAction(UserActionTypes.GET_PAGE_ID_RES, props<{ pageInfo: PageInfo }>());
export const GetPageIdError = createAction(UserActionTypes.GET_PAGE_ID_ERR, props<{ message: string }>());

// Get User Submitted Support Tickets
// tslint:disable-next-line:max-line-length
export const GetUserSubmittedSupportTickets = createAction(UserActionTypes.GET_USER_SUBMITTED_SUPPORT_TICKETS, props<{ options: TableGetOptions }>());
// tslint:disable-next-line:max-line-length
export const GetUserSubmittedSupportTicketsResults = createAction(UserActionTypes.GET_USER_SUBMITTED_SUPPORT_TICKETS_RES, props<{ userSubmittedTickets: SubmittedSupportTicket[] }>());
// tslint:disable-next-line:max-line-length
export const GetUserSubmittedSupportTicketsError = createAction(UserActionTypes.GET_USER_SUBMITTED_SUPPORT_TICKETS_ERR, props<{ message: string }>());

export const UpdateUserMHISettings = createAction(UserActionTypes.UPDATE_USER_MHI_SETTINGS,
  props<{ saveUserMHISettings: SaveUserMHISettings }>());
export const UpdateUserMHISettingsResults = createAction(UserActionTypes.UPDATE_USER_MHI_SETTINGS_RES,
  props<{ userMHISettings: SaveUserMHISettings }>());
export const UpdateUserMHISettingsError = createAction(UserActionTypes.UPDATE_USER_MHI_SETTINGS_ERR,
  props<{ response: SubmitResponse }>());

export const GetUsersPublicationSelections = createAction(UserActionTypes.GET_USER_PUBLICATION_SELECTION);
export const GetUsersPublicationSelectionsResults = createAction(UserActionTypes.GET_USER_PUBLICATION_SELECTION_RES,
  props<{ usersPublicationSelection: UsersPublicationSelection[] }>());
export const GetUsersPublicationSelectionsError = createAction(UserActionTypes.GET_USER_PUBLICATION_SELECTION_ERR,
  props<{ message: string }>());

export const UpdateUsersPublicationSelections = createAction(UserActionTypes.UPDATE_USER_PUBLICATION_SELECTION,
  props<{ saveUsersPublicationSelections: SaveUserPubicationSelection[] }>());
export const UpdateUsersPublicationSelectionsResults = createAction(
  UserActionTypes.UPDATE_USER_PUBLICATION_SELECTION_RES, props<{ response: SubmitResponse }>());
export const UpdateUsersPublicationSelectionsError = createAction(
  UserActionTypes.UPDATE_USER_PUBLICATION_SELECTION_ERR, props<{ response: SubmitResponse }>());

export const GetUserAlternateLoginNameList = createAction(UserActionTypes.GET_USER_ALTERNATE_LOGIN_NAME,
  props<{ options: TableGetOptions }>())
export const GetUserAlternateLoginNameListResults = createAction(UserActionTypes.GET_USER_ALTERNATE_LOGIN_NAME_RES,
  props<{ userAlternateLoginNameList: UserAlternateLoginName[] }>());
export const GetUserAlternateLoginNameListError = createAction(UserActionTypes.GET_USER_ALTERNATE_LOGIN_NAME_ERR,
  props<{ message: string }>());

// Get User Notification List
export const GetUserNotificationList = createAction(UserActionTypes.GET_USER_NOTIFICATION_LIST);
export const GetUserNotificationListResults = createAction(UserActionTypes.GET_USER_NOTIFICATION_LIST_RES, props<{
  notificationList: NotificationItem[]
}>());
export const GetUserNotificationListError = createAction(UserActionTypes.GET_USER_NOTIFICATION_LIST_ERR, props<{ message: string }>());

export const GetCSGIDList = createAction(UserActionTypes.GET_CSGID_LIST);
export const GetCSGIDListResults = createAction(UserActionTypes.GET_CSGID_LIST_RES
  , props<{ csgIdList: CSGID[] }>());
export const GetCSGIDListError = createAction(UserActionTypes.GET_CSGID_LIST_ERR
  , props<{ message: string }>());

// Update User Notification Mark as read
export const UpdateUserNotificationList = createAction(UserActionTypes.UPDATE_USER_NOTIFICATION_LIST);
export const UpdateUserNotificationListResults = createAction(UserActionTypes.UPDATE_USER_NOTIFICATION_LIST_RES,
  props<{ saveNotificationList: UpdateNotification }>());
export const UpdateUserNotificationListError = createAction(UserActionTypes.UPDATE_USER_NOTIFICATION_LIST_ERR, props<{ message: string }>());

// Update User Notification Mark Selected Read

export const UpdateSelectedUserNotificationList = createAction(UserActionTypes.UPDATE_SELECTED_USER_NOTIFICATION_LIST
  , props<{ saveNotificationList: UpdateNotification }>());
export const UpdateSelectedUserNotificationListResults = createAction(UserActionTypes.UPDATE_SELECTED_USER_NOTIFICATION_LIST_RES
  , props<{ saveNotificationList: UpdateNotification }>());
export const UpdateSelectedUserNotificationListError = createAction(UserActionTypes.UPDATE_SELECTED_USER_NOTIFICATION_LIST_ERR,
  props<{ message: string }>());

export const GetVendorList = createAction(UserActionTypes.GET_VENDOR_LIST);
export const GetVendorListResults = createAction(UserActionTypes.GET_VENDOR_LIST_RES, props<{ vendorList: Vendor[] }>());
export const GetVendorListError = createAction(UserActionTypes.GET_VENDOR_LIST_ERR, props<{ message: string }>())

export const AddNewUserAlternateLogin = createAction(UserActionTypes.ADD_NEW_USER_ALTERNATE_LOGIN,
  props<{ newUserAlternateLogin: AddUserAlternateLoginName }>());
export const AddNewUserAlternateLoginResults = createAction(UserActionTypes.ADD_NEW_USER_ALTERNATE_LOGIN_RES,
  props<{ response: SubmitResponse }>());
export const AddNewUseralternateLoginError = createAction(UserActionTypes.ADD_NEW_USER_ALTERNATE_LOGIN_ERR,
  props<{ response: SubmitResponse }>());

// Get User Notification History List
export const GetUserNotificationHistoryList = createAction(UserActionTypes.GET_USER_NOTIFICATION_HISTORY_LIST);
export const GetUserNotificationHistoryListResults = createAction(UserActionTypes.GET_USER_NOTIFICATION_HISTORY_LIST_RES
  , props<{ notificationhistoryList: NotificationItemHistory[] }>());
export const GetUserNotificationHistoryListError = createAction(UserActionTypes.GET_USER_NOTIFICATION_HISTORY_LIST_ERR
  , props<{ message: string }>());

export const GetNDCAPharmacy = createAction(UserActionTypes.GET_NDCA_PHARMACY);
export const GetNDCAPharmacyResults = createAction(UserActionTypes.GET_NDCA_PHARMACY_RES, props<{ pharmacy: NDCAPharmacy }>());
export const GetNDCAPharmacyError = createAction(UserActionTypes.GET_NDCA_PHARMACY_ERR, props<{ message: string }>());

export const GetDisclaimer = createAction(UserActionTypes.GETDISCLAIMER, props<{ pageId: number }>());
export const GetDisclaimerResult = createAction(UserActionTypes.GETDISCLAIMER_RES, props<{ disclaimer: DisclaimerItem }>());
export const GetDisclaimerError = createAction(UserActionTypes.GETDISCLAIMER_ERR, props<{ message: string }>())

export const AcknowledgeDisclaimer = createAction(UserActionTypes.ACKNOWLEDGE, props<{ disclaimerId: number }>());
export const AcknowledgeDisclaimerResult = createAction(UserActionTypes.ACKNOWLEDGE_RES, props<{ response: SubmitResponse }>());
export const AcknowledgeDisclaimerResultError = createAction(UserActionTypes.ACKNOWLEDGE_ERR, props<{ message: string }>());

export const GetUserLogout = createAction(UserActionTypes.GET_USER_LOGOUT);
export const GetUserLogoutResult = createAction(UserActionTypes.GET_USER_LOGOUT_RES, props<{ response: SubmitResponse }>());
export const GetUserLogoutError = createAction(UserActionTypes.GET_USER_LOGOUT_ERR, props<{ message: string }>());

export const GetCorporateParentValidationStatus = createAction(UserActionTypes.GET_CORPORATE_PARENT_VALIDATION_STATUS, props<{}>());
export const GetCorporateParentValidationStatusResults = createAction(UserActionTypes.GET_CORPORATE_PARENT_VALIDATION_STATUS_RES, props<{ healthSystemValidationResponse: HealthSystemValidationResponse }>());
export const GetCorporateParentValidationStatusError = createAction(UserActionTypes.GET_CORPORATE_PARENT_VALIDATION_STATUS_ERR, props<{ message: string }>());

export const GetLookerUrl = createAction(UserActionTypes.GET_LOOKER_URL, props<{ csgId: string, dashboardType: string }>());
export const GetLookerUrlResults = createAction(UserActionTypes.GET_LOOKER_URL_RES, props<{ lookerUrl: LookerUrl }>());
export const GetLookerUrlError = createAction(UserActionTypes.GET_LOOKER_URL_ERR, props<{ message: string }>());
  
export const SetSelectedCSGID = createAction(UserActionTypes.SET_SELECTED_CSGID, props<{ csgId: string, name: string }>());

// Regenerate a new token when user select a corpid or chain id
export const RegenerateUserToken = createAction(UserActionTypes.REGENERATE_TOKEN, props<{ request: UserTokenRequest }>());
export const RegenerateUserTokenResults = createAction(UserActionTypes.REGENERATE_TOKEN_RES, props<{ userAccessToken: UserAccessToken }>());
export const RegenerateUserTokenError = createAction(UserActionTypes.REGENERATE_TOKEN_ERR, props<{ message: string }>());

export const RegenerateUserTokenOnAppChange = createAction(UserActionTypes.REGENERATE_TOKEN_APP_CHANGE, props<{ pid: number }>());
export const RegenerateUserTokenOnAppChangeResults = createAction(UserActionTypes.REGENERATE_TOKEN_APP_CHANGE_RES, props<{ userAccessToken: UserAccessToken }>());
export const RegenerateUserTokenOnAppChangeError = createAction(UserActionTypes.REGENERATE_TOKEN_APP_CHANGE_ERR, props<{ message: string }>());

export const GetUAMUserPharmacySession = createAction(UserActionTypes.GET_UAM_USER_PHARMACY_SESSION);
export const GetUAMUserPharmacySessionResults = createAction(UserActionTypes.GET_UAM_USER_PHARMACY_SESSION_RES, props<{ session: UserSession }>());
export const GetUAMUserPharmacySessionError = createAction(UserActionTypes.GET_UAM_USER_PHARMACY_SESSION_ERR, props<{ message: string }>());

export const GetUAMUserApplicationPages = createAction(UserActionTypes.GET_UAM_USER_APPLICATION_PAGES);
export const GetUAMUserApplicationPagesResults = createAction(UserActionTypes.GET_UAM_USER_APPLICATION_PAGES_RES, props<{ userApplicationInfo: UserApplicationInfo }>());
export const GetUAMUserApplicationPagesError = createAction(UserActionTypes.GET_UAM_USER_APPLICATION_PAGES_ERR, props<{ message: string }>());

export const ShowClientSelection = createAction(UserActionTypes.SHOW_CLIENT_SELECTION_PAGE);
export const CloseClientSelection = createAction(UserActionTypes.CLOSE_CLIENT_SELECTION_PAGE);

export const GetSelectedDashboard = createAction(UserActionTypes.GET_SELECTED_DASHBOARD, props<{ dashboard: string }>());
export const GetSelectedDashboardResults = createAction(UserActionTypes.GET_SELECTED_DASHBOARD_RES, props<{ selectedDashboard: string }>());
export const GetSelectedDashboardError = createAction(UserActionTypes.GET_SELECTED_DASHBOARD_ERR, props<{ message: string }>());
  
export const ClearGatewayData = createAction(UserActionTypes.CLEAR_GATEWAY_DATA);

export const ShowGatewayCsgids = createAction(UserActionTypes.SHOW_GATEWAY_CSGIDS);
export const HideGatewayCsgids = createAction(UserActionTypes.HIDE_GATEWAY_CSGIDS);

export const ShowAccessDeniedPage = createAction(UserActionTypes.SHOW_ACCESS_DENIED_PAGE);
export const CloseAccessDeniedPage = createAction(UserActionTypes.CLOSE_ACCESS_DENIED_PAGE);
