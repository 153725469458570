import { Injectable } from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import { of as observableOf } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import {
  GetSfCategories, GetSfCategoriesError, GetSfCategoriesResults,
  GetVersion, GetVersionError, GetVersionResults,
  NewSfTicket, NewSfTicketError, NewSfTicketResults,
  UpdateVersion, UpdateVersionError, UpdateVersionResults,
  GetApplicationPageList, GetApplicationPageListError, GetApplicationPageListResults, LogTrackingEvent,
} from './shell.actions';
import {AppInsightsService} from 'src/app/service/appinsights.service';
import {ShellService} from 'src/app/service/shell.service';

@Injectable()
export class ShellStoreEffects {

  constructor(private shellService: ShellService, private insightsService: AppInsightsService, private actions$: Actions, private store: Store<{}>) {
  }

  GetSalesForceCategories$ = createEffect(() => this.actions$.pipe(
    ofType( GetSfCategories ),
    switchMap(action =>
      this.shellService.getSalesForceCategory()
        .pipe(
          map(
            data => {
              return GetSfCategoriesResults( { categories: data } );
            }
          ),
          catchError(error => {
            return observableOf( GetSfCategoriesError({ message: error} ) )
          })
        )
    )
  ));


  NewSalesForceTicte$ = createEffect(() => this.actions$.pipe(
    ofType( NewSfTicket ),
    switchMap(action =>
      this.shellService.submitSalesForceTicket( action.ticket )
        .pipe(
          map(
            data => NewSfTicketResults( { ticket: data } )
            ),
          catchError(
            error => observableOf( NewSfTicketError({ message: error} ) )
          )
        )
    )
  ));


  GetLatesApplicationVersion$ = createEffect(() => this.actions$.pipe(
    ofType( GetVersion ),
    switchMap(action =>
      this.shellService.getLastestApplicationVersion()
        .pipe(
          map(
            data => GetVersionResults( { version: data } )
          ),
          catchError(
            error => observableOf( GetVersionError({ message: error} ) )
          )
        )
    )
  ));


  GetUserSessionEffect$ = createEffect(() => this.actions$.pipe(
    ofType( UpdateVersion ),
    switchMap(action =>
      this.shellService.updateNewVersionTracker( action.saveData )
        .pipe(
          map(
            data => UpdateVersionResults( { version: data } )
          ),
          catchError(
            error => observableOf( UpdateVersionError({ message: error} ) )
          )
        )
    )
  ));

  GetApplicationPageListEffect$ = createEffect(() => this.actions$.pipe(
    ofType(GetApplicationPageList),
    switchMap(action=>
      this.shellService.getApplicationPageList(action.appId)
      .pipe(
        map(
          data => {return GetApplicationPageListResults({applicationPageList: data})
        }
        ),
        catchError(
          error => observableOf(GetApplicationPageListError({message: error}))
        )
      )
    )
  ))

  LogTrackingEventEffect$ = createEffect(() => this.actions$.pipe(
    ofType(LogTrackingEvent), switchMap(action=>  this.insightsService.logTrackingEvent( action.eventName ) )
    )
  )

}
