import { Applications } from 'src/app/models/applications';
import { AppListActionTypes } from './application-list.actions';

export const initialState =
  {
    Items: null,
    Selected: null,
    Menu: {
      Sections: null,
      Selected: null
    },
    restoredFromUrl:  window.location.hash.length > 2 ? true : false,
    backupPage: null
  };

export function applicationsReducer(state = initialState, action: any): Applications {

  switch (action.type) {
    case AppListActionTypes.SELECTED_RES: {
      return {
        ...state,
        Menu: {Sections:  action.applicationPageList,
               Selected: null}
      };
    }
    case AppListActionTypes.SELECTED: {
      return {
        ...state,
        Selected: action.app,
      };
    }
    case AppListActionTypes.GET_APPLICATIONS_RES: {
      return {
        ...state,
        Items: action.applications,
      };
    }
    case AppListActionTypes.PAGE_SELECTED: {
      return {
        ...state,
        Menu: Object.assign( state.Menu, { Selected:action.page })
      };
    }
    case AppListActionTypes.GET_BACKUP_PAGE_RES: {
      return { ...state, backupPage: action.page };
    }
    default: {
      return state;
    }
  }
}







