import { Component, OnInit, HostListener, OnDestroy } from '@angular/core';
import { UserSettings } from 'src/app/models/user';
import { SaveVersionTracker, VersionTracker } from 'src/app/models/version-tracker';
import { MatDialog, MatDialogRef  } from '@angular/material/dialog';
import { ShellState } from 'src/app/store/shell.reducer';
import { Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';
import { GetVersion, UpdateVersion, UpdateVersionError, UpdateVersionResults } from '../../../../../store/shell.actions';
import { Observable, Subscription } from 'rxjs';
import { InfoShow} from 'src/app/store/ui-state.actions';
import { UserState } from 'src/app/store/user.reducer';
import { Applications } from 'src/app/models/applications';
import { environment } from 'src/environments/environment';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'help-about-popup',
  templateUrl: './about-popup.component.html',
  styleUrls: ['./about-popup.component.scss']
})
export class AboutPopupComponent implements OnInit, OnDestroy {
  public applications$: Observable<Applications>;
  public versionStore$: Observable<ShellState>;
  public userStore$: Observable<UserState>;

  // Declare a new version tracker object to bind data into it
  versionTracker: VersionTracker = new VersionTracker();
  saveVersionTracker: SaveVersionTracker = new SaveVersionTracker();
  public version: string;
  // Show edit button if the logged in user has permission to edit application version
  enableEdit = true;
  showEdit = false;
  edit = false;
  showResponseMsg = false;
  wasFormChanged = false;

  // UIDs that has edit permission
  editAccessUids = [10391, 11569, 1584, 2135, 6435, 5711, 25910];
  // 25907, 25910 are Tri's UID, used to test the edit button for users that have permission to edit the version
  subs: Subscription = new Subscription;

  constructor(private dialog: MatDialog,
              private dialogRef: MatDialogRef<AboutPopupComponent>,
              private store: Store< {shellState: ShellState, userState: UserState, applications:Applications}>,
              private actions$: Actions) {
    this.store.dispatch(GetVersion());
    this.version = environment.appVersion;
  }

  @HostListener('window:keyup.esc') onKeyUp() {
    this.dialogRef.close();
  }

  ngOnInit(): void {
    this.applications$ =this.store.select(state => state.applications );
    this.versionStore$ = this.store.select(state => state.shellState);
    this.userStore$ = this.store.select(state => state.userState );
    this.subs.add(
      this.userStore$.subscribe((userState) => {
        this.showEdit = this.checkEditPermission(userState.settings);
        this.saveVersionTracker.updatedBy = userState.settings.uid;
      })
    )
    this.subs.add(
      this.actions$.pipe(ofType(UpdateVersionResults)).subscribe(data => {
        this.store.dispatch( InfoShow({ message: 'New application version is updated!'}) );
      })
    )
    this.subs.add(
      this.actions$.pipe(ofType(UpdateVersionError)).subscribe((data) => {
        alert(data.message);
      })
    )
  }

  formChanged() {
    this.wasFormChanged = true;
  }

  // Run the check on logged in user to see if he/she has application version edit permission
  checkEditPermission(currLoggedInUser: UserSettings): any {
    for (const value of this.editAccessUids) {
      if (value === currLoggedInUser.uid) {
        return true;
      }
    }
  }

  // Turn on Edit mode for users has editing permission
  enableEditMode() {
    this.edit = true;
  }

  // Turn off Edit mode
  disableEditMode() {
    this.edit = false;
  }

  // Execute when Save button clicked to send new update request to Web API
  onSaveClick() {
    this.setDataForNewVersionTracker();

    this.store.dispatch(UpdateVersion( {saveData: this.saveVersionTracker}));

    this.disableEditMode();
    this.saveVersionTracker = new SaveVersionTracker();
  }

  onCloseClick(){
    this.disableEditMode();
    this.dialogRef.close();
  }

  setDataForNewVersionTracker() {
    this.saveVersionTracker.versionID = 0;
    this.saveVersionTracker.active = 1;
    this.saveVersionTracker.dateAdded = new Date();
    this.saveVersionTracker.dateUpdated = new Date();
  }

  ngOnDestroy(): void {
      this.subs.unsubscribe();
  }
}
