import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of as observableOf } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { OrderService } from 'src/app/service/order.service';
import {
  GetCDData,
  GetCDDataResults,
  GetCDDataError,
  GetViewDetails,
  GetCDDataViewDetailResults,
  GetCDDataViewDetailError,
  GetVendor,
  GetVendorResults,
  GetVendorError,
  GetSequence,
  GetSequenceResults,
  GetSequenceError,
  UpdateCDGroup,
  UpdateCDGroupResults,
  UpdateCDGroupError,
  IsValidTerminalAccount,
  IsValidTerminalAccountResults,
  IsValidTerminalAccountError,
  IsValidGroupName,
  IsValidGroupNameResults,
  IsValidGroupNameError,
  AddCDGroup,
  AddCDGroupResults,
  AddCDGroupError,
  GetContacts,
  GetContactsResults,
  GetContactsError,
  GetContactsById,
  GetContactsByIdResults,
  GetContactsByIdError,
  GetContactsBySearchVal,
  GetContactsBySearchValResults,
  GetContactsBySearchValError,
  GetCentralSplitData,
  GetCentralSplitDataResults,
  GetCentralSplitDataError
} from './order.actions';


@Injectable()
export class OrderEffects {

  constructor(private orderService: OrderService, private actions$: Actions, private store: Store<{}>) {
  }

  GetCentralDistributionData$ = createEffect(() => this.actions$.pipe(
    ofType(GetCDData),
    switchMap(action =>
      this.orderService.getReceivedAll(action.options)
        .pipe(
          map(
            data => GetCDDataResults({ centraldistribution: data })
          ),
          catchError(
            error => observableOf(GetCDDataError({ message: error }))
          )
        )
    )
  ));

  GetCentralSplitView$ = createEffect(() => this.actions$.pipe(
    ofType(GetCentralSplitData),
    switchMap(action =>
      this.orderService.getCentralSplitGroups(action.options)
        .pipe(
          map(
            data => GetCentralSplitDataResults({ centralsplit: data })
          ),
          catchError(
            error => observableOf(GetCentralSplitDataError({ message: error }))
          )
        )
    )
  ));

  GetCentralDistributionDetails$ = createEffect(() => this.actions$.pipe(
    ofType(GetViewDetails),
    switchMap(action =>
      this.orderService.getCDGrpDetailsByID(action.groupID, action.options)
        .pipe(
          map(
            data => GetCDDataViewDetailResults({ viewdetailsdata: data })
          ),
          catchError(
            error => observableOf(GetCDDataViewDetailError({ message: error }))
          )
        )
    )
  ));

  GetCDVendor$ = createEffect(() => this.actions$.pipe(
    ofType(GetVendor),
    switchMap(action =>
      this.orderService.getVendorName(action.wacaccount)
        .pipe(
          map(
            data => GetVendorResults({ vendorname: data })
          ),
          catchError(
            error => observableOf(GetVendorError({ message: error }))
          )
        )
    )
  ));


  GetCDSequences$ = createEffect(() => this.actions$.pipe(
    ofType(GetSequence),
    switchMap(action =>
      this.orderService.getSequences()
        .pipe(
          map(
            data => GetSequenceResults({ sequences: data })
          ),
          catchError(
            error => observableOf(GetSequenceError({ message: error }))
          )
        )
    )
  ));

  UpdateCDGroup$ = createEffect(() => this.actions$.pipe(
    ofType(UpdateCDGroup),
    switchMap(action =>
      this.orderService.updateCDGroup(action.saveData)
        .pipe(
          map(
            data => UpdateCDGroupResults({ saveData: data })
          ),
          catchError(
            error => observableOf(UpdateCDGroupError({ message: error }))
          )
        )
    )
  ));

  IsValidTerminalAccount$ = createEffect(() => this.actions$.pipe(
    ofType(IsValidTerminalAccount),
    switchMap(action =>
      this.orderService.IsValidTerminalAccount(action.wacaccount)
        .pipe(
          map(
            data => IsValidTerminalAccountResults({ count: data })
          ),
          catchError(
            error => observableOf(IsValidTerminalAccountError({ message: error }))
          )
        )
    )
  ));

  IsValidGroupName$ = createEffect(() => this.actions$.pipe(
    ofType(IsValidGroupName),
    switchMap(action =>
      this.orderService.IsValidGroupName(action.groupname)
        .pipe(
          map(
            data => IsValidGroupNameResults({ countval: data })
          ),
          catchError(
            error => observableOf(IsValidGroupNameError({ message: error }))
          )
        )
    )
  ));

  AddCDGroup$ = createEffect(() => this.actions$.pipe(
    ofType(AddCDGroup),
    switchMap(action =>
      this.orderService.addCDGroup(action.saveData)
        .pipe(
          map(
            data => AddCDGroupResults({ saveData: data })
          ),
          catchError(
            error => observableOf(AddCDGroupError({ message: error }))
          )
        )
    )
  ));

  GETCDCONTACT$ = createEffect(() => this.actions$.pipe(
    ofType(GetContacts),
    switchMap(action =>
      this.orderService.getCDContacts(action.pids, action.options)
        .pipe(
          map(
            data => GetContactsResults({ contactsBySearch: data.filter(n => (n.FirstName.length >0 || n.LastName.length > 0 ) && n.Email.length > 0) })
          ),
          catchError(
            error => observableOf(GetContactsError({ message: error }))
          )
        )
    )
  ));

  GETCDCONTACTBYID$ = createEffect(() => this.actions$.pipe(
    ofType(GetContactsById),
    switchMap(action =>
      this.orderService.getCDContactsID(action.groupID, action.options)
        .pipe(
          map(
            data => GetContactsByIdResults({ contacts: data })
          ),
          catchError(
            error => observableOf(GetContactsByIdError({ message: error }))
          )
        )
    )
  ));

  GETCDCONTACTBYSEARCHVAL$ = createEffect(() => this.actions$.pipe(
    ofType(GetContactsBySearchVal),
    switchMap(action =>
      this.orderService.getCDContactsbySearchVal(action.contactdata, action.options)
        .pipe(
          map(
            data => GetContactsBySearchValResults({contactsBySearch: data.filter(n => (n.FirstName.length >0 || n.LastName.length > 0 ) && n.Email.length > 0) })
          ),
          catchError(
            error => observableOf(GetContactsBySearchValError({ message: error }))
          )
        )
    )
  ));

}
