import { SubmitResponse } from 'src/app/models/submit-response';
import {UiUser, UserSession, UserSettings, UserEmailSettings,
  SaveUsersTrackHistory, SaveUserClass, PageInfo, SaveUserQuickAccessPage, SaveUserMHISettings,
  UserApplications, UserAccessToken, NotificationItem, Vendor, AddUserAlternateLoginName,
  UserAlternateLoginName, UsersPublicationSelection, SaveUserPubicationSelection, NotificationItemHistory, DisclaimerItem, UserApplicationPage,
  CSGID, LookerUrl, UAMUserApplicationPage, UserHelpUrl
} from '../models/user';
import { UserActionTypes } from './user.actions';
import { UAMPharmacy } from '../models/pharmacy';
import { SubmittedSupportTicket } from '../models/sales-force-ticket';
import { HealthSystemValidationResponse } from '../models/health-system-view/health-system';

export interface UserState {
  user: UiUser
  settings: UserSettings,
  session: UserSession,
  recalculate: any,
  pharmacies: UAMPharmacy[],
  uppharmacies: UAMPharmacy[],
  userEmailSettings: UserEmailSettings,
  saveUsersTrackHistory: SaveUsersTrackHistory,
  userClassInfo: SaveUserClass;
  pageInfo: PageInfo,
  quickAccessPage: SaveUserQuickAccessPage,
  submittedTicketList: SubmittedSupportTicket[],
  saveUserMHISettings: SaveUserMHISettings,
  userPublicationSelectionList: UsersPublicationSelection[],
  saveUserPublicationSelectionList: SaveUserPubicationSelection[],
  userAlternateLoginNameList: UserAlternateLoginName[],
  userApps: UserApplications,
  userAppPages: UAMUserApplicationPage[],
  userAccessToken: UserAccessToken,
  vendorList: Vendor[],
  newUserAlternateLoginName: AddUserAlternateLoginName,
  notificationList: NotificationItem[];
  notificationhistoryList: NotificationItemHistory[];
  disclaimer: DisclaimerItem;
  acknowledgeDisclaimerResponse: SubmitResponse;
  logoutStatus: SubmitResponse;
  healthSystemValidationResponse:HealthSystemValidationResponse;
  csgids :CSGID[];
  lookerUrl :LookerUrl;
  selectedCSGID: CSGID;
  selectedDashboard: string;
  showClientSelectionPage: boolean;
  currentApp: number;
  showGatewayCsgids: boolean;
  showAccessDeniedPage: boolean;
  userHelpUrl: UserHelpUrl;
}

export const initialState = {
  user: null,
  settings: null,
  session: null,
  recalculate: null,
  pharmacies: null,
  uppharmacies: null,
  userEmailSettings: null,
  saveUsersTrackHistory: null,
  userClassInfo: null,
  pageInfo: null,
  quickAccessPage: null,
  submittedTicketList: null,
  saveUserMHISettings: null,
  userPublicationSelectionList: null,
  saveUserPublicationSelectionList: null,
  userAlternateLoginNameList: null,
  userApps: null,
  userAppPages: null,
  userAccessToken: null,
  notificationList: null,
  vendorList: null,
  newUserAlternateLoginName: null,
  notificationhistoryList: null,
  disclaimer: null,
  acknowledgeDisclaimerResponse: null,
  logoutStatus: null,
  healthSystemValidationResponse: null,
  csgids: null,
  lookerUrl: null,
  selectedCSGID: null,
  selectedDashboard: null,
  showClientSelectionPage:null,
  currentApp: null,
  showGatewayCsgids: null,
  selectedGatewayCsgid: null,
  showAccessDeniedPage: null,
  userHelpUrl: null
}

export function userActionsReducer(state = initialState, action: any): UserState {

  switch (action.type) {
    case UserActionTypes.INIT_USER_RES: {
      return { ...state, user: action.uiUser };
    }
    case UserActionTypes.GET_SETTINGS_RES: {
      return { ...state, settings: action.settings };
    }
    case UserActionTypes.GET_SESSION_RES: {
      return { ...state, session: action.session };
    }
    case UserActionTypes.UPDATE_SESSION_RES: {
      return { ...state, session: action.session };
    }
    case UserActionTypes.UPDATE_RECALCULATE_RES: {
      return { ...state, recalculate: action.session };
    }
    case UserActionTypes.GET_PHARMACIES_RES: {
      return { ...state, pharmacies: action.pharmacies };
    }
    case UserActionTypes.GET_ALL_PHARMACIES_RES: {
      return { ...state, uppharmacies: action.pharmacies};
    }
    case UserActionTypes.SAVE_SETTINGS_RES: {
      return {
        ...state,
        settings: Object.assign(state.settings, {
          defaultPkgs: action.saveData.defaultPkgs,
          displayHeight: action.saveData.displayHeight,
          virtualScrolling: action.saveData.virtualScrolling
        })
      };
    }
    case UserActionTypes.UPDATE_340BPOPUP_RES: {
      return {
        ...state,
        settings: Object.assign(state.settings, {
          defaultPkgs: action.saveData.defaultPkgs,
          displayHeight: action.saveData.displayHeight,
          virtualScrolling: action.saveData.virtualScrolling
        })
      };
    }
    case UserActionTypes.UPDATE_APPLICATION_RES: {
      return { ...state, currentApp: action.appId };
    }
    case UserActionTypes.UPDATE_APPLICATION_AND_AUTHORIZE_RES: {
      return { ...state, currentApp: action.appId };
    }
    case UserActionTypes.GET_USER_EMAIL_SETTINGS_RES: {
      return { ...state, userEmailSettings: action.userEmailSettings };
    }
    case UserActionTypes.UPDATE_USER_EMAIL_SETTINGS_RES: {
      return { ...state, userEmailSettings: action.saveData };
    }
    case UserActionTypes.UPDATE_USER_QUICK_ACCESS_PAGE_RES: {
      if (action.saveData.appId === 1)
        return {
          ...state,
          settings: Object.assign(state.settings, {
            macroManagerQuickAccessPageID: action.saveData.pageId
          })
        };
      else if (action.saveData.appId === 2)
        return {
          ...state,
          settings: Object.assign(state.settings, {
            architect340BQuickAccessPageID: action.saveData.pageId
          })
        };
      else if (action.saveData.appId === 7)
        return {
          ...state,
          settings: Object.assign(state.settings, {
            gatewayQuickAccessPageID: action.saveData.pageId
          })
        };
      else
        return state;
    }
    case UserActionTypes.INSERT_USER_TRACK_HISTORY_RES: {
      return { ...state, saveUsersTrackHistory: action.saveUsersTrackHistory };
    }
    case UserActionTypes.UPDATE_USER_CLASS_RES: {
      return { ...state, userClassInfo: action.userClassInfo};
    }
    case UserActionTypes.GET_PAGE_ID_RES: {
      return { ...state, pageInfo: action.pageInfo };
    }
    case UserActionTypes.GET_USER_SUBMITTED_SUPPORT_TICKETS_RES: {
      return { ...state, submittedTicketList: action.userSubmittedTickets };
    }
    case UserActionTypes.UPDATE_USER_MHI_SETTINGS_RES: {
      return { ...state, saveUserMHISettings: action.saveUserMHISettings };
    }
    case UserActionTypes.GET_USER_PUBLICATION_SELECTION_RES: {
      return { ...state, userPublicationSelectionList: action.usersPublicationSelection };
    }
    case UserActionTypes.UPDATE_USER_PUBLICATION_SELECTION_RES: {
      return { ...state, saveUserPublicationSelectionList: action.saveUsersPublicationSelections };
    }
    case UserActionTypes.GET_USER_ALTERNATE_LOGIN_NAME_RES: {
      return { ...state, userAlternateLoginNameList: action.userAlternateLoginNameList };
    }
    case UserActionTypes.GET_USER_NOTIFICATION_LIST_RES: {
      return { ...state, notificationList: action.notificationList };
    }
    case UserActionTypes.UPDATE_USER_NOTIFICATION_LIST_RES: {
      return { ...state, notificationList: action.saveNotificationList.updatedNotificationList };
    }
    case UserActionTypes.UPDATE_SELECTED_USER_NOTIFICATION_LIST_RES: {
      return { ...state, notificationList: action.saveNotificationList.updatedNotificationList };
    }
    case UserActionTypes.GET_VENDOR_LIST_RES: {
      return { ...state, vendorList: action.vendorList };
    }
    case UserActionTypes.ADD_NEW_USER_ALTERNATE_LOGIN_RES: {
      return { ...state, newUserAlternateLoginName: action.newUserAlternateLogin };
    }
    case UserActionTypes.GET_USER_NOTIFICATION_HISTORY_LIST_RES:{
      return { ...state, notificationhistoryList: action.notificationhistoryList};
    }
    case UserActionTypes.GET_NDCA_PHARMACY_RES:{
      return { ...state, session: Object.assign( state.session, { ndcPharmacyInfo: action.pharmacy })};
    }
    case UserActionTypes.GETDISCLAIMER_RES: {
      return { ...state, disclaimer: action.disclaimer };
    }
    case UserActionTypes.ACKNOWLEDGE_RES: {
      return { ...state, acknowledgeDisclaimerResponse: action.response };
    }
    case UserActionTypes.GET_USER_LOGOUT_RES: {
      return { ...state, logoutStatus: action.response };
    }
    case UserActionTypes.GET_CORPORATE_PARENT_VALIDATION_STATUS_RES: {
      return {...state,	healthSystemValidationResponse: action.healthSystemValidationResponse };
    }
    case UserActionTypes.GET_CSGID_LIST_RES: {
      return { ...state,	csgids: action.csgIdList };
    }
    case UserActionTypes.GET_LOOKER_URL_RES: {
      return { ...state,	lookerUrl: action.lookerUrl };
    }
    case UserActionTypes.GET_SELECTED_DASHBOARD: {
        return { ...state, selectedDashboard: action.dashboard };
    }
    case UserActionTypes.CLEAR_GATEWAY_DATA: {
        return { ...state, csgids: null, selectedCSGID: null, lookerUrl: null, selectedDashboard: null };
    }
    case UserActionTypes.REGENERATE_TOKEN_RES:
    case UserActionTypes.REGENERATE_TOKEN_APP_CHANGE_RES: {
      return { ...state, userAccessToken: action.userAccessToken }
    }
    case UserActionTypes.GET_UAM_USER_APPLICATION_PAGES_RES: {
      return { ...state, userApps: action.userApplicationInfo, userAppPages: action.userApplicationInfo.applicationPages }
    }
    case UserActionTypes.GET_UAM_USER_PHARMACY_SESSION_RES: {
      return { ...state, session: action.session }
    }
    case UserActionTypes.SHOW_CLIENT_SELECTION_PAGE: {
      return { ...state, showClientSelectionPage: true }
    }
    case UserActionTypes.CLOSE_CLIENT_SELECTION_PAGE: {
      return { ...state, showClientSelectionPage: false }
    }
    case UserActionTypes.SHOW_GATEWAY_CSGIDS: {
      return { ...state, showGatewayCsgids: true }
    }
    case UserActionTypes.HIDE_GATEWAY_CSGIDS: {
      return { ...state, showGatewayCsgids: false }
    }
    case UserActionTypes.SHOW_ACCESS_DENIED_PAGE: {
      return { ...state, showAccessDeniedPage: true }
    }
    case UserActionTypes.CLOSE_ACCESS_DENIED_PAGE: {
      return { ...state, showAccessDeniedPage: false }
    }
    case UserActionTypes.GET_CSGID_LIST_RES: {
        return { ...state, csgids: action.csgIdList };
    }
    case UserActionTypes.GET_LOOKER_URL_RES: {
        return { ...state, lookerUrl: action.lookerUrl };
    }
    case UserActionTypes.SET_SELECTED_CSGID: {
      return { ...state,	selectedCSGID: { csgid: action.csgId, name: action.name } };
    }
    case UserActionTypes.GET_HELPAPPLICATION_URL_RES: {
      return { ...state, userHelpUrl: action.userHelpUrl }
    }
    default: {
      return state;
    }
  }

}
