import { ApplicationMenuItem } from 'src/app/models/applications';
import {VersionTracker} from 'src/app/models/version-tracker';
import {SalesForceCategory} from 'src/app/models/sales-force-category';
import {ShellActionTypes} from './shell.actions';

export interface ShellState {
  version: VersionTracker,
  sfCategories: SalesForceCategory[],
  applicationPage: ApplicationMenuItem,
  applicationPageList : ApplicationMenuItem[],
}

export const initialState = {
  version: null,
  sfCategories: null,
  applicationPage: null,
  applicationPageList: null,
}

export function shellActionsReducer(state = initialState, action: any): ShellState {

  switch (action.type) {
    case ShellActionTypes.GET_SF_CATEGORIES_RES: {
      return { ...state, sfCategories: action.categories };
    }
    case ShellActionTypes.GET_VERSION_RES: {
      return { ...state, version: action.version };
    }
    case ShellActionTypes.UPDATE_VERSION_RES: {
      return { ...state, version: action.version };
    }
    case ShellActionTypes.GET_APPLICATION_PAGE_LIST: {
      return {...state, applicationPageList: action.applicationPageList}
    }
    default: {
      return state;
    }
  }

}
