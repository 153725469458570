import { Injectable } from '@angular/core';
import { BaseMhService } from './basemh.service';
import { throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { UserState } from '../store/user.reducer';
import { SubmitResponse } from '../models/submit-response';
import { ReferralOutputDto, ReferralColumnDTO, ReferralNotesDTO, ReferralElementsDTO, ReferralAttachments } from '../store/automated-referral.reducer';
import { ReferralSettings, SaveReferralSettings } from '../models/automated-referrals/referral-setting';
import { UserSession } from '../models/user';
import { environment } from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})

export class AutomatedReferralService extends BaseMhService {

  constructor(protected http: HttpClient, protected store: Store<{ userState: UserState }>) {
    super(http, store);
  }

  public getAutomatedReferralList(isQualified?: boolean, isDismissed?: boolean) {
    const session: UserSession = this.getStoreUserSession();
    return this.http
      .get(environment.apiBaseUrl + 'automatedreferral/v1/automatedreferral/automatedreferrallist?HID=' + session.hid
        + '&UID=' + session.uid + '&isQualified=' + isQualified + '&isDismissed=' + isDismissed, { headers: this.getHeaders() })
      .pipe(
        map((response: ReferralOutputDto) => response),
        // catchError(this.errorHandler)
        catchError(error => this.errorHandler({ error, operation: "Error while Retrieving Automated Referral Data" }))
      );
  }

  // Make sure the HID is reflected the pharmacy the user is on.  Needs to double check this
  public getReferralSettings() {
    const session: UserSession = this.getStoreUserSession();
    return this.http
      .get(environment.apiBaseUrl + 'automatedreferral/v1/referralsettings/getreferralSettings?HID=' + session.hid,
        { headers: this.getHeaders() })
      .pipe(
        map((response: ReferralSettings) => response),
        // tap(returnedData => console.log(returnedData)),
        catchError(error => this.errorHandler({ error, operation: "Error while retrieving referral settings" }))
      );
  }

  public updateReferralSettings(saveData: SaveReferralSettings) {
    return this.http
      .put(environment.apiBaseUrl + 'automatedreferral/v1/referralsettings/saveReferralSettings', saveData, { headers: this.getHeaders() })
      .pipe(
        map((response: SubmitResponse) => {
          if (response.status === '200')
            return response;
          else
            throwError(response.message);
        }),
        catchError(error => this.errorHandler({ error, operation: "Error while updating referral settings" }))
      );
  }

  public setReferralsColumns(columns: ReferralColumnDTO[]) {
    const session: UserSession = this.getStoreUserSession();
    const saveData = { userId: session.uid, referralData: columns };
    return this.http
      .put(environment.apiBaseUrl + 'automatedreferral/v1/automatedreferral/setReferralsColumns', saveData, { headers: this.getHeaders() })
      .pipe(
        map((response: ReferralColumnDTO[]) => response),
        catchError(error => this.errorHandler({ error, operation: "Error while settings grid columns" }))
      );
  }

  public getReferralsNotes(sid: number) {
    return this.http.get(environment.apiBaseUrl + 'automatedreferral/v1/automatedreferral/getReferralNotes?SID=' + sid, { headers: this.getHeaders() })
      .pipe(
        map((response: ReferralNotesDTO[]) => response),
        catchError(error => this.errorHandler({ error, operation: "Error while retrieving referral notes" }))
      );
  }

  public setReferralsNote(sid: number, note: string, refPid: number) {
    const session: UserSession = this.getStoreUserSession();
    let saveData = { uid: session.uid, note: note, sid: sid, refPid: refPid };
    return this.http
      .put(environment.apiBaseUrl + 'automatedreferral/v1/automatedreferral/setReferralNotes', saveData, { headers: this.getHeaders() })
      .pipe(
        map((response: SubmitResponse) => {
          if (response.status === '200')
            return response;
          else
            throwError(response.message);
        }),
        catchError(error => this.errorHandler({ error, operation: "Error while adding referral notes" }))
      );
  }

  public getReferralElements(sid: number, mhafid: number) {
    return this.http.get(environment.apiBaseUrl + 'automatedreferral/v1/automatedreferral/getReferralElements?SID=' + sid
        + '&MHAFID=' + mhafid, { headers: this.getHeaders() })
      .pipe(
        map((response: ReferralElementsDTO) => response),
        catchError(error => this.errorHandler({ error, operation: "Error while retrieving referral elements" }))
      );
  }

  public getReferralAttachments(sid: number) {
    return this.http.get(environment.apiBaseUrl + 'automatedreferral/v1/automatedreferral/getScriptAttachments?scriptID=' + sid, { headers: this.getHeaders() })
      .pipe(
        map((response: ReferralAttachments) => response),
        catchError(error => this.errorHandler({ error, operation: "Error while retrieving attachment" }))
      );
  }

  public addReferralAttachment(sid: number, pid: number, rxnumber: string, attachment: FormData) {
    //Take content type out of header
    var formHeaders = new HttpHeaders({
      'Cache-Control': 'no-cache',
      'Ocp-Apim-Subscription-Key': environment.subcriptionKey,
      Authorization: 'Bearer ' + this.getUserToken(),
    });

    return this.http
      .post(environment.apiBaseUrl + 'automatedreferral/v1/automatedreferral/script/' + sid
        + '/addAttachment?PID=' + pid + '&RXNumber=' + rxnumber, attachment, { headers: formHeaders })
      .pipe(
        map((response: SubmitResponse) => {
          if (response.status === '200')
            return response;
          else
            throwError(response.message);
        }),
        catchError(error => this.errorHandler({ error, operation: "Error while adding attachment" })));
  }

  public removeReferralAttachment(sid: number, guid: string, pid: number, fileName: string) {
    return this.http
      .get(environment.apiBaseUrl + 'automatedreferral/v1/automatedreferral/removeScriptAttachment?scriptID=' + sid + '&guid=' + guid
        + '&PID=' + pid + '&fileName=' + fileName,
        { headers: this.getHeaders() })
      .pipe(
        map((response: SubmitResponse) => {
          if (response.status === '200')
            return response;
          else
            throwError(response.message);
        }),
        catchError(error => this.errorHandler({ error, operation: "Error while removing attachment" })));
  }

  public checkReferralMenu(uid: number, pid: number) {
    return this.http
      .get(environment.apiBaseUrl + 'automatedreferral/v1/automatedreferral/checkReferralMenu?uid=' + uid + '&pid=' + pid,
        { headers: this.getHeaders() })
      .pipe(
        map((response: boolean) => response),
        catchError(error => this.errorHandler({ error, operation: "Error while checking referral menu" })));
  }

  public dismissReferral(mhafid: number, sid: number) {
    let saveData = { mhafid: mhafid, sid: sid };
    return this.http
      // .put(environment.apiBaseUrl + 'automatedreferral/v1/automatedreferral/dismissReferral?mhafid=' + mhafid + '&sid=' + sid, { headers: this.getHeaders() })
      .put(environment.apiBaseUrl + 'automatedreferral/v1/automatedreferral/dismissReferral', saveData, { headers: this.getHeaders() })
      .pipe(
        map((response: SubmitResponse) => response),
        catchError(error => this.errorHandler({ error, operation: "Error while Dismissing a referral" })));
  }

  public qualifyReferral(mhafid: number, sid: number, refPid: number, filltype: number, rxnumber: string, notes: string, isDisqualified?: boolean) {
    const session: UserSession = this.getStoreUserSession();
    let saveData = { mhafid, sid, refPid, uid: session.uid, filltype, rxnumber, notes, isDisqualified };
    return this.http
      .put(environment.apiBaseUrl + 'automatedreferral/v1/automatedreferral/qualifyReferral', saveData, { headers: this.getHeaders() })
      .pipe(
        map((response: SubmitResponse) => response),
        catchError(error => this.errorHandler({ error, operation: "Error while Qualifying a referral" })));
  }
}


