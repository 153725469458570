import { createAction, props } from "@ngrx/store";
import { ChainResponse } from "src/app/models/gateway/chain/chain-response";
import { ChainSettingsRequest } from "src/app/models/gateway/chain/chain-settings-response";
import { ChainSettingsResponse } from "src/app/models/gateway/chain/chain-settings-response";
import { ChainStoresGroups, ChainStoresGroupsResponse } from "src/app/models/gateway/chain/chain-stores-groups-response";
import { AddDispenseFeeScheduleRequest, DeleteDispenseFeeScheduleRequest, UpdateDispenseFeeScheduleRequest } from "src/app/models/gateway/dispense-fee/dispense-fee-schedule-request";
import { CoveredEntityResponse, DispenseFeeScheduleHistoryResponse, DispenseFeeScheduleResponse } from "src/app/models/gateway/dispense-fee/dispense-fee-schedule-response";
import { FormularySetupCSGIDsResponse } from "src/app/models/gateway/formulary/formulary-setup-csgids-response";
import { FormularyCurrentResponse, FormularySetupResponse } from "src/app/models/gateway/formulary/formulary-response";

export enum GatewayStateActionTypes {
  GET_GATEWAY_CHAIN_STORES_GROUPS = '[Rest Call] [Gateway] GetGatewayChainStoresGroups',
  GET_GATEWAY_CHAIN_STORES_GROUPS_RES = '[Rest Result] [Gateway] GetGatewayChainStoresGroupsResults',
  GET_GATEWAY_CHAIN_STORES_GROUPS_ERR = '[Rest Error] [Gateway] GetGatewayChainStoresGroupsError',
  GET_GATEWAY_CHAIN_STORES_GROUPS_CLEANUP = '[Rest Call] [Gateway] GetGatewayChainStoresGroupsCleanup',

  GET_GATEWAY_TREE = '[Rest Call] [Gateway] GetGatewayTreeList',
  GET_GATEWAY_TREE_RES = '[Rest Result] [Gateway] GetGatewayTreeListResults',
  GET_GATEWAY_TREE_ERR = '[Rest Error] [Gateway] GetGatewayTreeListError',
  GET_GATEWAY_TREE_CLEANUP = '[Rest Call] [Gateway] GetGatewayTreeListCleanup',

  GET_CHAIN_SETTINGS = '[Rest Call] [Gateway] GetChainSettings',
  GET_CHAIN_SETTINGS_RES = '[Rest Result] [Gateway] GetChainSettingsResults',
  GET_CHAIN_SETTINGS_ERR = '[Rest Error] [Gateway] GetChainSettingsError',

  SET_CHAIN_SETTINGS = '[Rest Call] [Gateway] SetChainSettings',
  SET_CHAIN_SETTINGS_RES = '[Rest Result] [Gateway] SetChainSettingsResults',
  SET_CHAIN_SETTINGS_ERR = '[Rest Error] [Gateway] SetChainSettingsError',

  GET_GATEWAY_CHAIN = '[Rest Call] [Gateway] GetGatewayChain',
  GET_GATEWAY_CHAIN_RES = '[Rest Result] [Gateway] GetGatewayChainResults',
  GET_GATEWAY_CHAIN_ERR = '[Rest Error] [Gateway] GetGatewayChainError',

  GET_DISPENSE_FEE_FORMULARY_SETUP = '[Rest Call] [Gateway] GetDispenseFeeFormularySetup',
  GET_DISPENSE_FEE_FORMULARY_SETUP_RES = '[Rest Result] [Gateway] GetDispenseFeeFormularySetupResults',
  GET_DISPENSE_FEE_FORMULARY_SETUP_ERR = '[Rest Error] [Gateway] GetDispenseFeeFormularySetupError',
  GET_DISPENSE_FEE_FORMULARY_SETUP_CLEANUP = '[Rest Response] [Gateway] GetDispenseFeeFormularySetupCleanUp',

  GET_DISPENSE_FEE_FORMULARY_SETUP_CSGIDS = '[Rest Call] [Gateway] GetDispenseFeeFormularySetupCSGIDs',
  GET_DISPENSE_FEE_FORMULARY_SETUP_CSGIDS_RES = '[Rest Result] [Gateway] GetDispenseFeeFormularySetupCSGIDsResults',
  GET_DISPENSE_FEE_FORMULARY_SETUP_CSGIDS_ERR = '[Rest Error] [Gateway] GetDispenseFeeFormularySetupCSGIDsError',

  GET_DISPENSE_FEE_SCHEDULE = '[Rest Call] [Gateway] GetDispenseFeeSchedule',
  GET_DISPENSE_FEE_SCHEDULE_RES = '[Rest Result] [Gateway] GetDispenseFeeScheduleResults',
  GET_DISPENSE_FEE_SCHEDULE_ERR = '[Rest Error] [Gateway] GetDispenseFeeScheduleError',

  ADD_DISPENSE_FEE_SCHEDULE = '[Rest Call] [Gateway] AddDispenseFeeSchedule',
  ADD_DISPENSE_FEE_SCHEDULES = '[Rest Call] [Gateway] AddDispenseFeeSchedules',
  ADD_DISPENSE_FEE_SCHEDULE_RES = '[Rest Result] [Gateway] AddDispenseFeeScheduleResults',
  ADD_DISPENSE_FEE_SCHEDULE_ERR = '[Rest Error] [Gateway] AddDispenseFeeScheduleError',

  GET_DISPENSE_FEE_SCHEDULE_HISTORY = '[Rest Call] [Gateway] GetDispenseFeeScheduleHistory',
  GET_DISPENSE_FEE_SCHEDULE_HISTORY_RES = '[Rest Result] [Gateway] GetDispenseFeeScheduleHistoryResults',
  GET_DISPENSE_FEE_SCHEDULE_HISTORY_ERR = '[Rest Error] [Gateway] GetDispenseFeeScheduleHistoryError',

  GET_DISPENSE_FEE_FORMULARY_CURRENT = '[Rest Call] [Gateway] GetDispenseFeeFormularyCurrent',
  GET_DISPENSE_FEE_FORMULARY_CURRENT_RES = '[Rest Result] [Gateway] GetDispenseFeeFormularyCurrentResults',
  GET_DISPENSE_FEE_FORMULARY_CURRENT_ERR = '[Rest Error] [Gateway] GetDispenseFeeFormularyCurrentError',

  GET_DISPENSE_FEE_FORMULARY_UPLOADS = '[Rest Call] [Gateway] GetDispenseFeeFormularyUploads',
  GET_DISPENSE_FEE_FORMULARY_UPLOADS_RES = '[Rest Result] [Gateway] GetDispenseFeeFormularyUploadsResults',
  GET_DISPENSE_FEE_FORMULARY_UPLOADS_ERR = '[Rest Error] [Gateway] GetDispenseFeeFormularyUploadsError',
  GET_DISPENSE_FEE_FORMULARY_UPLOADS_CLEANUP = '[Rest Response] [Gateway] GetDispenseFeeFormularyUploadsCleanUp',

  UPDATE_DISPENSE_FEE_SCHEDULE = '[Rest Call] [Gateway] UpdateDispenseFeeSchedule',
  UPDATE_DISPENSE_FEE_SCHEDULE_RES = '[Rest Result] [Gateway] UpdateDispenseFeeScheduleResults',
  UPDATE_DISPENSE_FEE_SCHEDULE_ERR = '[Rest Error] [Gateway] UpdateDispenseFeeScheduleError',

  DELETE_DISPENSE_FEE_SCHEDULE = '[Rest Call] [Gateway] DeleteDispenseFeeSchedule',
  DELETE_DISPENSE_FEE_SCHEDULE_RES = '[Rest Result] [Gateway] DeleteDispenseFeeScheduleResults',
  DELETE_DISPENSE_FEE_SCHEDULE_ERR = '[Rest Error] [Gateway] DeleteDispenseFeeScheduleError',

  GET_COVERED_ENTITIES_FOR_CSGID = '[Rest Call] [Gateway] GetCoveredEntitiesForCSGID',
  GET_COVERED_ENTITIES_FOR_CSGID_RES = '[Rest Result] [Gateway] GetCoveredEntitiesForCSGIDResults',
  GET_COVERED_ENTITIES_FOR_CSGID_ERR = '[Rest Error] [Gateway] GetCoveredEntitiesForCSGIDError',

  SELECT_CHAIN_GROUP = '[Gateway] SelectChainGroup'
}

export const GetGatewayChainStoresGroups = createAction(GatewayStateActionTypes.GET_GATEWAY_CHAIN_STORES_GROUPS, props<{ }>());
export const GetGatewayChainStoresGroupsResults = createAction(GatewayStateActionTypes.GET_GATEWAY_CHAIN_STORES_GROUPS_RES, props<{ chainList: ChainStoresGroupsResponse }>());
export const GetGatewayChainStoresGroupsError = createAction(GatewayStateActionTypes.GET_GATEWAY_CHAIN_STORES_GROUPS_ERR, props<{ message: string }>());
export const GetGatewayChainStoresGroupsCleanup = createAction(GatewayStateActionTypes.GET_GATEWAY_CHAIN_STORES_GROUPS_CLEANUP);

export const GetGatewayTreeList = createAction(GatewayStateActionTypes.GET_GATEWAY_TREE, props<{ CSGID: number }>());
export const GetGatewayTreeListResults = createAction(GatewayStateActionTypes.GET_GATEWAY_TREE_RES, props<{ treeview: any }>());
export const GetGatewayTreeListError = createAction(GatewayStateActionTypes.GET_GATEWAY_TREE_ERR, props<{ message: string }>());
export const GetGatewayTreeListCleanup = createAction(GatewayStateActionTypes.GET_GATEWAY_TREE_CLEANUP);

export const GetChainSettings = createAction(GatewayStateActionTypes.GET_CHAIN_SETTINGS, props<{ chainId: number }>());
export const GetChainSettingsResults = createAction(GatewayStateActionTypes.GET_CHAIN_SETTINGS_RES, props<{ chainSettings: ChainSettingsResponse }>());
export const GetChainSettingsError = createAction(GatewayStateActionTypes.GET_CHAIN_SETTINGS_ERR, props<{ message: string }>());

export const SetChainSettings = createAction(GatewayStateActionTypes.SET_CHAIN_SETTINGS, props<{ chainSettingsRequest: ChainSettingsRequest }>());
export const SetChainSettingsResults = createAction(GatewayStateActionTypes.SET_CHAIN_SETTINGS_RES, props<{ response: any }>());
export const SetChainSettingsError = createAction(GatewayStateActionTypes.SET_CHAIN_SETTINGS_ERR, props<{ message: string }>());

export const GetGatewayChain = createAction(GatewayStateActionTypes.GET_GATEWAY_CHAIN, props<{ CSGID: number }>());
export const GetGatewayChainResults = createAction(GatewayStateActionTypes.GET_GATEWAY_CHAIN_RES, props<{ chainData: ChainResponse }>());
export const GetGatewayChainError = createAction(GatewayStateActionTypes.GET_GATEWAY_CHAIN_ERR, props<{ message: string }>());

export const GetDispenseFeeFormularySetup = createAction(GatewayStateActionTypes.GET_DISPENSE_FEE_FORMULARY_SETUP, props<{ CSGID: number, effectiveDate: string }>());
export const GetDispenseFeeFormularySetupResults = createAction(GatewayStateActionTypes.GET_DISPENSE_FEE_FORMULARY_SETUP_RES, props<{ formularySetupData: FormularySetupResponse }>());
export const GetDispenseFeeFormularySetupError = createAction(GatewayStateActionTypes.GET_DISPENSE_FEE_FORMULARY_SETUP_ERR, props<{ message: string }>());
export const GetDispenseFeeFormularySetupCleanUp = createAction(GatewayStateActionTypes.GET_DISPENSE_FEE_FORMULARY_SETUP_CLEANUP, props<{  }>());

export const GetDispenseFeeFormularySetupCSGIDs = createAction(GatewayStateActionTypes.GET_DISPENSE_FEE_FORMULARY_SETUP_CSGIDS, props<{ }>());
export const GetDispenseFeeFormularySetupCSGIDsResults = createAction(GatewayStateActionTypes.GET_DISPENSE_FEE_FORMULARY_SETUP_CSGIDS_RES, props<{ formularySetupCSGIDs: FormularySetupCSGIDsResponse }>());
export const GetDispenseFeeFormularySetupCSGIDsError = createAction(GatewayStateActionTypes.GET_DISPENSE_FEE_FORMULARY_SETUP_CSGIDS_ERR, props<{ message: string }>());

export const AddDispenseFeeSchedule = createAction(GatewayStateActionTypes.ADD_DISPENSE_FEE_SCHEDULE, props<{ addDispenseFeeScheduleRequest: AddDispenseFeeScheduleRequest }>());
export const AddDispenseFeeSchedules = createAction(GatewayStateActionTypes.ADD_DISPENSE_FEE_SCHEDULES, props<{ addDispenseFeeSchedulesRequest: AddDispenseFeeScheduleRequest[] }>());
export const AddDispenseFeeScheduleResults = createAction(GatewayStateActionTypes.ADD_DISPENSE_FEE_SCHEDULE_RES, props<{ response: any }>());
export const AddDispenseFeeScheduleError = createAction(GatewayStateActionTypes.ADD_DISPENSE_FEE_SCHEDULE_ERR, props<{ message: string }>());

export const GetDispenseFeeSchedule = createAction(GatewayStateActionTypes.GET_DISPENSE_FEE_SCHEDULE, props<{ CSGID: number }>());
export const GetDispenseFeeScheduleResults = createAction(GatewayStateActionTypes.GET_DISPENSE_FEE_SCHEDULE_RES, props<{ dispenseFeeScheduleResponse: DispenseFeeScheduleResponse }>());
export const GetDispenseFeeScheduleError = createAction(GatewayStateActionTypes.GET_DISPENSE_FEE_SCHEDULE_ERR, props<{ message: string }>());

export const GetDispenseFeeScheduleHistory = createAction(GatewayStateActionTypes.GET_DISPENSE_FEE_SCHEDULE_HISTORY, props<{ CSGID: number, DrugType: string }>());
export const GetDispenseFeeScheduleHistoryResults = createAction(GatewayStateActionTypes.GET_DISPENSE_FEE_SCHEDULE_HISTORY_RES, props<{ dispenseFeeScheduleHistoryResponse: DispenseFeeScheduleHistoryResponse }>());
export const GetDispenseFeeScheduleHistoryError = createAction(GatewayStateActionTypes.GET_DISPENSE_FEE_SCHEDULE_HISTORY_ERR, props<{ message: string }>());

export const GetDispenseFeeFormularyCurrent = createAction(GatewayStateActionTypes.GET_DISPENSE_FEE_FORMULARY_CURRENT, props<{ CSGID: number }>());
export const GetDispenseFeeFormularyCurrentResults = createAction(GatewayStateActionTypes.GET_DISPENSE_FEE_FORMULARY_CURRENT_RES, props<{ formularyCurrentResponse: FormularyCurrentResponse }>());
export const GetDispenseFeeFormularyCurrentError = createAction(GatewayStateActionTypes.GET_DISPENSE_FEE_FORMULARY_CURRENT_ERR, props<{ message: string }>());

export const GetDispenseFeeFormularyUploads = createAction(GatewayStateActionTypes.GET_DISPENSE_FEE_FORMULARY_UPLOADS, props<{ CSGID: number }>());
export const GetDispenseFeeFormularyUploadsResults = createAction(GatewayStateActionTypes.GET_DISPENSE_FEE_FORMULARY_UPLOADS_RES, props<{ formularyUploadsResponse: FormularySetupResponse }>());
export const GetDispenseFeeFormularyUploadsError = createAction(GatewayStateActionTypes.GET_DISPENSE_FEE_FORMULARY_UPLOADS_ERR, props<{ message: string }>());
export const GetDispenseFeeFormularyUploadsCleanUp = createAction(GatewayStateActionTypes.GET_DISPENSE_FEE_FORMULARY_UPLOADS_CLEANUP);

export const UpdateDispenseFeeSchedule = createAction(GatewayStateActionTypes.UPDATE_DISPENSE_FEE_SCHEDULE, props<{ updateDispenseFeeScheduleRequest: UpdateDispenseFeeScheduleRequest }>());
export const UpdateDispenseFeeScheduleResults = createAction(GatewayStateActionTypes.UPDATE_DISPENSE_FEE_SCHEDULE_RES, props<{ response: any }>());
export const UpdateDispenseFeeScheduleError = createAction(GatewayStateActionTypes.UPDATE_DISPENSE_FEE_SCHEDULE_ERR, props<{ message: string }>());

export const DeleteDispenseFeeSchedule = createAction(GatewayStateActionTypes.DELETE_DISPENSE_FEE_SCHEDULE, props<{ deleteDispenseFeeScheduleRequest: DeleteDispenseFeeScheduleRequest }>());
export const DeleteDispenseFeeScheduleResults = createAction(GatewayStateActionTypes.DELETE_DISPENSE_FEE_SCHEDULE_RES, props<{ response: any }>());
export const DeleteDispenseFeeScheduleError = createAction(GatewayStateActionTypes.DELETE_DISPENSE_FEE_SCHEDULE_ERR, props<{ message: string }>());

export const GetCoveredEntitiesForCSGID = createAction(GatewayStateActionTypes.GET_COVERED_ENTITIES_FOR_CSGID, props<{ CSGID: number }>());
export const GetCoveredEntitiesForCSGIDResults = createAction(GatewayStateActionTypes.GET_COVERED_ENTITIES_FOR_CSGID_RES, props<{ coveredEntityResponse: CoveredEntityResponse }>());
export const GetCoveredEntitiesForCSGIDError = createAction(GatewayStateActionTypes.GET_COVERED_ENTITIES_FOR_CSGID_ERR, props<{ message: string }>());

export const SelectChainGroup = createAction(GatewayStateActionTypes.SELECT_CHAIN_GROUP, props<{ chainGroup: ChainStoresGroups }>());