import {VERSION} from "./version";

export const environment = {
  MacroHelixSupportUrl: 'https://help.mhiapps.com/',
  production: true,
  appVersion: "-"+VERSION.hash+"-dev",
  apiBaseUrl: 'https://devapi.mhiapps.com/',
  baseUrl: 'https://dev4.mhiapps.com/ngWeb',
  loginUrl: 'https://dev.mhiapps.com/',
  ssoLoginUrl: 'https://fedsvc.mckesson.com/adfs/ls/',
  subcriptionKey: '50d8166508c746b896a741ccd4e59978',
  UAMUserApiEndpoint: "user/",
  appInsights: {
    instrumentationKey: 'dc268550-d2d3-4ad1-88af-f688c170e24d',
    applicationName: 'MHIApps'
  },
  b2c: {
    TenantName: "mhiappsb2cdev",
    SignInUrl: "https://mhiappsb2cdev.b2clogin.com/mhiappsb2cdev.onmicrosoft.com/b2c_1a_mhiappsdev_emailonlysignin/oauth2/v2.0/authorize",
    ClientId: "8c1a63aa-45be-4aae-a1ec-c815f54ea0a3",
    RedirectUrl: "https://ngdev.mhiapps.com/",
    LogoutUrl: "https://dev.mhiapps.com/",
    SignInPolicy: "B2C_1A_mhiappsdev_EmailOnlySignIn",
    OldSignInPolicy: "B2C_1A_mhiappsdev_JITMigrationSignIn",
    ResetPasswordPolicy: "B2C_1A_mhiappsdev_PasswordReset",
    LoggedInPasswordResetPolicyId: "B2C_1A_mhiappsdev_PasswordChange",
    UserChangeMFAPolicyId: "B2C_1A_mhiappsdev_MFAChange",
    UserApiEndpoint: "user/v1/login",
    SignUpPolicy: "B2C_1A_mhiappsdev_OnboardingSignUp",
    ConnectSignInPolicy: "B2C_1A_mhiappsdev_ConnectSignIn"
  },
  analyticsReportNames: '340BI Reporting,340BI Optimization,340BI Account Table',
  embeddingPageUrl: 'https://dev4.mhiapps.com/ngWeb/EmbeddingPage.aspx'
};
