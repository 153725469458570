import {ApplicationMenuItem} from "./models/applications";

export const Pages: {[key: string]: ApplicationMenuItem} = {

  viewProfile : {
    pageID: -1,
    appID: -1,
    active: 1,
    sectionName: '',
    pageName: 'User Profile',
    ngPageURL: 'viewProfile',
    aspPageURL: null,
    pageOrder: -1,
    sectionOrder: -1,
    permissionKey: ''
  },
  DASHBOARD_MENU_ITEM_MM: {
    appID: 1,
    aspPageURL: "/WebApplications/MacroManager/Overview/Dashboard.aspx",
    ngPageURL: "/MacroManager/dashboard",
    pageID: 1,
    pageName: "MHI Dashboard",
    pageOrder: 1001,
    sectionName: "Overview",
    sectionOrder: 1,
    active: 1,
    permissionKey: 'MHIDASHBOARD'
  },
  DASHBOARD_MENU_ITEM_340B: {
    pageID: 39,
    appID: 2,
    active: 1,
    sectionName: 'Overview',
    pageName: 'Pharmacy Dashboard',
    ngPageURL: '/340bArchitect/dashboard',
    aspPageURL: '/WebApplications/340BArchitect/Overview/Dashboard.aspx',
    pageOrder: 2001,
    sectionOrder: 1,
    permissionKey: 'PHARMACYDASHBOARD'
  },
  DASHBOARD_MENU_ITEM_NDC: {
    pageID: 59,
    appID: 3,
    active: 1,
    sectionName: 'Overview',
    pageName: 'NDCA Dashboard',
    ngPageURL: '/NDCArchitect/dashboard',
    aspPageURL: '/WebApplications/NDCArchitect/Overview/Dashboard.aspx',
    pageOrder: 3001,
    sectionOrder: 1,
    permissionKey: 'NDCADASHBOARD'
  },
  CLAIM_ANALYSIS_MENU_ITEM_NDC: {
    pageID: 62,
    appID: 3,
    active: 1,
    sectionName: 'Data Review',
    pageName: 'Claims Analysis',
    ngPageURL: '/NDCArchitect/claims-analysis',
    aspPageURL: '/WebApplications/NDCArchitect/DataReview/ClaimsAnalysis.aspx',
    pageOrder: 3004,
    sectionOrder: 2,
    permissionKey: 'CLAIMSANALYSIS'
  },
  ANALYTICS_MENU_ITEM: {
    pageID: 76,
    appID: 5,
    active: 1,
    sectionName: 'Overview',
    pageName: 'Reports',
    ngPageURL: '/340bAnalytics/reports',
    aspPageURL: '',
    pageOrder: 5001,
    sectionOrder: 1,
    permissionKey: 'REPORTS'
  },
  UAM_MENU_ITEM: {
    pageID: 99,
    appID: 8,
    active : 1,
    sectionName: 'Access Management',
    pageName: 'Access Management',
    ngPageURL: '/UserAccessManagement/access-management',
    aspPageURL: '',
    pageOrder: 8000,
    sectionOrder: 1,
    permissionKey: 'ACCESSMANAGEMENT'
  },
  DASHBOARD_MENU_ITEM_340BGATEWAY: {
    pageID: 98,
    appID: 7,
    active: 1,
    sectionName: 'Gateway',
    pageName: 'Dashboard',
    ngPageURL: '/340bGateway/replenishment-dashboard',
    aspPageURL: '',
    pageOrder: 7000,
    sectionOrder: 1,
    permissionKey: 'REPLENISHMENTDASHBOARD'
  }

}
