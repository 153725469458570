import { Injectable } from '@angular/core';
import { BaseMhService } from './basemh.service';
import { catchError, map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { UserState } from '../store/user.reducer';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { ClientList, PortRequestList } from '../models/hl7';

@Injectable({
  providedIn: 'root'
})

export class HL7Service extends BaseMhService {

  constructor(protected http: HttpClient, protected store: Store<{ userState: UserState }>) 
  {
    super(http, store);
  }

  public getHL7ClientList() {
    return this.http
      .get(environment.apiBaseUrl + 'hl7/v1/hl7/getHL7ClientList', { headers: this.getHeaders() })
      .pipe(
        map((response: ClientList) => response),
        catchError(error => this.errorHandler({ error, operation: "Error while Retrieving HL7 Client List Data" }))
      );
  }

  public getHL7PortSetupRequest() {
    return this.http
      .get(environment.apiBaseUrl + 'hl7/v1/hl7/getHL7NewSetupPorts', { headers: this.getHeaders() })
      .pipe(
        map((response: PortRequestList) => response),
        catchError(error => this.errorHandler({ error, operation: "Error while Retrieving HL7 List of new ports" }))
      );
  }

 
}


