import { NestedTreeControl } from '@angular/cdk/tree';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { FormControl, Validators } from '@angular/forms';
import { SelectionModel } from '@angular/cdk/collections';
import { ClearGatewayData, CloseClientSelection, GetCSGIDList, GetSelectedCSGID, RegenerateUserToken, RegenerateUserTokenError, RegenerateUserTokenResults, ShowAccessDeniedPage } from 'src/app/store/user.actions';
import { SearchCorpsChainGroups } from 'src/app/store/uam/uam.actions';
import { UamState } from 'src/app/store/uam/uam.reducer';
import { UserService } from 'src/app/service/user.service';
import { UserState } from 'src/app/store/user.reducer';
import { ResultItem } from 'src/app/models/User-Access-Management/client';
import { Subscription } from 'rxjs';


/**
 * data with nested structure.
 * Each node has a name and an optional list of children.
 */
interface node {
  id?: number;
  name: string;
  level?: number;
  type?: boolean;
  children?: node[];
}

/**
 * Health System is structured like such
 * Health System
 *     => Corp
 *          => HID
 *              => PID    
 * 
 * Pharmacy Chains is structured like
 * Pharmacy Chains
 *      => Chain Group
 */
let TREE_DATA: node[] = [];

@Component({
  selector: 'clientID-selection',
  templateUrl: './clientID-selection.component.html',
  styleUrls: ['./clientID-selection.component.scss'],
})

export class ClientIDSelectionComponent implements OnInit {

  @ViewChild('tree') tree;
  clientTypesControl = new FormControl("Corporate", Validators.required);
  clientTypes: String[] = ["Corporate", "Hospital", "Pharmacy", "Chain"];

  selection = new SelectionModel<node>(true, []);

  treeControl = new NestedTreeControl<node>(node => node.children);
  dataSource = new MatTreeNestedDataSource<node>();
  searchString = ''
  corpList: any[] = [];
  chainList: any[] = [];
  csgids: any[] = []; // Gateway chains
  showNoResults: boolean = false;
  callInProgress: boolean = false;
  private subscriptions = new Subscription();

  constructor(private actions$: Actions, private store: Store<{ uamState: UamState, userState: UserState }>, public userService: UserService) {
  }

  hasChild = (_: number, node: node) => !!node.children && node.children.length > 0;

  ngOnInit() {
    this.subscriptions.add(
      this.store.select(state => state.uamState.initialClientList).subscribe((data) => {
        if (data != null) {
          TREE_DATA = [];

          if (data.corps.length > 0) {
            let corps : node = {
              name: data.corps.length > 0 ? 'Health Systems' : null,
              children: data.corps.length > 0 ? data.corps : null
            }
            TREE_DATA.push(corps);
          }
          
          if (data.chains.length > 0) {
            let chains : node = {
              name: data.chains.length > 0 ? 'Pharmacy Chains' : null,
              children: data.chains.length > 0 ? data.chains : null
            }
            TREE_DATA.push(chains);
          }

          this.corpList = data.corps;
          this.chainList = data.chains;
          this.dataSource.data = TREE_DATA;
        }
      })
    );

    this.subscriptions.add(
      this.store.select(state => state.uamState.searchClientList).subscribe((data) => {
        if (data) {
          if (data.chains.length + data.corps.length == 0) {
            this.showNoResults = true;
          }
          else {
            let TREE_DATA_SEARCH: node[] = [];
  
            TREE_DATA_SEARCH = [
              {
                name: data.corps.length > 0 ? 'Health Systems' : 'Pharmacy Chains',
                children: data.corps.length > 0 ? data.corps : data.chains
              }
            ];
  
            this.showNoResults = false;
            this.dataSource.data = TREE_DATA_SEARCH;
            this.treeControl.dataNodes = this.dataSource.data;
            this.treeControl.expandAll();
          }
          this.callInProgress = false;
        }
      })
    );
  
    this.actions$.pipe(ofType(RegenerateUserTokenResults)).subscribe(() => {
      this.callInProgress = false;
      this.store.dispatch(CloseClientSelection());
    });

    this.clearSearch()
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  selectNode(node: node) {
    if (!this.selection.isEmpty()) {
      this.selection.clear();
    }
    this.selection.select(node);

  }

  regenerateToken() {
    let isCorp: boolean;
    this.selection.selected.forEach(node => {
      // we know that only corps have children below the one selected, chain group does not have children

      // Make the determination here if node is corp or chain from corpList + chainList
      this.corpList.find(corp => {
        if (corp.name.includes(node.name) && corp.id == node.id) {
          isCorp = true;
          this.store.dispatch(GetSelectedCSGID({ csgId: "-1", name: "" }));
          return;
        }
      });

      this.chainList.find(chain => {
        if (chain.name.includes(node.name) && chain.id == node.id) {
          isCorp = false;  
          this.store.dispatch(GetSelectedCSGID({ csgId: node.id.toString(), name: node.name }));
          return;
        }
      });

      // set cookie for 24 hours
      let date = new Date();
      this.userService.createCookie("ClientId", node.id + '-' + (isCorp ? 1 : 0), new Date(date.setTime(date.getTime() + 86400000)));
      this.callInProgress = true;
      // update token, display blank page while app loads
      this.store.dispatch(RegenerateUserToken({ request: { pid: 0, clientId: node.id, isCorp: isCorp } }));
    });
  }

  search() {
    this.selection.clear();
    let searchType;
    switch (this.clientTypesControl.value) {
      case "Corporate":
        searchType = 1;
        break;
      case "Hospital":
        searchType = 2;
        break;
      case "Pharmacy":
        searchType = 3;
        break;
      case "Chain":
        searchType = 4;
        break;
    }

    this.store.dispatch(SearchCorpsChainGroups({ keyword: this.searchString, searchType: searchType }));
    this.callInProgress = true;
  }

  // clearSearch and checkSearchEmpty will revert tree back to intial data
  clearSearch() {
    this.searchString = '';
    this.dataSource.data = TREE_DATA;
    this.showNoResults = false;
    this.selection.clear();
  }

  checkSearchEmpty() {
    if (this.searchString.length == 0) {
      this.dataSource.data = TREE_DATA;
      this.selection.clear();
    }
  }


}
