import { createAction, props } from '@ngrx/store';
import {
  vmCD_Group_View, TableGetOptions, CD_GroupName, CD_PidList,
  vmCD_Group_View_Details, vmCD_Group_Vendor, vmCD_Group_Sequence,
  vmCD_Group_TerminalAccount, saveCDGroup, vmCD_Contact
} from '../models/received-detail';

export enum OrderActionTypes {
  GET_DATA = '[Rest Call] [Order] GetData',
  GET_DATA_RES = '[Rest Result] [Order] CDDataGetResults',
  GET_DATA_ERR = '[Rest Error] [Order] CDDataGetError',

  GET_CENTRALSPLIT_DATA = '[Silent Rest Call] [Order] GetCentralSplitData',
  GET_CENTRALSPLIT_DATA_RES = '[Silent Rest Result] [Order] GetCentralSplitDataResults',
  GET_CENTRALSPLIT_DATA_ERR = '[Silent Rest Error] [Order] GetCentralSplitDataError',

  GET_CDDETAILS_BYID = '[Rest Call] [Order] GetViewDetails',
  GET_CDDETAILS_BYID_RES = '[Rest Result] [Order] GetCDDataViewDetailResults',
  GET_CDDETAILS_BYID_ERR = '[Rest Error] [Order] GetCDDataViewDetailError',

  GET_CDVENDOR = '[Rest Call] [Order] GetVendor',
  GET_CDVENDOR_RES = '[Rest Result] [Order] GetVendorResults',
  GET_CDVENDOR_ERR = '[Rest Error] [Order] GetVendorError',

  GET_CD_SEQUENCE = '[Rest Call] [Order] GetSequence',
  GET_CD_SEQUENCE_RES = '[Rest Result] [Order] GetSequenceResults',
  GET_CD_SEQUENCE_ERR = '[Rest Error] [Order] GetSequenceError',

  UPDATE_CDGROUP = '[Rest Call] [Order] UpdateCDGroup',
  UPDATE_CDGROUP_RES = '[Rest Result] [Order] UpdateCDGroupResults',
  UPDATE_CDGROUP_ERR = '[Rest Error] [Order] UpdateCDGroupError',

  IS_VALID_TERMINALACNT = '[Rest Call] [Order] IsValidTerminalAccount',
  IS_VALID_TERMINALACNT_RES = '[Rest Result] [Order] IsValidTerminalAccountResults',
  IS_VALID_TERMINALACNT_ERR = '[Rest Error] [Order] IsValidTerminalAccountError',

  IS_VALID_GROUPNAME = '[Rest Call] [Order] IsValidGroupName',
  IS_VALID_GROUPNAME_RES = '[Rest Result] [Order] IsValidGroupNameResults',
  IS_VALID_GROUPNAME_ERR = '[Rest Error] [Order] IsValidGroupNameError',

  ADD_CDGROUP = '[Rest Call] [Order] AddCDGroup',
  ADD_CDGROUP_RES = '[Rest Result] [Order] AddCDGroupResults',
  ADD_CDGROUP_ERR = '[Rest Error] [Order] AddCDGroupError',

  GET_CD_CONTACTS = '[Rest Call] [Order] GetContacts',
  GET_CD_CONTACTS_RES = '[Rest Result] [Order] GetContactsResults',
  GET_CD_CONTACTS_ERR = '[Rest Error] [Order] GetContactsError',

  GET_CD_CONTACTS_BY_ID = '[Rest Call] [Order] GetContactsById',
  GET_CD_CONTACTS_BY_ID_RES = '[Rest Result] [Order] GetContactsByIdResults',
  GET_CD_CONTACTS_BY_ID_ERR = '[Rest Error] [Order] GetContactsByIdError',

  GET_CD_CONTACTS_BY_SEARCHVAL = '[Rest Call] [Order] GetContactsBySearchVal',
  GET_CD_CONTACTS_BY_SEARCHVAL_RES = '[Rest Result] [Order] GetContactsBySearchValResults',
  GET_CD_CONTACTS_BY_SEARCHVAL_ERR = '[Rest Error] [Order] GetContactsBySearchValError',
}


export const GetCDData = createAction(OrderActionTypes.GET_DATA, props<{ options: TableGetOptions }>());
export const GetCDDataResults = createAction(OrderActionTypes.GET_DATA_RES, props<{ centraldistribution: vmCD_Group_View[] }>());
export const GetCDDataError = createAction(OrderActionTypes.GET_DATA_ERR, props<{ message: string }>());

export const GetCentralSplitData = createAction(OrderActionTypes.GET_CENTRALSPLIT_DATA, props<{ options: TableGetOptions }>());
export const GetCentralSplitDataResults = createAction(OrderActionTypes.GET_CENTRALSPLIT_DATA_RES, props<{ centralsplit: vmCD_Group_View[] }>());
export const GetCentralSplitDataError = createAction(OrderActionTypes.GET_CENTRALSPLIT_DATA_ERR, props<{ message: string }>());


export const GetViewDetails = createAction(OrderActionTypes.GET_CDDETAILS_BYID, props<{ groupID: number, options: TableGetOptions }>());
export const GetCDDataViewDetailResults = createAction(OrderActionTypes.GET_CDDETAILS_BYID_RES, props<{ viewdetailsdata: vmCD_Group_View_Details }>());
export const GetCDDataViewDetailError = createAction(OrderActionTypes.GET_CDDETAILS_BYID_ERR, props<{ message: string }>());

export const GetVendor = createAction(OrderActionTypes.GET_CDVENDOR, props<{ wacaccount: vmCD_Group_TerminalAccount }>());
export const GetVendorResults = createAction(OrderActionTypes.GET_CDVENDOR_RES, props<{ vendorname: vmCD_Group_Vendor }>());
export const GetVendorError = createAction(OrderActionTypes.GET_CDVENDOR_ERR, props<{ message: string }>());

export const GetSequence = createAction(OrderActionTypes.GET_CD_SEQUENCE);
export const GetSequenceResults = createAction(OrderActionTypes.GET_CD_SEQUENCE_RES, props<{ sequences: vmCD_Group_Sequence[] }>());
export const GetSequenceError = createAction(OrderActionTypes.GET_CD_SEQUENCE_ERR, props<{ message: string }>());

export const UpdateCDGroup = createAction(OrderActionTypes.UPDATE_CDGROUP, props<{ saveData: saveCDGroup }>());
export const UpdateCDGroupResults = createAction(OrderActionTypes.UPDATE_CDGROUP_RES, props<{ saveData: saveCDGroup }>());
export const UpdateCDGroupError = createAction(OrderActionTypes.UPDATE_CDGROUP_ERR, props<{ message: string }>());

export const IsValidTerminalAccount = createAction(OrderActionTypes.IS_VALID_TERMINALACNT, props<{ wacaccount: vmCD_Group_TerminalAccount }>());
export const IsValidTerminalAccountResults = createAction(OrderActionTypes.IS_VALID_TERMINALACNT_RES, props<{ count: number }>());
export const IsValidTerminalAccountError = createAction(OrderActionTypes.IS_VALID_TERMINALACNT_ERR, props<{ message: string }>());

export const IsValidGroupName = createAction(OrderActionTypes.IS_VALID_GROUPNAME, props<{ groupname: CD_GroupName }>());
export const IsValidGroupNameResults = createAction(OrderActionTypes.IS_VALID_GROUPNAME_RES, props<{ countval: number }>());
export const IsValidGroupNameError = createAction(OrderActionTypes.IS_VALID_GROUPNAME_ERR, props<{ message: string }>());

export const AddCDGroup = createAction(OrderActionTypes.ADD_CDGROUP, props<{ saveData: saveCDGroup }>());
export const AddCDGroupResults = createAction(OrderActionTypes.ADD_CDGROUP_RES, props<{ saveData: saveCDGroup }>());
export const AddCDGroupError = createAction(OrderActionTypes.ADD_CDGROUP_ERR, props<{ message: string }>());

export const GetContacts = createAction(OrderActionTypes.GET_CD_CONTACTS, props<{ pids: number[], options: TableGetOptions }>());
export const GetContactsResults = createAction(OrderActionTypes.GET_CD_CONTACTS_RES, props<{ contactsBySearch: vmCD_Contact[] }>());
export const GetContactsError = createAction(OrderActionTypes.GET_CD_CONTACTS_ERR, props<{ message: string }>());

export const GetContactsById = createAction(OrderActionTypes.GET_CD_CONTACTS_BY_ID, props<{ groupID: number, options: TableGetOptions }>());
export const GetContactsByIdResults = createAction(OrderActionTypes.GET_CD_CONTACTS_BY_ID_RES, props<{ contacts: vmCD_Contact[] }>());
export const GetContactsByIdError = createAction(OrderActionTypes.GET_CD_CONTACTS_BY_ID_ERR, props<{ message: string }>());

export const GetContactsBySearchVal = createAction(OrderActionTypes.GET_CD_CONTACTS_BY_SEARCHVAL, props<{ contactdata: CD_PidList, options: TableGetOptions }>());
export const GetContactsBySearchValResults = createAction(OrderActionTypes.GET_CD_CONTACTS_BY_SEARCHVAL_RES, props<{ contactsBySearch: vmCD_Contact[] }>());
export const GetContactsBySearchValError = createAction(OrderActionTypes.GET_CD_CONTACTS_BY_SEARCHVAL_ERR, props<{ message: string }>());
