import { AllOtherPrescriberOutputDto } from "src/app/models/prescriber-list/all-other-prescriber-output";
import { ErrorResponse } from "src/app/models/prescriber-list/error-response";
import { Prescriber } from "src/app/models/prescriber-list/prescriber";
import { PrescriberColumnDTO, PrescriberUploadColumnsDTO } from "src/app/models/prescriber-list/prescriber-column-dto";
import { PrescriberListUploadErrorReponseOutputDto } from "src/app/models/prescriber-list/prescriber-list-upload-error-response-output";
import { PrescriberListUploadOutputDto } from "src/app/models/prescriber-list/prescriber-list-upload-output";
import { PrescriberOutputDto } from "src/app/models/prescriber-list/prescriber-output-dto";
import { PrescriberActionTypes } from "./prescriber-list.actions";
import { PrescriberHistory } from "src/app/models/prescriber-list/prescriber-history";
//State to hold all Prescriber List data
export interface PrescriberState {
  prescriberList: PrescriberOutputDto,
  columns: PrescriberColumnDTO[],
  validationErrors: ErrorResponse,
  prescriberDetails: Prescriber,
  prescriberListUpload: PrescriberListUploadOutputDto,
  prescriberListUploadErrorResponse: PrescriberListUploadErrorReponseOutputDto,
  prescriberUploadColumns: PrescriberUploadColumnsDTO[],
  prescriberHistory: PrescriberHistory[],
  allPrescriberListUpload: AllOtherPrescriberOutputDto,
  deactivatePrescriber: any
}

//Default initial state
export const initialState =
{
  prescriberList: null,
  columns: null,
  validationErrors: null,
  prescriberDetails: null,
  prescriberListUpload: null,
  prescriberListUploadErrorResponse: null,
  prescriberUploadColumns: null,
  prescriberHistory: null,
  allPrescriberListUpload: null,
  deactivatePrescriber: null
}

export function prescriberListReducer(state = initialState, action: any): PrescriberState {
  switch (action.type) {
    case PrescriberActionTypes.GET_PRESCRIBER_LIST_RES: {
      return { ...state, prescriberList: action.prescriberList }
    }
    case PrescriberActionTypes.SET_PRESCRIBER_LIST_COLUMNS_RES: {
      return { ...state, columns: action.columns }
    }
    case PrescriberActionTypes.ADD_PRESCRIBER_RES: {
      return { ...state, prescriberDetails: action.newPrescriber }
    }
    case PrescriberActionTypes.VALIDATE_PRESCRIBER_RES: {
      return { ...state, validationErrors: action.response }
    }
    case PrescriberActionTypes.VIEW_PRESCRIBER_RES: {
      return { ...state, prescriberDetails: action.prescriberDetails }
    }
    case PrescriberActionTypes.UPDATE_PRESCRIBER_RES: {
      return { ...state, prescriberDetails: action.newPrescriber }
    }
    case PrescriberActionTypes.GET_PRESCRIBER_LIST_UPLOAD_RES: {
      return { ...state, prescriberListUpload: action.prescriberListUpload }
    }
    case PrescriberActionTypes.GET_PRESCRIBER_LIST_UPLOAD_ERROR_RESPONSE_RES: {
      return { ...state, prescriberListUploadErrorResponse: action.prescriberListUploadErrorResponse }
    }
    case PrescriberActionTypes.SET_PRESCRIBER_LIST_UPLOAD_COLUMNS_RES: {
      return { ...state, prescriberUploadColumns: action.prescriberUploadColumns }
    }
    case PrescriberActionTypes.VIEW_PRESCRIBER_HISTORY_RES: {
      return { ...state, prescriberHistory: action.prescriberHistory }
    }
    case PrescriberActionTypes.GET_ALL_PRESCRIBER_DETAILS_RES: {
      return { ...state, allPrescriberListUpload: action.allPrescriberListUpload }
    }
    case PrescriberActionTypes.DEACTIVATE_PRESCRIBER_RES: {
      return { ...state, deactivatePrescriber: action.response }
    }

    default: {
      return state;
    }
  }
}
