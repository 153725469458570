import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of as observableOf } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import {
  GetAutomatedReferralList, GetAutomatedReferralError, GetAutomatedReferralResults, GetReferralSettings, GetReferralSettingsResults, GetReferralSettingsError, UpdateReferralSettings,
  UpdateReferralSettingsResults, UpdateReferralSettingsError, SetReferralsColumns, SetReferralsColumnsResults, SetReferralsColumnsError, GetReferralsNotes, GetReferralsNotesError, GetReferralsNotesResults, SetReferralsNote, SetReferralsNoteResults, SetReferralsNoteError, GetReferralElements, GetReferralElementsResults, GetReferralElementsError,
  AddReferralAttachment, AddReferralAttachmentError, AddReferralAttachmentResults, GetReferralAttachments, GetReferralAttachmentsResults, GetReferralAttachmentsError, RemoveReferralAttachment, RemoveReferralAttachmentError, RemoveReferralAttachmentResults, CheckReferralMenu, CheckReferralMenuResults, CheckReferralMenuError,
  DismissReferral, DismissReferralResults, DismissReferralError, QualifyReferral, QualifyReferralResults, QualifyReferralError
} from './automated-referral.actions';
import { AutomatedReferralService } from 'src/app/service/automated-referral.service';

@Injectable()
export class ReferralStoreEffects {

  constructor(private automatedReferralService: AutomatedReferralService, private actions$: Actions, private store: Store<{}>) {
  }

  GetAutomatedReferralListEffect$ = createEffect(() => this.actions$.pipe(
    ofType(GetAutomatedReferralList),
    switchMap(action =>
      this.automatedReferralService.getAutomatedReferralList(action.isQualified, action.isDismissed)
        .pipe(
          map(
            data => {
              return GetAutomatedReferralResults({ automatedReferralList: data })
            }
          ),
          catchError(
            error => observableOf(GetAutomatedReferralError({ message: error }))
          )
        )
    )
  ))

  GetReferralSettingsEffect$ = createEffect(() => this.actions$.pipe(
    ofType(GetReferralSettings),
    switchMap(action =>
      this.automatedReferralService.getReferralSettings()
        .pipe(
          map(
            data => {
              return GetReferralSettingsResults({ referralSettings: data })
            }
          ),
          catchError(
            error => observableOf(GetReferralSettingsError({ message: error }))
          )
        )
    )
  ))

  UpdateReferralSettingsEffect$ = createEffect(() => this.actions$.pipe(
    ofType(UpdateReferralSettings),
    switchMap(action =>
      this.automatedReferralService.updateReferralSettings(action.saveData)
        .pipe(
          map(
            data => {
              return UpdateReferralSettingsResults({ response: data })
            }
          ),
          catchError(
            error => observableOf(UpdateReferralSettingsError({ message: error }))
          )
        )
    )
  ))

  SetReferralsColumnsEffect$ = createEffect(() => this.actions$.pipe(
    ofType(SetReferralsColumns),
    switchMap(action =>
      this.automatedReferralService.setReferralsColumns(action.columns)
        .pipe(
          map(
            data => {
              return SetReferralsColumnsResults({ columns: data })
            }
          ),
          catchError(
            error => observableOf(SetReferralsColumnsError({ message: error }))
          )
        )
    )
  ))

  GetReferralNotesEffect$ = createEffect(() => this.actions$.pipe(
    ofType(GetReferralsNotes),
    switchMap(action =>
      this.automatedReferralService.getReferralsNotes(action.sid)
        .pipe(
          map(
            data => {
              return GetReferralsNotesResults({ notes: data })
            }
          ),
          catchError(
            error => observableOf(GetReferralsNotesError({ message: error }))
          )
        )
    )
  ))

  SetReferralSettingsEffect$ = createEffect(() => this.actions$.pipe(
    ofType(SetReferralsNote),
    switchMap(action =>
      this.automatedReferralService.setReferralsNote(action.sid, action.note, action.pid)
        .pipe(
          map(
            data => {
              return SetReferralsNoteResults({ response: data })
            }
          ),
          catchError(
            error => observableOf(SetReferralsNoteError({ message: error }))
          )
        )
    )
  ))

  GetReferralElementsEffect$ = createEffect(() => this.actions$.pipe(
    ofType(GetReferralElements),
    switchMap(action =>
      this.automatedReferralService.getReferralElements(action.sid, action.mhafid)
        .pipe(
          map(
            data => {
              return GetReferralElementsResults({ referralElements: data })
            }
          ),
          catchError(
            error => observableOf(GetReferralElementsError({ message: error }))
          )
        )
    )
  ))


  GetReferralAttachmentsEffect$ = createEffect(() => this.actions$.pipe(
    ofType(GetReferralAttachments),
    switchMap(action =>
      this.automatedReferralService.getReferralAttachments(action.sid)
        .pipe(
          map(
            data => {
              return GetReferralAttachmentsResults({ attachments: data })
            }
          ),
          catchError(
            error => observableOf(GetReferralAttachmentsError({ message: error }))
          )
        )
    )
  ))

  AddReferralAttachmentEffect$ = createEffect(() => this.actions$.pipe(
    ofType(AddReferralAttachment),
    switchMap(action =>
      this.automatedReferralService.addReferralAttachment(action.sid, action.pid, action.rxnumber, action.attachment)
        .pipe(
          map(
            data => {
              return AddReferralAttachmentResults({ response: data })
            }
          ),
          catchError(
            error => observableOf(AddReferralAttachmentError({ message: error }))
          )
        )
    )
  ))

  RemoveReferralAttachmentEffect$ = createEffect(() => this.actions$.pipe(
    ofType(RemoveReferralAttachment),
    switchMap(action =>
      this.automatedReferralService.removeReferralAttachment(action.sid, action.guid, action.pid, action.fileName)
        .pipe(
          map(
            data => {
              return RemoveReferralAttachmentResults({ response: data })
            }
          ),
          catchError(
            error => observableOf(RemoveReferralAttachmentError({ message: error }))
          )
        )
    )
  ))

  CheckReferralMenuEffect$ = createEffect(() => this.actions$.pipe(
    ofType(CheckReferralMenu),
    switchMap(action =>
      this.automatedReferralService.checkReferralMenu(action.uid, action.pid)
        .pipe(
          map(
            data => {
              return CheckReferralMenuResults({ result: data })
            }
          ),
          catchError(
            error => observableOf(CheckReferralMenuError({ message: error }))
          )
        )
    )
  ))
  DismissReferral$ = createEffect(() => this.actions$.pipe(
    ofType(DismissReferral),
    switchMap(action =>
      this.automatedReferralService.dismissReferral(action.mhafid, action.sid)
        .pipe(
          map(
            data => {
              return DismissReferralResults({ response: data })
            }
          ),
          catchError(
            error => observableOf(DismissReferralError({ message: error }))
          )
        )
    )
  ))
  QualifyReferral$ = createEffect(() => this.actions$.pipe(
    ofType(QualifyReferral),
    switchMap(action =>
      this.automatedReferralService.qualifyReferral(action.mhafid, action.sid, action.refPid, action.filltype, action.rxnumber, action.notes, action.isDisqualified)
        .pipe(
          map(
            data => {
              return QualifyReferralResults({ response: data })
            }
          ),
          catchError(
            error => observableOf(QualifyReferralError({ message: error }))
          )
        )
    )
  ))
}
