import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of as observableOf } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { UserService } from 'src/app/service/user.service';
import { ErrShow } from 'src/app/store/ui-state.actions';
import {
  GetPharmacies, GetPharmaciesError, GetPharmaciesResults,
  GetAllPharmacies, GetAllPharmaciesError, GetAllPharmaciesResults,
  GetUserSessionError, GetUserSessionResults,
  GetUserSettings, GetUserSettingsError, GetUserSettingsResults,
  InitUserResults,
  UpdateUserSettings, UpdateUserSettingsResults, UpdateUserSettingsError,
  UserActionTypes,
  UpdateUserSession, UpdateUserSessionResults, UpdateUserSessionError,
  UpdateUserApplication, UpdateUserApplicationResults, UpdateUserApplicationError,
  GetUserEmailSettings, GetUserEmailSettingsResults, GetUserEmailSettingsError,
  UpdateUserEmailSettings, UpdateUserEmailSettingsResults, UpdateUserEmailSettingsError,
  UpdateRecalculate, UpdateRecalculateResults, UpdateRecalculateError,
  UpdateUserQuickAccessPageResults, UpdateUserQuickAccessPage, UpdateUserQuickAccessPageError,
  InsertUserTrackHistory, InsertUserTrackHistoryResults, InsertUserTrackHistoryErrors,
  UpdateUserClass, UpdateUserClassResults, UpdateUserClassErrors,
  GetPageId, GetPageIdResults, GetPageIdError,
  GetUserSubmittedSupportTickets, GetUserSubmittedSupportTicketsResults, GetUserSubmittedSupportTicketsError,
  UpdateUserMHISettings, UpdateUserMHISettingsResults,
  GetUsersPublicationSelections, GetUsersPublicationSelectionsResults, GetUsersPublicationSelectionsError,
  GetUserAlternateLoginNameList, GetUserAlternateLoginNameListResults, GetUserAlternateLoginNameListError,
  UpdateUsersPublicationSelections, UpdateUsersPublicationSelectionsResults, UpdateUsersPublicationSelectionsError,
  GetUserNotificationList, GetUserNotificationListError, GetUserNotificationListResults,
  UpdateUserNotificationList, UpdateUserNotificationListResults, UpdateUserNotificationListError,
  GetVendorListResults, GetVendorList, GetVendorListError,
  AddNewUserAlternateLogin, AddNewUserAlternateLoginResults, AddNewUseralternateLoginError,
  UpdateSelectedUserNotificationList, UpdateSelectedUserNotificationListResults, UpdateSelectedUserNotificationListError,
  GetUserNotificationHistoryList, GetUserNotificationHistoryListResults, GetUserNotificationHistoryListError,
  UpdateUserApplicationAndAuthorize, UpdateUserApplicationAndAuthorizeResults,
  GetNDCAPharmacy, GetNDCAPharmacyResults, GetNDCAPharmacyError,
  GetDisclaimer, GetDisclaimerResult, 
  GetDisclaimerError, AcknowledgeDisclaimer, AcknowledgeDisclaimerResult, AcknowledgeDisclaimerResultError,
  GetUserLogout, GetUserLogoutResult, GetUserLogoutError,
  Update340BPopup, Update340BPopupResults, Update340BPopupError, GetCorporateParentValidationStatus, GetCorporateParentValidationStatusResults, GetCorporateParentValidationStatusError,
  GetCSGIDList, GetCSGIDListError, GetCSGIDListResults,
    GetLookerUrl, GetLookerUrlResults, GetLookerUrlError, GetSelectedDashboard,
    RegenerateUserToken, RegenerateUserTokenResults, RegenerateUserTokenError, RegenerateUserTokenOnAppChange, RegenerateUserTokenOnAppChangeResults, RegenerateUserTokenOnAppChangeError, GetUAMUserApplicationPages, GetUAMUserApplicationPagesResults, GetUAMUserApplicationPagesError, GetUAMUserPharmacySession, GetUAMUserPharmacySessionResults, GetUAMUserPharmacySessionError, CloseClientSelection, ShowAccessDeniedPage
} from './user.actions';
import { GetClients } from './uam/uam.actions';
import { GetApplications, GetBackupPage } from './application-list.actions';

@Injectable()
export class UserStoreEffects {

  constructor(private userService: UserService, private actions$: Actions, private store: Store<{}>) {
  }

  InitUserEffect$ = createEffect(() => this.actions$.pipe(
    ofType( UserActionTypes.INIT_USER ),
    switchMap(action =>
      this.userService.initUser()
        .pipe(
          map(
            data => {
              return InitUserResults( { uiUser: data } );
            }
          ),
          catchError(error => {
            return observableOf( ErrShow({ message: error} ) )
          })
        )
    )
  ));

  InitUserResultEffect$ = createEffect(() => this.actions$.pipe(
    ofType( UserActionTypes.INIT_USER_RES ),
    switchMap(action => {
        return observableOf( GetClients() )
      }
    )
  ));

  GetUserSettingsEffect$ = createEffect(() => this.actions$.pipe(
    ofType( UserActionTypes.GET_SETTINGS ),
    switchMap(action =>
      this.userService.getUserSettings()
        .pipe(
          map(
            data => GetUserSettingsResults( { settings: data } )
            ),
          catchError(
            error => observableOf( GetUserSettingsError({ message: error} ) )
          )
        )
    )
  ));

  SaveUserSettingsEffect$ = createEffect(() => this.actions$.pipe(
    ofType( UpdateUserSettings ),
    switchMap(action =>
      this.userService.updateUserSettings( action.saveData )
        .pipe(
          map(
            data => UpdateUserSettingsResults({ saveData: data })
          ),
          catchError(
            error => observableOf( UpdateUserSettingsError({ message: error}))
          )
        )
    )
  ));

  UpdateUser340BPopupEffect$ = createEffect(() => this.actions$.pipe(
    ofType(Update340BPopup),
    switchMap(action =>
      this.userService.updateUser340BPopup(action.displayDate)
        .pipe(
          map(
            data => Update340BPopupResults({ userSetting: data })
          ),
          catchError(
            error => observableOf(Update340BPopupError({ message: error }))
          )
        )
    )
  ));

  UpdateApplicationEffect$ = createEffect(() => this.actions$.pipe(
    ofType( UpdateUserApplication ),
    switchMap(action =>
      this.userService.updateUserApplication( action.appId )
        .pipe(
          map(
            data => UpdateUserApplicationResults( { appId: data } )
          ),
          catchError(
            error => observableOf( UpdateUserApplicationError({ message: error} ) )
          )
        )
    )
  ));

  UpdateApplicationAndAuthorizeEffect$ = createEffect(() => this.actions$.pipe(
    ofType( UpdateUserApplicationAndAuthorize ),
    switchMap(action =>
      this.userService.updateUserApplication( action.appId )
        .pipe(
          map(
            data => UpdateUserApplicationAndAuthorizeResults({ appId: data, pid: action.pid })
          ),
          catchError(
            error => observableOf( UpdateUserApplicationError({ message: error} ) )
          )
        )
    )
  ));

  /* Unlike updating the session, updating the application only requires that the application
  be authorized for the given pid, and that the api token be updated.
  */
  UpdateApplicationAndAuthorizeResultsEffect$ = createEffect(() => this.actions$.pipe(
    ofType(UserActionTypes.UPDATE_APPLICATION_AND_AUTHORIZE_RES),
    switchMap((action: any) => {
      return observableOf( RegenerateUserTokenOnAppChange({ pid: action.pid }) )
    })
  ));

  GetUserSessionEffect$ = createEffect(() => this.actions$.pipe(
    ofType( UserActionTypes.GET_SESSION ),
    switchMap(action =>
      this.userService.getUserSession()
        .pipe(
          map(
            data => GetUserSessionResults( { session: data } )
          ),
          catchError(
            error => observableOf( GetUserSessionError({ message: error} ) )
          )
        )
    )
  ));

  SaveUserSessionEffect$ = createEffect(() => this.actions$.pipe(
    ofType( UpdateUserSession ),
    switchMap(action =>
      this.userService.updateUserSession( action.sessionData )
        .pipe(
          map(
            data => UpdateUserSessionResults( { session: data } )
          ),
          catchError(
            error => observableOf( UpdateUserSessionError({ message: error} ) )
          )
        )
    )
  ));

  SaveRecalculateEffect$ = createEffect(() => this.actions$.pipe(
    ofType( UpdateRecalculate ),
    switchMap(action =>
      this.userService.updateRecalculate( action.sessionData )
        .pipe(
          map(
            data => UpdateRecalculateResults( { session: data } )
          ),
          catchError(
            error => observableOf( UpdateRecalculateError({ message: error} ) )
          )
        )
    )
  ));

  GetPharmacyListEffect$ = createEffect(() => this.actions$.pipe(
    ofType( GetPharmacies ),
    switchMap(action =>
      this.userService.getUAMPharmacyList(action.searchText, action.appChange)
        .pipe(
          map(
            data => GetPharmaciesResults({ pharmacies: data })            
          ),
          catchError(
            error => observableOf( GetPharmaciesError({ message: error} ) )
          )
        )
    )
  ));

  GetAllPharmacyEffect$ = createEffect(() => this.actions$.pipe(
    ofType(GetAllPharmacies),
    switchMap(action =>
      this.userService.getUAMAllPharmacyList()
        .pipe(
          map(
            data => GetAllPharmaciesResults({ pharmacies: data })
          ),
          catchError(
            error => observableOf(GetAllPharmaciesError({ message: error }))
          )
        )
    )
  ));

  GetUserEmailSettingsEffect$ = createEffect(() => this.actions$.pipe(
    ofType( GetUserEmailSettings ),
    switchMap(action =>
      this.userService.getUserEmailSettings()
        .pipe(
          map(
            data => GetUserEmailSettingsResults( { userEmailSettings: data } )
            ),
          catchError(
            error => observableOf( GetUserEmailSettingsError({ message: error} ) )
          )
        )
    )
  ));

  UpdateUserEmailSettingsEffect$ = createEffect(() => this.actions$.pipe(
    ofType( UpdateUserEmailSettings ),
    switchMap(action =>
      this.userService.updateUserEmailSettings( action.saveData )
        .pipe(
          map(
            data => UpdateUserEmailSettingsResults( { saveData: data } )
          ),
          catchError(
            error => observableOf( UpdateUserEmailSettingsError({ message: error} ) )
          )
        )
    )
  ));

  UpdateUserQuickAccessPageEffect$ = createEffect(() => this.actions$.pipe(
    ofType( UpdateUserQuickAccessPage ),
    switchMap(action =>
      this.userService.updateUserQuickAccessPage(action.saveData)
      .pipe(
        map(
          data => UpdateUserQuickAccessPageResults ({saveData: action.saveData})
        ),
        catchError(
          error => observableOf(UpdateUserQuickAccessPageError ({ message: error}))
        )
      ))
  ));

  UserTrackHistoryInsertEffect$ = createEffect(() => this.actions$.pipe(
    ofType (InsertUserTrackHistory),
    switchMap(action =>
      this.userService.userTrackHistory_Insert(action.saveData)
      .pipe(
        map(
          data => InsertUserTrackHistoryResults ({message: data})
        ),
        catchError(
          error => observableOf(InsertUserTrackHistoryErrors({ message: error})
          )
        )
      ))
  ));

  UpdateUserClassEffect$ = createEffect(() => this.actions$.pipe(
    ofType(UpdateUserClass),
    switchMap(action =>
      this.userService.updateUserClass(action.saveData)
      .pipe(
        map(
          data => UpdateUserClassResults( { userClassInfo: data })
        ),
        catchError (
          error => observableOf(UpdateUserClassErrors ( {response: error }))
        )
      ))
    )
  );

  GetPageIdEffect$ = createEffect(() => this.actions$.pipe(
    ofType(GetPageId),
    switchMap(action =>
      this.userService.getPageId(action.app)
        .pipe(
          map(
            data => GetPageIdResults({ pageInfo: data })
          ),
          catchError(
            error => observableOf(GetPageIdError({ message: error }))
          )
        )
    )
  ));

  GetUserSubmittedSupportTicketsEffect$ = createEffect(() => this.actions$.pipe(
    ofType( GetUserSubmittedSupportTickets ),
    switchMap(action =>
      this.userService.getSubmittedSupportTickets( action.options )
        .pipe(
          map(
            data => GetUserSubmittedSupportTicketsResults({ userSubmittedTickets: data })
          ),
          catchError(
            error => observableOf(GetUserSubmittedSupportTicketsError({ message: error }))
          )
        )
    )
  ));


  UpdateUserMHISettingsEffect$ = createEffect(() => this.actions$.pipe(
    ofType (UpdateUserMHISettings),
    switchMap(action =>
      this.userService.updateUserMHISettings(action.saveUserMHISettings)
        .pipe(
          map(
            data => UpdateUserMHISettingsResults({ userMHISettings: data })
          ),
          catchError(
            error => observableOf(UpdateUserClassErrors({ response: error }))
          )
        ))
  )
  );

  GetUserPublicationSelectionsEffect$ = createEffect(() => this.actions$.pipe(
    ofType( GetUsersPublicationSelections ),
    switchMap(action =>
      this.userService.getUserPublicationSelection()
        .pipe(
          map(
            data => GetUsersPublicationSelectionsResults( { usersPublicationSelection: data } )
          ),
          catchError(
            error => observableOf( GetUsersPublicationSelectionsError({ message: error} ) )
          )
        )
    )
  ));

  UpdateUserPublicationSelectionsEffect$ = createEffect(() => this.actions$.pipe(
    ofType (UpdateUsersPublicationSelections),
    switchMap(action =>
      this.userService.updateUserPublicationSelection(action.saveUsersPublicationSelections)
      .pipe(
        map(
          data => UpdateUsersPublicationSelectionsResults( { response: data })
        ),
        catchError (
          error => observableOf(UpdateUsersPublicationSelectionsError ( {response: error }))
        )
      ))
    )
  );

  GetUserAlternateLoginNameListEffect$ = createEffect(() => this.actions$.pipe(
    ofType( GetUserAlternateLoginNameList ),
    switchMap(action =>
      this.userService.getUserAlternateLoginName( action.options )
        .pipe(
          map(
            data => GetUserAlternateLoginNameListResults({ userAlternateLoginNameList: data })
          ),
          catchError(
            error => observableOf( GetUserAlternateLoginNameListError({ message: error }))
            )
          )
      ))
  );

  getNotificationListEffect$ = createEffect(() => this.actions$.pipe(
    ofType(GetUserNotificationList),
    switchMap(action =>
      this.userService.getNotificationList()
        .pipe(
          map(
            data => GetUserNotificationListResults({ notificationList: data })
          ),
          catchError(
            error => observableOf(GetUserNotificationListError({ message: error }))
          )
        )
    )
  ));

  UpdateUserNotificationListEffect$ = createEffect(() => this.actions$.pipe(
    ofType(UpdateUserNotificationList),
    switchMap(action =>
      this.userService.updateNotificationList()
      .pipe(
        map(
          data => UpdateUserNotificationListResults({ saveNotificationList: data })
        ),
        catchError(
          error => observableOf(UpdateUserNotificationListError({ message: error }))
        )
      ))
    )
  );

  UpdateSelectedUserNotificationListEffect$ = createEffect(() => this.actions$.pipe(
    ofType(UpdateSelectedUserNotificationList),
    switchMap(action =>
      this.userService.updateSelectedNotificationList(action.saveNotificationList.updatedNotificationList,
       action.saveNotificationList.updateNoteSentID)
      .pipe(
        map(
          data => UpdateSelectedUserNotificationListResults({ saveNotificationList: data })
        ),
        catchError (
          error => observableOf(UpdateSelectedUserNotificationListError({ message: error }))
        )
      ))
    )
  );

  GetVendorListEffect$ = createEffect(() => this.actions$.pipe(
    ofType(GetVendorList),
    switchMap(action =>
      this.userService.getVendorList()
      .pipe(
        map(
          data => GetVendorListResults({ vendorList: data })
        ),
        catchError(
          error => observableOf(GetVendorListError({ message: error }))
        )
      ))
  ));

  AddNewUserAlternateLoginEffect$ = createEffect(() => this.actions$.pipe(
    ofType(AddNewUserAlternateLogin),
    switchMap(action =>
      this.userService.AddNewUserAlternateLogin(action.newUserAlternateLogin)
      .pipe(
        map(
          apiServerResponse => AddNewUserAlternateLoginResults({ response: apiServerResponse })
        ),
        catchError(
          apiServerResponse => observableOf(AddNewUseralternateLoginError({ response: apiServerResponse }))
        )
      ))
    )
  );

  GetNotificationHistoryList$ = createEffect(() => this.actions$.pipe(
    ofType(GetUserNotificationHistoryList),
    switchMap(action =>
      this.userService.getNotificationHistoryList()
        .pipe(
          map(
            data => GetUserNotificationHistoryListResults({ notificationhistoryList: data })
          ),
          catchError(
            error => observableOf( GetUserNotificationHistoryListError({ message: error }))
          )
        )
    )
  ));

  GetCSGIDList$ = createEffect(() => this.actions$.pipe(
    ofType(GetCSGIDList),
    switchMap(action =>
      this.userService.getCSGID()
        .pipe(
          map(
            data => GetCSGIDListResults({ csgIdList: data })
          ),
          catchError(
            error => observableOf(GetCSGIDListError({ message: error }))
          )
        )
    )
  ));

  GetLookerUrl$ = createEffect(() => this.actions$.pipe(
    ofType(GetLookerUrl),
    switchMap(action =>
      this.userService.getLookerUrl(action.csgId, action.dashboardType)
        .pipe(
          map(
            data => GetLookerUrlResults({ lookerUrl: data })
          ),
          catchError(
            error => observableOf(GetLookerUrlError({ message: error }))
          )
        )
    )
  ));

  GetNDCAPharmacyEffect$ = createEffect(() => this.actions$.pipe(
    ofType (GetNDCAPharmacy),
    switchMap(action =>
      this.userService.getNDCAPharmacy()
        .pipe(
          map(
            data => GetNDCAPharmacyResults({pharmacy: data})
          ),
          catchError(
            error => observableOf( GetNDCAPharmacyError({message: error} ) )
          )
        )
    )
  ));
  /*
  GetMck340BIAccessEffect$ = createEffect(() => this.actions$.pipe(
    ofType(GetMck340BIAccess),
    switchMap(action =>
      this.userService.getMck340BIAccess()
        .pipe(
          map(
            data => GetMck340BIAccessResults({ access: data })
          ),
          catchError(
            error => observableOf(GetMck340BIAccessError({ message: error }))
          )
        )
    )
  ));
  */

  GetDisclaimer$ = createEffect(() => this.actions$.pipe(
    ofType(GetDisclaimer),
    switchMap(action =>
      this.userService.getDisclaimer(action.pageId)
        .pipe(
          map(
            data => GetDisclaimerResult({ disclaimer: data })
          ),
          catchError(
            error => observableOf(GetDisclaimerError({ message: error }))
          )
        )
    )
  ));

  AcknowledgeDisclaimer$ = createEffect(() => this.actions$.pipe(
    ofType(AcknowledgeDisclaimer),
    switchMap(action =>
      this.userService.acknowledgeDisclaimer(action.disclaimerId)
        .pipe(
          map(
            data => AcknowledgeDisclaimerResult({ response: data })
          ),
          catchError(
            error => observableOf(AcknowledgeDisclaimerResultError({ message: error }))
          )
        )
    )
  ));

  GetUserLogout$ = createEffect(() => this.actions$.pipe(
    ofType(GetUserLogout),
    switchMap(action =>
      this.userService.getUserLogout()
        .pipe(
          map(
            data => GetUserLogoutResult({ response: data })
          ),
          catchError(
            error => observableOf(GetUserLogoutError({ message: error }))
          )
        )
    )
  ));

  getCorporateParentValidationStatus$ = createEffect(() => this.actions$.pipe(
    ofType(GetCorporateParentValidationStatus),
    switchMap(action =>
      this.userService.getCorporateParentValidationStatus()
        .pipe(
          map(
            data => {
              return GetCorporateParentValidationStatusResults({ healthSystemValidationResponse: data })
            }

          ),
          catchError(
            error => observableOf(GetCorporateParentValidationStatusError({ message: error }))
          )

        )
    )
  ));

  RegenerateUserToken$ = createEffect(() => this.actions$.pipe(
    ofType(RegenerateUserToken),
    switchMap(action =>
      this.userService.regenerateUserToken(action.request)
        .pipe(
          map(
            data => RegenerateUserTokenResults({ userAccessToken: data })
          ),
          catchError(
            error => observableOf(RegenerateUserTokenError({ message: error }))
          )
        )
    )
  ));

  /* Step 1 in app load: token generation triggers the application and page list to reload in the 
  event of login, session (pharmacy) update, or corpid/chainid change
  */
  RegenerateUserTokenResultsEffect$ = createEffect(() => this.actions$.pipe(
    ofType(UserActionTypes.REGENERATE_TOKEN_RES),
    switchMap(action => {
        return observableOf( GetUAMUserApplicationPages() )
      }
    )
  ));

  RegenerateUserTokenOnAppChangeEffect$ = createEffect(() => this.actions$.pipe(
    ofType(RegenerateUserTokenOnAppChange),
    switchMap(action =>
      this.userService.regenerateTokenOnAppChange(action.pid)
        .pipe(
          map(
            data => RegenerateUserTokenOnAppChangeResults({ userAccessToken: data })
          ),
          catchError(
            error => observableOf(RegenerateUserTokenOnAppChangeError({ message: error }))
          )
        )
    )
  ));

  GetUAMUserPharamcySessionEffect$ = createEffect(() => this.actions$.pipe(
    ofType(GetUAMUserPharmacySession),
    switchMap(action =>
      this.userService.getUserPharmacySession()
        .pipe(
          map(
            data => GetUAMUserPharmacySessionResults({ session : data })
          ),
          catchError(
            error => observableOf(GetUAMUserPharmacySessionError({ message: error }))
          )
        )
    )
  ));

  /* Step 3 in app load: Once the session data returns, we have enough information 
  to request the remainder of the app data
  */
  GetUAMUserPharmacySessionResultsEffect$ = createEffect(() => this.actions$.pipe(
    ofType(UserActionTypes.GET_UAM_USER_PHARMACY_SESSION_RES),
    switchMap(action => {
        return observableOf( GetUserEmailSettings(), GetNDCAPharmacy(), GetUsersPublicationSelections(), GetCorporateParentValidationStatus({}) )
      }
    )
  ));

  GetUAMUserApplicationPagesEffect$ = createEffect(() => this.actions$.pipe(
    ofType(GetUAMUserApplicationPages),
    switchMap(action =>
      this.userService.getUAMUserApplicationPages()
        .pipe(
          map(
            data => GetUAMUserApplicationPagesResults({ userApplicationInfo: data })
          ),
          catchError(
            error => observableOf(GetUAMUserApplicationPagesError({ message: error }))
          )
        )
    )
  ));

  /* step 2 in the app load: once pages and app list return, gather session-dependent data
  */
  GetUAMUserApplicationPagesResultsEffect$ = createEffect(() => this.actions$.pipe(
    ofType(UserActionTypes.GET_UAM_USER_APPLICATION_PAGES_RES),
    switchMap(action => {
        return observableOf( GetUserSettings(), GetUAMUserPharmacySession(), GetUserNotificationList(), GetAllPharmacies(), GetApplications() )
      }
    )
  ));

  /* If an error occurs, show access denied from there user can select another corp/chain
      There are two scenarios where this regenerate token error can occur
      1. If user has already logged in and has a cookie OR only has 1 corp/chain
      2. User clicks on one of the corps/chains in the client-selection screen 
  */
  RegenerateUserTokenErrorEffect$ = createEffect(() => this.actions$.pipe(
    ofType(UserActionTypes.REGENERATE_TOKEN_ERR),
    switchMap(action => {
        return observableOf( CloseClientSelection(), ShowAccessDeniedPage() )
      }
    )
  ));

}


