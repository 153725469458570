import * as msal from "@azure/msal-browser";
import { Configuration, Logger, LogLevel } from "@azure/msal-browser";
import { MsalGuardConfiguration, MsalInterceptorConfiguration } from '@azure/msal-angular';
import { environment } from "../environments/environment";
import { env } from 'process';
import { BrowserCacheLocation, InteractionType, IPublicClientApplication, PublicClientApplication } from '@azure/msal-browser';

// this checks if the app is running on IE
export const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;
const graphMeEndpoint = "https://graph.microsoft.com/v1.0/me";
const graphReadScope =  `https://${environment.b2c.TenantName}.onmicrosoft.com/api/read`;

function loggerCallback(logLevel, message, containsPii) {
  console.log(message);
}

/** =================== REGIONS ====================
 * 1) B2C policies and user flows
 * 2) Web API configuration parameters
 * 3) Authentication configuration parameters
 * 4) MSAL-Angular specific configuration parameters
 * =================================================
 */

// #region 1) B2C policies and user flows
/**
 * Enter here the user flows and custom policies for your B2C application,
 * To learn more about user flows, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/user-flow-overview
 * To learn more about custom policies, visit https://docs.microsoft.com/en-us/azure/active-directory-b2c/custom-policy-overview
 */
export const b2cPolicies = {
    names: {
      signUpSignIn: environment.b2c.SignInPolicy,
      resetPassword: environment.b2c.LoggedInPasswordResetPolicyId,
      resetMfa: environment.b2c.UserChangeMFAPolicyId,
      forgotpassword: environment.b2c.ResetPasswordPolicy,
      signup: environment.b2c.SignUpPolicy,
      connectSignIn: environment.b2c.ConnectSignInPolicy
    },
    authorities: {
      signUpSignIn: {
        authority: `https://${environment.b2c.TenantName}.b2clogin.com/${environment.b2c.TenantName}.onmicrosoft.com/${environment.b2c.SignInPolicy}`
      },
      resetPassword: {
        authority: `https://${environment.b2c.TenantName}.b2clogin.com/${environment.b2c.TenantName}.onmicrosoft.com/${environment.b2c.LoggedInPasswordResetPolicyId}`
      },
      resetMfa: {
        authority: `https://${environment.b2c.TenantName}.b2clogin.com/${environment.b2c.TenantName}.onmicrosoft.com/${environment.b2c.UserChangeMFAPolicyId}`
      },
      forgotPassword: {
        authority: `https://${environment.b2c.TenantName}.b2clogin.com/${environment.b2c.TenantName}.onmicrosoft.com/${environment.b2c.ResetPasswordPolicy}`
      },
      signup: {
        authority: `https://${environment.b2c.TenantName}.b2clogin.com/${environment.b2c.TenantName}.onmicrosoft.com/${environment.b2c.SignUpPolicy}`
      },
      connectSignIn: {
        authority: `https://${environment.b2c.TenantName}.b2clogin.com/${environment.b2c.TenantName}.onmicrosoft.com/${environment.b2c.ConnectSignInPolicy}`
      },
      oldSignUpSignIn: {
        authority: `https://${environment.b2c.TenantName}.b2clogin.com/${environment.b2c.TenantName}.onmicrosoft.com/${environment.b2c.OldSignInPolicy}`
      }
    }
};
// #endregion


// #region 2) Web API Configuration
/**
 * Enter here the coordinates of your Web API and scopes for access token request
 * The current application coordinates were pre-registered in a B2C tenant.
 */
export const apiConfig: {b2cScopes: string[]; webApi: string} = {
    b2cScopes: [graphReadScope],
    webApi: `https://${environment.b2c.TenantName}.azurewebsites.net/api`
};

// #endregion

//['https://graph.microsoft.com/v1.0/me', ['user.read']]

// #region 3) Authentication Configuration
/**
 * Config object to be passed to Msal on creation. For a full list of msal.js configuration parameters,
 * visit https://azuread.github.io/microsoft-authentication-library-for-js/docs/msal/modules/_configuration_.html
 */
// export const msalConfig: Configuration = {
//     auth: {
//         clientId: environment.b2c.ClientId,
//         authority: b2cPolicies.authorities.signUpSignIn.authority,
//         redirectUri: environment.b2c.RedirectUrl,
//         postLogoutRedirectUri: environment.b2c.LogoutUrl,
//         navigateToLoginRequestUrl: false,
//         validateAuthority: false,
//       },
//     framework:{
//       isAngular: true
//     },
//     cache: {
//         cacheLocation: 'localStorage',
//         storeAuthStateInCookie: isIE, // Set this to 'true' to save cache in cookies to address trusted zones limitations in IE
//     },
//     system: {
//       logger:  new Logger(
//           loggerCallback , {
//               level: LogLevel.Error,
//               piiLoggingEnabled: false,
//               correlationId: '123456789'
//           }
//       )
//   }
// };

export const msalConfig: Configuration = {
  auth: {
      clientId: environment.b2c.ClientId,
      authority: b2cPolicies.authorities.signUpSignIn.authority,
      knownAuthorities: [],
      cloudDiscoveryMetadata: "",
      redirectUri: environment.b2c.RedirectUrl,
      postLogoutRedirectUri: environment.b2c.LogoutUrl,
      navigateToLoginRequestUrl: false,
      clientCapabilities: ["CP1"]
      
  },
  cache: {
      cacheLocation: "localStorage",
      storeAuthStateInCookie: isIE
  },
  system: {
      loggerOptions: {
          loggerCallback: (level: LogLevel, message: string, containsPii: boolean): void => {
              if (containsPii) {
                  return;
              }
              switch (level) {
                  case LogLevel.Error:
                      console.error(message);
                      return;
                  case LogLevel.Info:
                      console.info(message);
                      return;
                  case LogLevel.Verbose:
                      console.debug(message);
                      return;
                  case LogLevel.Warning:
                      console.warn(message);
                      return;
              }
          },
          piiLoggingEnabled: false,
          
      },
      windowHashTimeout: 60000,
      iframeHashTimeout: 6000,
      loadFrameTimeout: 0,
      asyncPopups: false
  }
};

/**
 * Scopes you enter here will be consented once you authenticate. For a full list of available authentication parameters,
 * visit https://azuread.github.io/microsoft-authentication-library-for-js/docs/msal/modules/_authenticationparameters_.html
 */
export const loginRequest = {
    scopes: ["openid", "profile", graphReadScope],
    authority: b2cPolicies.authorities.signUpSignIn.authority
};

export const signUpRequest = {
  scopes: ["openid", "profile", graphReadScope],
  authority: b2cPolicies.authorities.signup.authority
}

export const resetPasswordRequest = {
  scopes: ["openid", "profile", graphReadScope],
  authority: b2cPolicies.authorities.resetPassword.authority
};

export const mfaRequest = {
  scopes: ["openid", "profile", graphReadScope],
  authority: b2cPolicies.authorities.resetMfa.authority
};

export const forgotPasswordRequest = {
  scopes: ["openid", "profile", graphReadScope],
  authority: b2cPolicies.authorities.forgotPassword.authority
};

// Scopes you enter will be used for the access token request for your web API
export const tokenRequest = {
  scopes: apiConfig.b2cScopes, // i.e. [https://fabrikamb2c.onmicrosoft.com/helloapi/demo.read]
  forceRefresh: false
};

// #endregion

// #region 4) MSAL-Angular Configuration
// here you can define the coordinates and required permissions for your protected resources
// export const protectedResourceMap = {
//   "", [apiConfig.webApi, apiConfig.b2cScopes]
// };

//     // i.e. [https://fabrikamb2chello.azurewebsites.net/hello, ['https://fabrikamb2c.onmicrosoft.com/helloapi/demo.read']]
// ];

/**
 * MSAL-Angular specific authentication parameters. For a full list of available options,
 * visit https://github.com/AzureAD/microsoft-authentication-library-for-js/tree/dev/lib/msal-angular#config-options-for-msal-initialization
 */

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication(msalConfig);
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set(graphMeEndpoint, ['user.read']);
  protectedResourceMap.set(graphReadScope, ['user.read']);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return { interactionType: InteractionType.Redirect };
}
