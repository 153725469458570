import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { AccessDeniedComponent } from './shared/access-denied-page/access-denied.component';
import { ClientIDSelectionComponent } from './shared/clientId-selection/clientID-selection.component';
import { ViewProfileComponent } from './shared/top-nav/navbar-buttons/user-profile-button/view-profile/view-profile.component';

const routes: Routes = [
  {
    path: '340bArchitect',
    loadChildren: () => import('./modules/340b-architect/340b-architect.module').then(m => m.Module340bArchitect)
    ,canActivate: [MsalGuard]
  },
  {
    path: 'MacroManager',
    loadChildren: () => import('./modules/macro-manager/macro-manager.module').then(m => m.ModuleMacroManager)
    ,canActivate: [MsalGuard]
  },
  {
    path: 'NDCArchitect',
    loadChildren: () => import('./modules/ndc-architect/ndc-architect.module').then(m => m.ModuleNDCArchitect)
    ,canActivate: [MsalGuard]
  },
  {
    path: '340bAnalytics',
    loadChildren: () => import('./modules/340b-analytics/340b-analytics.module').then(m => m.Module340bAnalytics)
    ,canActivate: [MsalGuard]
  },{
    path: '340bGateway',
    loadChildren: () => import('./modules/340b-gateway/340b-gateway.module').then(m => m.Module340bGateway)
    ,canActivate: [MsalGuard]
  },
  {
    path: 'UserAccessManagement',
    loadChildren: () => import('./modules/user-access-management/user-access-management.module').then(m => m.ModuleUserAccessManagement)
    ,canActivate: [MsalGuard]
  },
  {
    path: 'viewProfile',
    component: ViewProfileComponent
    ,canActivate: [MsalGuard]
  },
  {
    // Needed for b2c routing
    path: 'state',
    redirectTo: '',
    pathMatch: 'full'
  },
  {
    // Needed for b2c routing
    path: 'error',
    redirectTo: '',
    pathMatch: 'full'
  },
  {
    path: '',
    redirectTo: '',
    pathMatch: 'full'
  },
];

const isIframe = window !== window.parent && !window.opener;

@NgModule({
  imports: [RouterModule.forRoot(routes,
    {
      useHash: true
    , onSameUrlNavigation: 'reload'
    // Don't perform initial navigation in iframes
    , initialNavigation: !isIframe ? 'enabled' : 'disabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
