import { AddNewUserLoginNameDialogComponent } from './../add-new-user-login-name-dialog/add-new-user-login-name-dialog.component';
import { Component, OnInit, ViewChild } from '@angular/core';
import { UserAlternateLoginName } from 'src/app/models/user';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Store } from '@ngrx/store';
import { UserState } from 'src/app/store/user.reducer';
import { TableGetOptions } from 'src/app/models/received-detail';
import { GetUserAlternateLoginNameList } from 'src/app/store/user.actions';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'user-alternate-login-name-table',
  templateUrl: './user-alternate-login-name-table.component.html',
  styleUrls: ['./user-alternate-login-name-table.component.scss']
})
export class UserAlternateLoginNameTableComponent implements OnInit {
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  public userAlternateLoginNameData: UserAlternateLoginName[];
  userAlternateLoginNameDataSource;
  tableHeaders: string[] =['vendorName', 'loginName', 'dateAdded'];


  constructor(public store: Store<{ userState: UserState }>,
    private dialog: MatDialog,){
    const options: TableGetOptions = new TableGetOptions();
    this.store.dispatch( GetUserAlternateLoginNameList( { options } ));
  }

  ngOnInit(): void {
    this.store.select(state => state.userState.userAlternateLoginNameList).subscribe( (data) => {
      if (data != null) {
        this.userAlternateLoginNameDataSource = new MatTableDataSource(data);
        this.userAlternateLoginNameDataSource.paginator = this.paginator;
      }
    });
  }

  AddNewLoginNammeClicked() {
    const dialogRef = this.dialog.open(AddNewUserLoginNameDialogComponent, {
      disableClose: true,
      panelClass: ['mh-dialog','add-login-name-dialog']
    ,})

    dialogRef.afterClosed().subscribe(result => {
      const options: TableGetOptions = new TableGetOptions();
      this.store.dispatch( GetUserAlternateLoginNameList( { options} ) );
      this.store.select(state => state.userState.userAlternateLoginNameList).subscribe(
        (data) => {
          this.userAlternateLoginNameDataSource = new MatTableDataSource(data);
          this.userAlternateLoginNameDataSource.paginator = this.paginator;
      });
    })
  }
}
