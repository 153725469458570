<div>
  <form>
    <div class="user-full-name">
      <mat-label>{{ userSettingsInfo.fullName }}</mat-label>
    </div>
    <mat-tab-group class="setting-panel" animationDuration="0ms">
      <mat-tab class="settings-tab" label="My Account">
        <form #submitUserSettingsForm="ngForm">
          <mat-toolbar>
            <mat-toolbar-row class="tab-label-test">
              <span>Macro Helix Settings</span>
            </mat-toolbar-row>
            <mat-toolbar-row class='actionBar' style="font-size: 14pt;">
              <div style="border-right: 1px solid white;margin-top: 5px; margin-bottom: 5px;
              line-height: 22px; padding-right: 8px; ">
                <button class="save-profile-btn" mat-button
                        (click)="SaveProfileClick()"
                        [disabled]="submitUserSettingsForm.form.invalid">
                  Save Profile
                </button>
              </div>
            </mat-toolbar-row>
          </mat-toolbar>
          <div>
            <div class="security-settings-column">

              <mat-toolbar>
                <mat-toolbar-row class="section-header" style="
                border-right-style: solid;
                border-color: #ffffff;">
                  <span>Security Settings</span>
                </mat-toolbar-row>
              </mat-toolbar>

              <div *ngIf="this.isEmployee">
                <mat-hint class="reset-password-hint">
                  Security settings are disabled for employees
                </mat-hint>
              </div>

              <div *ngIf="!this.isEmployee && !this.IsConnectUser()" class="reset-password-section">
                <a (click)="this.ResetB2cOptions(1)" class="reset-password">Reset Your Password</a><br />
                <mat-hint class="reset-password-hint">
                  Reset your password
                </mat-hint>
              </div>

              <div *ngIf="!this.isEmployee && !this.IsConnectUser()" class="reset-password-section">
                <a (click)="this.ResetB2cOptions(2)" class="reset-password">Reset Your 2FA Option</a><br />
                <mat-hint class="reset-password-hint">
                  Two Factor Authentication Option
                </mat-hint>
              </div>

              <div *ngIf="this.IsConnectUser()" class="reset-password-section">
                <p style="font-size: 15px; padding-left: 15px;">Connect User must sign in with email to change 2FA and Password settings.</p>
              </div>

              <div style="margin-top:30px">
                <mat-toolbar>
                  <mat-toolbar-row class="section-header" style="
                  border-right-style: solid;
                  border-color: #ffffff;">
                    <span>Activity Log</span>
                  </mat-toolbar-row>
                </mat-toolbar>
                <div class="data-field">
                  <mat-label class="last-login-label">Last Login</mat-label>
                  <p style="padding-top: 10px;">
                    {{ userSettingsInfo.prevLogin | date: "M/d/yyyy h:mm a" }}
                  </p>
                </div>
                <div class="data-field">
                  <mat-label class="last-profile-update-label">Last Profile Update</mat-label>
                  <p style="padding-top: 10px;">
                    {{
                      userSettingsInfo.profileUpdated | date: "M/d/yyyy h:mm a"
                    }}
                  </p>
                </div>
              </div>

            </div>

            <div class="personal-info-column">
              <mat-toolbar color="primary" style="min-height: 32px;">
                <mat-toolbar-row class="section-header" style="
                border-right-style: solid;
                border-color: #ffffff;">
                  <span>Personal Information</span>
                </mat-toolbar-row>
              </mat-toolbar>
              <div class="data-field">
                <mat-form-field appearance="" class="first-name" floatLabel="always">
                  <mat-label class="form-label">
                    First Name
                  </mat-label>
                  <input type="text" matInput placeholder="Your First Name" class="form-control" name="firstName"
                         #firstName="ngModel" [(ngModel)]="userSettingsInfo.firstName" required size="40" [(value)]="userSettingsInfo.firstName" />
                  <mat-error *ngIf="firstName.hasError('required')">First Name is <strong>required</strong></mat-error>
                </mat-form-field>
              </div>
              <div class="data-field">
                <mat-form-field appearance="" class="last-name" floatLabel="always">
                  <mat-label class="form-label">
                    Last Name
                  </mat-label>
                  <input type="text" matInput placeholder="Your Last Name" class="form-control" name="lastName"
                         #lastName="ngModel" [(ngModel)]="userSettingsInfo.lastName" required size="100" [(value)]="userSettingsInfo.lastName" />
                  <mat-error *ngIf="lastName.hasError('required')">Last Name is <strong>required</strong></mat-error>
                </mat-form-field>
              </div>
              <div class="data-field">
                <mat-form-field appearance="" class="cell-phone" floatLabel="always">
                  <mat-label class="form-label">
                    Cell Phone
                  </mat-label>
                  <input type="tel" matInput class="form-control" name="cellPhoneNumber" #cellPhoneNumber="ngModel"
                         [(ngModel)]="userSettingsInfo.cellPhoneNumber" placeholder="(123) 456-7890"
                         [textMask]="{ mask: phoneMask }"
                         pattern="\(\d{3}\) \d{3}-\d{4}"
                         [(value)]="userSettingsInfo.cellPhoneNumber" />
                  <mat-error *ngIf="cellPhoneNumber.hasError('pattern')">
                    Cellphone is invalid
                  </mat-error>
                </mat-form-field>
              </div>
              <div>
                <mat-toolbar>
                  <mat-toolbar-row class="section-header" style="
                  border-right-style: solid;
                  border-color: #ffffff;">
                    <span> Other Settings</span>
                  </mat-toolbar-row>
                </mat-toolbar>
                <div class="data-field">
                  <mat-form-field class="default-packages" floatLabel="always">
                    <mat-label class="form-label">Default Package Size: </mat-label>
                    <mat-select class="form-control" name="defaultPackagesSetting"
                                [(ngModel)]="userSettingsInfo.defaultPkgs" [(value)]="userSettingsInfo.defaultPkgs"
                                #defaultPackagesSetting="ngModel">
                      <mat-option *ngFor="let packageSettingOption of packageSettingOptions"
                                  [value]="packageSettingOption.value">
                        {{ packageSettingOption.viewValue }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <br />
                  <mat-hint style="margin-top: -10px;" class="default-packages-hint">
                    Used in Order Builder.
                  </mat-hint>
                  <br />
                  <mat-hint class="default-packages-hint">
                    Max orders all 340B accumulations.
                  </mat-hint>
                </div>
              </div>
            </div>
            <div class="work-info-column">
              <mat-toolbar>
                <mat-toolbar-row class="section-header">
                  <span>Work Information</span>
                </mat-toolbar-row>
              </mat-toolbar>
              <div class="data-field">
                <mat-form-field appearance="" class="job-title" floatLabel="always">
                  <mat-label class="form-label">
                    Job Title
                  </mat-label>
                  <input type="text" matInput placeholder="Your Job Title" class="form-control" name="jobTitle"
                         #jobTitle="ngModel" [(ngModel)]="userSettingsInfo.jobTitle" size="100" [(value)]="userSettingsInfo.jobTitle" />
                  <mat-error *ngIf="jobTitle.hasError('required')">Job Title is <strong>required</strong></mat-error>
                </mat-form-field>
              </div>
              <div class="data-field">
                <mat-form-field appearance="" class="emailAddress" floatLabel="always" [style.width.px]=emailLengthCalculate()>
                  <mat-label class="form-label">
                    Email
                  </mat-label>
                  <input type="text" matInput
                         placeholder="Your Email"
                         class="form-control" name="email"
                         [(ngModel)]="userSettingsInfo.email"
                         #email="ngModel"
                         required
                         maxlength="50"
                         [pattern]="emailPattern"
                         [(value)]="userSettingsInfo.email" [readonly]="this.IsConnectUser()" />
                  <mat-error *ngIf="email.hasError('pattern') && !email.hasError('required')">
                    Please enter a valid email address
                  </mat-error>
                  <mat-error *ngIf="email.hasError('required')">
                    Email is <strong>required</strong>
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="data-field">
                <mat-form-field appearance="" class="work-phone" floatLabel="always">
                  <mat-label class="form-label">
                    Work Phone
                  </mat-label>
                  <input type="tel" matInput class="form-control" name="workPhoneNumber" #workPhoneNumber="ngModel"
                         [(ngModel)]="userSettingsInfo.phoneNumber" placeholder="(123) 456-7890"
                         [textMask]="{ mask: phoneMask }"
                         pattern="\(\d{3}\) \d{3}-\d{4}"
                         [(value)]="userSettingsInfo.phoneNumber" />
                  <mat-error *ngIf="workPhoneNumber.hasError('pattern')">
                    Workphone is invalid
                  </mat-error>
                </mat-form-field>
                <!-- <div>saveUserSettingsInfo.workPhone: {{saveUserSettingsInfo.phoneNumber}}</div>
                <div>userSettingsInfo.workPhone: {{userSettingsInfo.phoneNumber}}</div> -->
              </div>
              <div class="data-field">
                <mat-form-field appearance="" class="fax-number" floatLabel="always">
                  <mat-label class="form-label">
                    FAX Number
                  </mat-label>
                  <input type="tel" matInput class="form-control" name="faxNumber" #faxNumber="ngModel"
                         [(ngModel)]="userSettingsInfo.faxNumber" placeholder="(123) 456-7890"
                         [textMask]="{ mask: phoneMask }"
                         pattern="\(\d{3}\) \d{3}-\d{4}" [(value)]="userSettingsInfo.faxNumber" />
                  <mat-error *ngIf="faxNumber.hasError('pattern')">
                    Fax number is invalid
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
          </div>
          <div class="vendor-login-name-section">
            <user-alternate-login-name-table></user-alternate-login-name-table>
          </div>
        </form>
      </mat-tab>
      <mat-tab label="Pharmacy List">
        <mat-toolbar>
          <mat-toolbar-row class="tab-label-test">
            <span>My Pharmacies</span>
          </mat-toolbar-row>
        </mat-toolbar>
        <user-pharmacy-table></user-pharmacy-table>
      </mat-tab>
      <mat-tab label="Notifications">
        <user-notification-settings-tab></user-notification-settings-tab>
      </mat-tab>
      <mat-tab label="Support Tickets">
        <user-submitted-tickets-table></user-submitted-tickets-table>
      </mat-tab>
      <mat-tab label="Macro Helix" *ngIf="userSettingsInfo.isMHIUser === true">
        <form #submitUserMHISettingsForm="ngForm">
          <mat-toolbar>
            <mat-toolbar-row class="tab-label-test">
              <span>Macro Helix Settings</span>
            </mat-toolbar-row>
            <mat-toolbar-row class='actionBar' style="font-size: 14pt;">
              <div style="border-right: 1px solid white;margin-top: 5px; margin-bottom: 5px;
              line-height: 22px; padding-right: 8px; ">
                <button *ngIf="showSaveButtonMacroHelixTab" class="save-profile-btn" mat-button (click)="SaveMacroHelixTabClick()" [disabled]="submitUserMHISettingsForm.form.invalid">
                  Save Profile
                </button>
              </div>
            </mat-toolbar-row>
          </mat-toolbar>
          <div class="info-display">
            <div class="user-mhi-info">
              <div class="data-field" style="margin-top: 10px;">
                <mat-form-field class="macro-helix-user" floatLabel="always">
                  <mat-label class="form-label">Macro Helix User</mat-label>
                  <mat-select class="form-control" name="isMacroHelixUser" [(ngModel)]="userSettingsInfo.isMHIUser"
                              [(value)]="userSettingsInfo.isMHIUser" #isMacroHelixUser="ngModel" placeholder="Select an option" disabled>
                    <mat-option *ngFor="let option of isMHUserOption" [value]="option.value">
                      {{ option.viewValue }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div *ngIf="userSettingsInfo.isMHIUser">
                <div class="data-field">
                  <mat-form-field appearance="" class="eid" floatLabel="always">
                    <mat-label class="form-label">
                      EID
                    </mat-label>
                    <input type="text" matInput class="form-control" name="eid" #eid="ngModel"
                           [(ngModel)]="userSettingsInfo.eid" placeholder="Your Employee ID" required disabled />
                    <mat-error *ngIf="eid.hasError('required')">EID is required</mat-error>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>
        </form>
      </mat-tab>
    </mat-tab-group>
  </form>
</div>
