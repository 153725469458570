import { createAction, props } from '@ngrx/store';
import { ClientList, PortRequestList } from '../models/hl7';

export enum HL7ActionTypes {
  GET_HL7CLIENT_LIST = '[Rest Call] [PresList] GetHL7ClientList',
  GET_HL7CLIENT_LIST_RES = '[Rest Result] [PresList] GetHL7ClientList',
  GET_HL7CLIENT_LIST_ERR = '[Rest Error] [PresList] GetHL7ClientList',

  GET_HL7PORTREQUEST_LIST = '[Rest Call] [PresList] GetHL7PortRequestList',
  GET_HL7PORTREQUEST_LIST_RES = '[Rest Result] [PresList] GetHL7PortRequestList',
  GET_HL7PORTREQUEST_LIST_ERR = '[Rest Error] [PresList] GetHL7PortRequestList',

}

export const GetHL7ClientList = createAction(HL7ActionTypes.GET_HL7CLIENT_LIST);
export const GetHL7ClientListResults = createAction(HL7ActionTypes.GET_HL7CLIENT_LIST_RES, props<{ HL7ClientList: ClientList; }>());
export const GetHL7ClientListError = createAction(HL7ActionTypes.GET_HL7CLIENT_LIST_ERR, props<{ message: string }>());

export const GetHL7PortRequestList = createAction(HL7ActionTypes.GET_HL7PORTREQUEST_LIST);
export const GetHL7PortRequestListResults = createAction(HL7ActionTypes.GET_HL7PORTREQUEST_LIST_RES, props<{ HL7PortRequestList: PortRequestList; }>());
export const GetHL7PortRequestListError = createAction(HL7ActionTypes.GET_HL7PORTREQUEST_LIST_ERR, props<{ message: string }>());


