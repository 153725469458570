import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { PbiEmbedConfig, PBIDashboard, PBIReport, PBITile, ReportPage } from '../models/analytics';
import { BaseMhService } from './basemh.service';
import { Store } from '@ngrx/store';
import { UserState } from '../store/user.reducer';
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})


export class PbiEmbeddingService extends BaseMhService {

  constructor(protected http: HttpClient, protected store: Store<{userState:UserState}>) {
    super( http, store);
  }

  getListOfReports(): Observable<PBIReport[]> {
    return this.http
      .get(environment.apiBaseUrl+'pbiembed/v1/reports', {headers: this.getHeaders()})
      .pipe(
        map((response: PBIReport[]) => response),
        catchError(this.errorHandler)
      );
  }

  getListOfReportPages(report: string, pid: number): Observable<ReportPage[]> {
    return this.http
      .get(environment.apiBaseUrl+'pbiembed/v1/reports/' + report + "/pid/" + pid + "/pages", {headers: this.getHeaders()})
      .pipe(
        map((response: ReportPage[]) => response),
        catchError(this.errorHandler)
      );
  }

  getListOfDashboards(): Observable<PBIDashboard[]> {
    return this.http
      .get(environment.apiBaseUrl+'pbiembed/v1/dashboards', {headers: this.getHeaders()})
      .pipe(
        map((response: PBIDashboard[]) => response),
        catchError(this.errorHandler)
      );
  }

  getListOfTiles(): Observable<PBITile[]> {
    return this.http
      .get(environment.apiBaseUrl+'pbiembed/v1/tiles', {headers: this.getHeaders()})
      .pipe(
        map((response: PBITile[]) => response),
        catchError(this.errorHandler)
      );
  }

  getReportsEmbed(report: string, pid: number): Observable<PbiEmbedConfig[]> {
    return this.http
      .get(environment.apiBaseUrl+'pbiembed/v1/reports/'+report+"/embed/pid/"+pid, {headers: this.getHeaders()})
      .pipe(
        map((response: PbiEmbedConfig[]) => response),
        catchError(this.errorHandler)
      );
  }

  getDashboardEmbed(dashboard: string): Observable<PbiEmbedConfig> {
    return this.http
      .get(environment.apiBaseUrl+'pbiembed/v1/dashboards/'+dashboard+"/embed", {headers: this.getHeaders()})
      .pipe(
        map((response: PbiEmbedConfig) => response),
        catchError(this.errorHandler)
      );
  }

  getTileEmbed(dashboard: string, tile: string): Observable<PbiEmbedConfig> {
    return this.http
      .get(environment.apiBaseUrl+'pbiembed/v1/dashboards/'+dashboard+"/tiles/"+tile+"/embed", {headers: this.getHeaders()})
      .pipe(
        map((response: PbiEmbedConfig) => response),
        catchError(this.errorHandler)
      );
  }


}
