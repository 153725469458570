import { createAction, props } from '@ngrx/store';
import { ReferralOutputDto, ReferralColumnDTO, ReferralNotesDTO, ReferralElementsDTO, ReferralAttachments } from './automated-referral.reducer';
import { ReferralSettings, SaveReferralSettings } from '../models/automated-referrals/referral-setting';
import { SubmitResponse } from '../models/submit-response';

export enum AutoRefActionTypes {
  GET_REFERRAL_LIST = '[Rest Call] [AutoRef] GetReferralList',
  GET_REFERRAL_LIST_RES = '[Rest Result] [AutoRef] GetReferralList',
  GET_REFERRAL_LIST_ERR = '[Rest Error] [AutoRef] GetReferralList',

  GET_REFFERAL_SETTINGS = '[Silent Rest Call] [AutoRef] GetReferralSettings',
  GET_REFFERAL_SETTINGS_RES = '[Silent Rest Result] [AutoRef] GetReferralSettingsResults',
  GET_REFFERAL_SETTINGS_ERR = '[Silent Rest Error] [AutoRef] GetReferralSettingsError',

  UPDATE_REFFERAL_SETTINGS = '[Rest Call] [AutoRef] UpdateReferralSettings',
  UPDATE_REFFERAL_SETTINGS_RES = '[Rest Result] [AutoRef] UpdateReferralSettingsResults',
  UPDATE_REFFERAL_SETTINGS_ERR = '[Rest Error] [AutoRef] UpdateReferralSettingsError',

  SET_REFERRALS_COLUMNS = '[Rest Call] [AutoRef] SetReferralsColumns',
  SET_REFERRALS_COLUMNS_RES = '[Rest Result] [AutoRef] SetReferralsColumnsResults',
  SET_REFERRALS_COLUMNS_ERR = '[Rest Error] [AutoRef] SetReferralsColumnsError',

  GET_REFERRALS_NOTES = '[Silent Rest Call] [AutoRef] GetReferralsNotes',
  GET_REFERRALS_NOTES_RES = '[Silent Rest Result] [AutoRef] GetReferralsNotesResults',
  GET_REFERRALS_NOTES_ERR = '[Silent Rest Error] [AutoRef] GetReferralsNotesError',

  SET_REFERRALS_NOTE = '[Rest Call] [AutoRef] SetReferralsNote',
  SET_REFERRALS_NOTE_RES = '[Rest Result] [AutoRef] SetReferralsNoteResults',
  SET_REFERRALS_NOTE_ERR = '[Rest Error] [AutoRef] SetReferralsNoteError',

  GET_REFERRAL_ELEMENTS = '[Silent Rest Call] [AutoRef] GetReferralElements',
  GET_REFERRAL_ELEMENTS_RES = '[Silent Rest Result] [AutoRef] GetReferralElementsResults',
  GET_REFERRAL_ELEMENTS_ERR = '[Silent Rest Error] [AutoRef] GetReferralElementsError',

  ADD_REFERRAL_ATTACHMENT = '[Rest Call] [AutoRef] AddReferralAttachment',
  ADD_REFERRAL_ATTACHMENT_RES = '[Rest Result] [AutoRef] AddReferralAttachmentResults',
  ADD_REFERRAL_ATTACHMENT_ERR = '[Rest Error] [AutoRef] AddReferralAttachmentError',

  GET_REFERRAL_ATTACHMENTS = '[Silent Rest Call] [AutoRef] GetReferralAttachments',
  GET_REFERRAL_ATTACHMENTS_RES = '[Silent Rest Result] [AutoRef] GetReferralAttachmentsResults',
  GET_REFERRAL_ATTACHMENTS_ERR = '[Silent Rest Error] [AutoRef] GetReferralAttachmentsError',

  REMOVE_REFERRAL_ATTACHMENT = '[Rest Call] [AutoRef] RemoveReferralAttachment',
  REMOVE_REFERRAL_ATTACHMENT_RES = '[Rest Result] [AutoRef] RemoveReferralAttachmentResults',
  REMOVE_REFERRAL_ATTACHMENT_ERR = '[Rest Error] [AutoRef] RemoveReferralAttachmentError',

  CHECK_REFERRAL_MENU = '[Silent Rest Call] [AutoRef] CheckReferralMenu',
  CHECK_REFERRAL_MENU_RES = '[Silent Rest Result] [AutoRef] CheckReferralMenuResults',
  CHECK_REFERRAL_MENU_ERR = '[Silent Rest Error] [AutoRef] CheckReferralMenuError',

  DISMISS_REFERRAL = '[Rest Call] [AutoRef] DismissReferral',
  DISMISS_REFERRAL_RES = '[Rest Result] [AutoRef] DismissReferralResults',
  DISMISS_REFERRAL_ERR = '[Rest Error] [AutoRef] DismissReferralError',

  QUALIFY_REFERRAL = '[Rest Call] [AutoRef] QualifyReferral',
  QUALIFY_REFERRAL_RES = '[Rest Result] [AutoRef] QualifyReferralResults',
  QUALIFY_REFERRAL_ERR = '[Rest Error] [AutoRef] QualifyReferralError',
}

export const GetAutomatedReferralList = createAction(AutoRefActionTypes.GET_REFERRAL_LIST, props<{ isQualified?: boolean, isDismissed?: boolean }>());
export const GetAutomatedReferralResults = createAction(AutoRefActionTypes.GET_REFERRAL_LIST_RES,
  props<{ automatedReferralList: ReferralOutputDto }>());
export const GetAutomatedReferralError = createAction(AutoRefActionTypes.GET_REFERRAL_LIST_ERR, props<{ message: string }>());


export const GetReferralSettings = createAction(AutoRefActionTypes.GET_REFFERAL_SETTINGS);
export const GetReferralSettingsResults = createAction(AutoRefActionTypes.GET_REFFERAL_SETTINGS_RES, props<{ referralSettings: ReferralSettings }>());
export const GetReferralSettingsError = createAction(AutoRefActionTypes.GET_REFFERAL_SETTINGS_ERR, props<{ message: string }>());

export const UpdateReferralSettings = createAction(AutoRefActionTypes.UPDATE_REFFERAL_SETTINGS, props<{ saveData: SaveReferralSettings }>());
export const UpdateReferralSettingsResults = createAction(AutoRefActionTypes.UPDATE_REFFERAL_SETTINGS_RES, props<{ response: SubmitResponse }>());
export const UpdateReferralSettingsError = createAction(AutoRefActionTypes.UPDATE_REFFERAL_SETTINGS_ERR, props<{ message: string }>());

export const SetReferralsColumns = createAction(AutoRefActionTypes.SET_REFERRALS_COLUMNS, props<{ columns: ReferralColumnDTO[] }>())
export const SetReferralsColumnsResults = createAction(AutoRefActionTypes.SET_REFERRALS_COLUMNS_RES, props<{ columns: ReferralColumnDTO[] }>())
export const SetReferralsColumnsError = createAction(AutoRefActionTypes.SET_REFERRALS_COLUMNS_ERR, props<{ message: string }>())

export const GetReferralsNotes = createAction(AutoRefActionTypes.GET_REFERRALS_NOTES, props<{ sid: number }>())
export const GetReferralsNotesResults = createAction(AutoRefActionTypes.GET_REFERRALS_NOTES_RES, props<{ notes: ReferralNotesDTO[] }>())
export const GetReferralsNotesError = createAction(AutoRefActionTypes.GET_REFERRALS_NOTES_ERR, props<{ message: string }>())

export const SetReferralsNote = createAction(AutoRefActionTypes.SET_REFERRALS_NOTE, props<{ sid: number, note: string, pid: number }>())
export const SetReferralsNoteResults = createAction(AutoRefActionTypes.SET_REFERRALS_NOTE_RES, props<{ response: SubmitResponse }>())
export const SetReferralsNoteError = createAction(AutoRefActionTypes.SET_REFERRALS_NOTE_ERR, props<{ message: string }>())

export const GetReferralElements = createAction(AutoRefActionTypes.GET_REFERRAL_ELEMENTS, props<{ sid: number, mhafid: number }>())
export const GetReferralElementsResults = createAction(AutoRefActionTypes.GET_REFERRAL_ELEMENTS_RES, props<{ referralElements: ReferralElementsDTO }>())
export const GetReferralElementsError = createAction(AutoRefActionTypes.GET_REFERRAL_ELEMENTS_ERR, props<{ message: string }>())

export const AddReferralAttachment = createAction(AutoRefActionTypes.ADD_REFERRAL_ATTACHMENT, props<{ sid: number, pid: number, rxnumber: string, attachment: FormData }>())
export const AddReferralAttachmentResults = createAction(AutoRefActionTypes.ADD_REFERRAL_ATTACHMENT_RES, props<{ response: SubmitResponse }>())
export const AddReferralAttachmentError = createAction(AutoRefActionTypes.ADD_REFERRAL_ATTACHMENT_ERR, props<{ message: string }>())

export const GetReferralAttachments = createAction(AutoRefActionTypes.GET_REFERRAL_ATTACHMENTS, props<{ sid: number }>())
export const GetReferralAttachmentsResults = createAction(AutoRefActionTypes.GET_REFERRAL_ATTACHMENTS_RES, props<{ attachments: ReferralAttachments }>())
export const GetReferralAttachmentsError = createAction(AutoRefActionTypes.GET_REFERRAL_ATTACHMENTS_ERR, props<{ message: string }>())

export const RemoveReferralAttachment = createAction(AutoRefActionTypes.REMOVE_REFERRAL_ATTACHMENT, props<{ sid: number, guid: string, pid: number, fileName: string }>())
export const RemoveReferralAttachmentResults = createAction(AutoRefActionTypes.REMOVE_REFERRAL_ATTACHMENT_RES, props<{ response: SubmitResponse }>())
export const RemoveReferralAttachmentError = createAction(AutoRefActionTypes.REMOVE_REFERRAL_ATTACHMENT_ERR, props<{ message: string }>())

export const CheckReferralMenu = createAction(AutoRefActionTypes.CHECK_REFERRAL_MENU, props<{ uid: number, pid: number }>())
export const CheckReferralMenuResults = createAction(AutoRefActionTypes.CHECK_REFERRAL_MENU_RES, props<{ result: boolean }>())
export const CheckReferralMenuError = createAction(AutoRefActionTypes.CHECK_REFERRAL_MENU_ERR, props<{ message: string }>())

export const DismissReferral = createAction(AutoRefActionTypes.DISMISS_REFERRAL, props<{ mhafid: number, sid: number }>())
export const DismissReferralResults = createAction(AutoRefActionTypes.DISMISS_REFERRAL_RES, props<{ response: SubmitResponse }>())
export const DismissReferralError = createAction(AutoRefActionTypes.DISMISS_REFERRAL_ERR, props<{ message: string }>())

export const QualifyReferral = createAction(AutoRefActionTypes.QUALIFY_REFERRAL, props<{ mhafid: number, sid: number, refPid: number, filltype: number, rxnumber: string, notes: string, isDisqualified?: boolean }>())
export const QualifyReferralResults = createAction(AutoRefActionTypes.QUALIFY_REFERRAL_RES, props<{ response: SubmitResponse }>())
export const QualifyReferralError = createAction(AutoRefActionTypes.QUALIFY_REFERRAL_ERR, props<{ message: string }>())
