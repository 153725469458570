import { Component, ViewChild, ElementRef, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { Store } from '@ngrx/store';
import { MatSort } from '@angular/material/sort';
import { Pharmacy, PharmacyGetOptions } from '../../../../../models/pharmacy';
import { UserState } from '../../../../../store/user.reducer';
import { Observable, Subscription } from 'rxjs';
import { BaseMhService } from 'src/app/service/basemh.service';
import { HttpClient } from '@angular/common/http';
import * as $ from 'jquery';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'user-pharmacy-table',
  templateUrl: './user-pharmacies-table.component.html',
  styleUrls: ['./user-pharmacies-table.component.scss']
})
export class UserPharmaciesTableComponent extends BaseMhService implements OnInit {

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild('TABLE') table: ElementRef;
  public uppharmacies$: Observable<Pharmacy[]>;
  isInternalUser:boolean = false;
  displayedColumns: string[] = ['space','pharmType','pid','pharmacy','location','utilCount','lastRecalculated'];
  dataSource;
  tableRow;
  subs: Subscription = new Subscription;

  constructor(protected http: HttpClient,
              public store: Store<{ userState: UserState }>) {
    super( http, store);
   }

  ngOnInit(): void {
    this.subs.add(
      this.store.select(state => state.userState.settings.isMHIUser).subscribe(val => this.isInternalUser = val)
    )
    
    this.subs.add(
      this.store.select(state => state.userState.uppharmacies).subscribe(data => {       
        if (data != null) {         
          let filteredData = data;

          var groupedData = this.sortAndGroupData(filteredData);

          this.dataSource= new MatTableDataSource(groupedData);
          this.dataSource.paginator = this.paginator;

          // tslint:disable-next-line:no-shadowed-variable
          this.dataSource.filterPredicate = (data, filter: string): boolean =>{

            if (data.group){
              return data.group.toString().toLowerCase().includes(filter)||
              data.hid.toString().toLowerCase().includes(filter)||
              data.pharmNames.toString().toLowerCase().includes(filter)||
              data.locations.toString().toLowerCase().includes(filter)||
              data.pids.toString().toLowerCase().includes(filter);
            }
            else{
              return data.hospitalName.toString().toLowerCase().includes(filter) ||
              data.pharmacy.toString().toLowerCase().includes(filter) ||
              data.pid.toString().includes(filter)||
              data.location.toString().toLowerCase().includes(filter)||
              data.hid.toString().toLowerCase().includes(filter);
            }
          }
        }
      })
    )
  }

  sortAndGroupData(data){
    data.forEach(el => {
      // cities are upppercase, parse them to correct casing
      if (el.location){
        const cityState = el.location.split(',');
        const cityParts = cityState[0].trim().split(' ');
        cityParts.forEach((c, i) => {
          cityParts[i] = c[0] + c.substring(1).toLowerCase();
        });
        el.location = cityParts.join(' ') + ', ' + cityState[1];
      }
      // parse utilcount to comma separated
      el.utilCount = el.utilCount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    });

    data.sort((a, b) => {
      return b.pharmActive - a.pharmActive || a.pharmType - b.pharmType;
    });

    const groups = data.reduce((r, a) => {
      r[a.hid] = [...r[a.hid] || [], a];
      return r;
      }, {});

    const groupedData = [];
    Object.keys(groups).forEach(key => {
      groupedData.push({
        group: groups[key][0].hospitalName,
        hid: key,
        pharmNames: groups[key].reduce((r, a) => r + a.pharmacy, ''),
        locations: groups[key].reduce((r, a) => r + a.location, ''),
        pids: groups[key].reduce((r, a) => r + a.pid,'')
      }, ...groups[key]);
    });
    return groupedData;
  }

  collapseRows(hid){
    const button = $('.button-hid-'+hid);
    if(button.hasClass('group-expanded')){
      $('.hid-group-'+hid).css('display','none');
      button.removeClass('group-expanded');
    }
    else{
      $('.hid-group-'+hid).css('display','table-row');
      button.addClass('group-expanded');
    }
  }

  isGroup(index, item): boolean{
    return item.group;
  }

  handleRowData(row){
    this.tableRow = row;
  }

  ngOnDestroy(){
    this.subs.unsubscribe();
  }
}
