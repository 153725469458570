import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of as observableOf } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { HL7Service } from '../service/hl7.service';
import { 
        GetHL7ClientList, GetHL7ClientListResults, GetHL7ClientListError,
        GetHL7PortRequestList, GetHL7PortRequestListResults, GetHL7PortRequestListError,
       } from './hl7.actions';

@Injectable()
export class HL7StoreEffects {

  constructor(private HL7Service: HL7Service, private actions$: Actions, private store: Store<{}>) {
  }

  GetHL7ClientListEffect$ = createEffect(() => this.actions$.pipe(
    ofType(GetHL7ClientList),
    switchMap(action =>
      this.HL7Service.getHL7ClientList()
        .pipe(
          map(
            data => {
              return GetHL7ClientListResults({ HL7ClientList: data })
            }
          ),
          catchError(
            error => observableOf(GetHL7ClientListError({ message: error }))
          )
        )
    )
  ))

  GetHL7PortRequestListEffect$ = createEffect(() => this.actions$.pipe(
    ofType(GetHL7PortRequestList),
    switchMap(action =>
      this.HL7Service.getHL7PortSetupRequest()
        .pipe(
          map(
            data => {
              return GetHL7PortRequestListResults({ HL7PortRequestList: data })
            }
          ),
          catchError(
            error => observableOf(GetHL7PortRequestListError({ message: error }))
          )
        )
    )
  ))

}
