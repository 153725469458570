<mat-drawer-container style="height: 100%" autosize>
  <mat-drawer #drawer class="mh-sidenav-drawer" mode="side" opened="{{menuVisible}}">

    <div *ngIf="currentApp !== 5">
      <mat-expansion-panel [expanded]="expandedSection == section.name"
        *ngFor="let section of filteredSections; let i = index" class="mh-sidenav mat-elevation-z0">
        <mat-expansion-panel-header>
          <mat-panel-title class="mh-sidenav-title">{{section.name}}</mat-panel-title>
          <mat-icon *ngIf="validation && section.name==='Admin Setup' && !userSettings.isMHIUser && hasHealthSystemView"  style="color: red;">priority_high</mat-icon>
        </mat-expansion-panel-header>
        <!--<mat-divider></mat-divider>-->
        <mat-nav-list class="left-nav-list" [disableRipple]="true">
          <div *ngFor="let menuItem of section.items"
            [ngStyle]="displayMap[menuItem.pageID] == 0 && {'display': 'none'}"
            [className]="displayMap[menuItem.pageID] == 2 ? (currentPage == menuItem.pageID ? 'list-group-item item-selected' : 'list-group-item') : 'list-group-item-disabled'">
            <a *ngIf="displayMap[menuItem.pageID] == 1" style="color:#656767" disabled>{{menuItem.pageName}}</a>
            <a *ngIf="displayMap[menuItem.pageID] == 2" (click)="pageSelected(menuItem)" href=""
              [routerLink]="menuItem.ngPageURL"
              [state]="{ menuItem: menuItem, application: (applications$ | async).Selected }">{{menuItem.pageName}}</a>             
              <mat-icon *ngIf="validation && menuItem.pageName==='Health System View' && !userSettings.isMHIUser"  style="color: red;">priority_high</mat-icon> 
            </div>
        </mat-nav-list>
      </mat-expansion-panel>
    </div>
    <div *ngIf="currentApp === 5" class="mh-sidenav mat-elevation-z0">
      <mat-nav-list class="left-nav-list" [disableRipple]="true">
        <div *ngFor="let page of analyticsReportPages"
          [className]="selectedReportPage == page.name ? 'list-group-item item-selected' : 'list-group-item'">
          <a class="analytics-page" (click)="updateReport(page.name)">{{page.displayName}}</a>
        </div>
      </mat-nav-list>
    </div>
    <!-- <div style="position: absolute;bottom: 0px;text-align: center; width: 100%; color: darkgrey"><div style="margin: auto">v{{version}}</div></div> -->
  </mat-drawer>
  <div [style.height.%]=setAppHeight() autosize>
    <router-outlet></router-outlet>
  </div>
</mat-drawer-container>
