import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';
import { Application, Applications, ApplicationMenuItem, ApplicationMenuSection } from 'src/app/models/applications';
import { AppSelected, PageSelected } from 'src/app/store/application-list.actions';
import { UiState } from 'src/app/store/ui-state.reducer';
import { MenuExpand, MenuColapse, InfoShow } from 'src/app/store/ui-state.actions';
import { Actions, ofType } from '@ngrx/effects';
import {
  UpdateUserQuickAccessPageResults,
  UpdateUserQuickAccessPage,
  UpdateUserApplicationAndAuthorize,
  GetDisclaimer
} from '../../store/user.actions';
import { UserState } from '../../store/user.reducer';
import { SaveUserQuickAccessPage, DisclaimerItem, UserSettings, UAMUserApplicationPage } from 'src/app/models/user';
import { UserService } from 'src/app/service/user.service';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { DisclaimerComponent } from '../disclaimer/disclaimer.component';
import { MatTableComponent } from '../mat-table/mat-table.component';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'horizontal-menu',
  templateUrl: './horizontal-menu.component.html',
  styleUrls: ['./horizontal-menu.component.scss']
})

export class HorizontalMenuComponent implements OnInit, OnDestroy {

  public applications$: Observable<Applications>;
  public uiState$: Observable<UiState>;
  public userStore$: Observable<UserState>;

  public menuVisible: boolean = false;

  public mmQuickAccessPage: number;
  public tfbQuickAccessPage: number;
  public gwQuickAccessPage: number;
  public favoritePageSelected: boolean;
  public selectedPageName: string;
  public userSettings: UserSettings;
  public selectedPage: ApplicationMenuItem = new ApplicationMenuItem();
  private disclaimerBeforeRouteArr = [70];
  allSections: ApplicationMenuSection[];
  isMHIEmployee: boolean;
  showDisclaimer = false;
  accessCheckNDCA: boolean;
  allowedPages: UAMUserApplicationPage[];
  allowedApps: Application[];
  appSelectedFlag: number;
  currentApp: number;
  subs: Subscription = new Subscription;

  constructor(private store: Store<{ uiState: UiState, applications: Applications, userState: UserState }>,
    private dialog: MatDialog,
    private router: Router, private actions$: Actions, private _userService: UserService) {
    this.favoritePageSelected = false;

    this.subs.add(
      this.store.select(state => state.uiState.MenuVisible).subscribe((val) => {
        if (val != this.menuVisible) {
          setTimeout(() => {
            this.menuVisible = val;
          });
        }
      })
    )
    this.subs.add(
      this.store.select(state => state.userState.disclaimer).subscribe((result?: DisclaimerItem) => {
        if (result && !this.isMHIEmployee && this.disclaimerBeforeRouteArr.includes(this.selectedPage.pageID)) {
          const { pageID, pageName } = this.selectedPage;
          const { disclaimerMessage, disclaimerID } = result;
          this.showDisclaimer = true;
          this.dialog.open(DisclaimerComponent, {
            disableClose: true,
            autoFocus: false,
            restoreFocus: false,
            panelClass: ['mh-dialog'],
            data: { pageID, pageName, disclaimerID, disclaimerMessage }
          })
        } else {
          this.showDisclaimer = false;
        }
      })
    )


  }

  private setMenuName(menu: ApplicationMenuItem) {
    if (menu.pageID === -1) {
      this.selectedPageName = menu.pageName;
    } else {
      this.selectedPageName = menu.sectionName + ' / ' + menu.pageName;
    }
  }

  ngOnInit() {
    this.applications$ = this.store.select(appState => appState.applications);
    this.uiState$ = this.store.select(uiState => uiState.uiState);
    this.userStore$ = this.store.select(userState => userState.userState);

    this.subs.add(
      this.applications$.subscribe(data => {
        if (data.Menu.Sections) {
          this.allSections = data.Menu.Sections;
        }
        if (data.Items) {
          this.allowedApps = data.Items;
        }
      })
    )

    this.subs.add(
      this.userStore$.subscribe(data => {
        if (data.settings) {
          this.userSettings = data.settings;
          this.mmQuickAccessPage = data.settings.macroManagerQuickAccessPageID;
          this.tfbQuickAccessPage = data.settings.architect340BQuickAccessPageID;
          this.gwQuickAccessPage = data.settings.gatewayQuickAccessPageID;
          this.isMHIEmployee = data.settings.isMHIUser;
        }
        if (data.session) {
          this.accessCheckNDCA = data.session.isNDCA !== 0 && data.session.pharmType !== 1;
          this.allowedPages = data.userAppPages;
        }
        if (data.currentApp){
          this.currentApp = data.currentApp;
        }
      })
    )

    this.subs.add(
      this.store.select(appState => appState.applications.Menu.Selected).subscribe(val => {
        if (val) {
          this.selectedPage = val;
          this.setMenuName(val);
          this.favoritePageSelected = (this.selectedPage.pageID === this.mmQuickAccessPage || this.selectedPage.pageID === this.tfbQuickAccessPage || this.selectedPage.pageID === this.gwQuickAccessPage);
        }
      })
    )


    this.subs.add(
      this.router.events.subscribe((val) => {
        if (val instanceof NavigationEnd) {
          let eventMeta = this.router.getCurrentNavigation().extras?.state;
          if (!eventMeta || eventMeta.data) {
            const menuItem = this.getMenuItemByUrl(val.urlAfterRedirects);
            if (menuItem) {
              eventMeta = { 
                menuItem: menuItem,
                data: eventMeta ? eventMeta.data : null
              };
            }
          }
          if (eventMeta && this.selectedPage.pageID > -1) {
            this.handleDisclaimerOnRouteChange();
            if (eventMeta.menuItem.pageID != this.selectedPage.pageID || this.appSelectedFlag != eventMeta.menuItem.appID) {
              if (this.currentApp != this.selectedPage.appID) {
                this.applicationSelected(this.allowedApps.find(a => a.appID == this.selectedPage.appID));
              }
              else if (eventMeta.menuItem.pageID != this.selectedPage.pageID) {
                // if app isnt changing but page didn't get updated in the store, we're probably navigating through a link within the asp page
                this.store.dispatch(PageSelected({ page: eventMeta.menuItem }));
              }
              this.appSelectedFlag = this.selectedPage.appID;
            }
          }
        }
      })
    )

    this.subs.add(
      this.actions$.pipe(ofType(UpdateUserQuickAccessPageResults)).subscribe((data) => {
        this.store.dispatch(InfoShow({ message: 'Your default landing page has been updated' }))
      })
    )

  }

  handleDisclaimerOnRouteChange() {
    const { pageID } = this.selectedPage;
    if (this.disclaimerBeforeRouteArr.includes(pageID)) {
      this.store.dispatch(GetDisclaimer({ pageId: pageID }));
    }
  }

  getMenuItemByUrl(url: string) {
    let appMenuItem: ApplicationMenuItem;
    for (let i = 0; i < this.allSections.length; i++) {
      const section = this.allSections[i];
      for (let j = 0; j < section.items.length; j++) {
        var applicationitem = section.items[j];
        if (url.includes(applicationitem.ngPageURL)) {
          appMenuItem = applicationitem;
        }
      }
    }
    if (!appMenuItem) {
      let uap = this.allowedPages.find(p => url.includes(p.ngPageURL));
      appMenuItem = {
        pageID: uap.pageID,
        appID: uap.appID,
        ngPageURL: uap.ngPageURL,
        active: 1,
        aspPageURL: null,
        pageName: uap.ngPageURL.split('/')[2],
        pageOrder: 1,
        sectionName: uap.ngPageURL.split('/')[1],
        sectionOrder: 1,
        permissionKey: uap.permissionKey
      };
    }
    return appMenuItem;
  }

  toggleFavorite() {
    this.favoritePageSelected = !this.favoritePageSelected;
    const saveUserQuickAccessPage: SaveUserQuickAccessPage = { pageId: 0, appId: 0, quickAccessPageUrl: '', accessAction: '' };

    if (this.favoritePageSelected) {
      saveUserQuickAccessPage.pageId = this.selectedPage.pageID;
      saveUserQuickAccessPage.appId = this.selectedPage.appID;
      saveUserQuickAccessPage.quickAccessPageUrl = this.selectedPage.ngPageURL;
      saveUserQuickAccessPage.accessAction = '';
    } else {
      saveUserQuickAccessPage.pageId = 0;
      saveUserQuickAccessPage.appId = this.selectedPage.appID;
      saveUserQuickAccessPage.quickAccessPageUrl = '';
      saveUserQuickAccessPage.accessAction = '';
    }

    this.store.dispatch(UpdateUserQuickAccessPage({ saveData: saveUserQuickAccessPage }));
  }

  applicationSelected(selectedApp: Application) {
    // if permissions contains any page within selected app
    if(this.allowedPages.find(x => x.appID == selectedApp.appID)){
      this.store.dispatch(AppSelected({ app: selectedApp }));
      this.store.dispatch(UpdateUserApplicationAndAuthorize({ appId: selectedApp.appID, pid: null }));
    }
    else{
      const dialogRef = this.dialog.open(MatTableComponent, {
        panelClass: ['mh-dialog','pid-selector-dialog'],
        disableClose: true,
        data: {
          appChange: selectedApp.appID
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result){
          this.store.dispatch(AppSelected({ app: selectedApp }));
          this.store.dispatch(UpdateUserApplicationAndAuthorize({ appId: selectedApp.appID, pid: result }));
        }
      })
    }

  }

  menuHide() {
    this.store.dispatch(MenuColapse());
  }

  menuShow() {
    this.store.dispatch(MenuExpand());
  }

  isApplicationDisabled(appId: number) {
    return (appId === 3 && !this.accessCheckNDCA);
  }

  ngOnDestroy(){
    this.subs.unsubscribe();
  }
}
