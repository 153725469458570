
import { Component, Inject, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { MatTable } from '@angular/material/table';
import { Store } from '@ngrx/store';
import { ConfirmDialogComponent } from 'src/app/shared/confirm-dialog/confirm-dialog.component';
import { OrderState } from 'src/app/store/order.reducer';
import { UserState } from 'src/app/store/user.reducer';

interface ExclusionItem {
  bin: string;
  pcn: string;
  group: string;
  exclusionType: string;
  effectiveDate: Date;
  newRow: boolean;
  rowIndex: number;
}

interface dialogData {
  modalTitle: string;
  binList: string;
}

@Component({
  selector: 'app-batch-bin-exclusion',
  templateUrl: './batch-bin-exclusion.component.html',
  styleUrls: ['./batch-bin-exclusion.component.scss']
})


export class BatchBinExclusionComponent implements OnInit {
  @ViewChild('stepper', { read: MatStepper }) stepper: MatStepper;
  @ViewChild(MatTable, { static: false }) binTable: any;
  ngAfterViewInit() {
      this.stepper._getIndicatorType = () => 'number';
  }

  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  isEditable = true;
  manualEntry: boolean;
  fileUpload: boolean;
  showDialog: boolean;
  dataSource: ExclusionItem[] = null;
  ExclusionTypes: string[];
  constructor(private dialogRef: MatDialogRef<BatchBinExclusionComponent>,
    @Inject(MAT_DIALOG_DATA) public receivedData: dialogData,
    public store: Store<{ orderState: OrderState, userState: UserState }>,
    public dialog: MatDialog,
    private _formBuilder: FormBuilder
  ) { }

  public childName = "bulkBinUpdateRules";

  ngOnChanges(changes: SimpleChanges): void {
    console.log('changed');
  }

  ngOnInit() {

    this.secondFormGroup = this._formBuilder.group({
      secondCtrl: ['', Validators.required]
    });

    this.manualEntry = true;
    this.fileUpload = false;
    this.ExclusionTypes = ["Medicaid"];
    console.log('test passed data from dialog');
    console.log(this.receivedData.binList);
    let index = 0;
    if (this.receivedData.binList !== undefined && this.receivedData.binList !== null) {
      var selectItems: ExclusionItem[] = JSON.parse(this.receivedData.binList);
      for (var item of selectItems) {
        if (item.bin !== undefined && item.bin !== null) {
          if (this.dataSource == undefined || this.dataSource == null)
            this.dataSource = [{ bin: item.bin, pcn: item.pcn, group: item.group, exclusionType: "", effectiveDate: null, newRow: false, rowIndex: index }];
          else
            this.dataSource.push({ bin: item.bin, pcn: item.pcn, group: item.group, exclusionType: "", effectiveDate: null, newRow: false, rowIndex: index });
        }
        index++;
      };

      this.dataSource.push({ bin: "", pcn: "", group: "", exclusionType: "", effectiveDate: null, newRow: true, rowIndex: index });
    }
    else {
      this.dataSource = [{ bin: "", pcn: "", group: "", exclusionType: "", effectiveDate: null, newRow: true, rowIndex: index }];
    }

  }
  public steps = [{ name: 'Select Qualification Rules', }, { name: 'Select PIDs to applu rules' }, { name: 'Review' }];
  displayedColumns: string[] = ['BIN', 'PCN', 'Group', 'ExclusionType', 'EffectiveDate', 'Action'];
  deleteRow(index) {
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Delete confirmation',
        message: 'Do you want to delete this row?\n \n',
        confirmValue: 'Yes',
        cancelValue: "No"
      }
    });
    confirmDialog.afterClosed().subscribe(result => {
      if (result) {
        if (index < this.dataSource.length) {
          console.log('yes');
          this.dataSource.splice(index, 1);
          this.binTable.renderRows();
        }
      }
    });
  }


  //Methods
  public dateChange($event): void {
    const inputDate = new Date($event.target.value);
    inputDate.setDate(inputDate.getDate() + 1);
    const today = new Date();
    if (inputDate < today)
      this.showDialog = true;
  }

  public CloseDialog() {
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Close confirmation',
        message: 'Do you want to close?\n \n',
        confirmValue: 'Yes',
        cancelValue: "No"
      }
    });
    confirmDialog.afterClosed().subscribe(result => {
      if (result) {
        this.dialogRef.close();
      }
    });
  }

  public CancelChanges() {
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Cancel changes confirmation',
        message: 'Do you want to cancel all changes?\n \n',
        confirmValue: 'Yes',
        cancelValue: "No"
      }
    });
    confirmDialog.afterClosed().subscribe(result => {
      if (result) {
        this.stepper.reset();
      }
    });
  }

  public Reset() {
    const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Reset confirmation',
        message: 'Do you want to clear all data and start at on step 1?\n \n',
        confirmValue: 'Yes',
        cancelValue: "No"
      }
    });
    confirmDialog.afterClosed().subscribe(result => {
      if (result) {
        this.stepper.reset();
      }
    });
  }
}
