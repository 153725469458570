import { Component, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { CSGId } from "src/app/models/user";
import { UserState } from "src/app/store/user.reducer";
import { CsgidsPopupComponent } from "../csgids-dialog/csgids-popup.component";
import { MatDialog } from "@angular/material/dialog";
import {
  ClearGatewayData,
  GetCSGIDList,
  GetSelectedCSGID,
} from "src/app/store/user.actions";
import { Subscription } from "rxjs";
import {
  GetGatewayChain,
  GetChainSettings,
} from "src/app/store/gateway/gateway.actions";
import { GatewayState } from "src/app/store/gateway/gateway.reducer";
import { filter } from "rxjs/operators";
import { ErrShow } from "src/app/store/ui-state.actions";
import { GetLookerUrl, GetLookerUrlError, GetSelectedDashboard, HideGatewayCsgids, ShowClientSelection, ShowGatewayCsgids } from 'src/app/store/user.actions';
import { Actions, ofType } from '@ngrx/effects';

@Component({
  selector: "app-csgid-selector",
  templateUrl: "./csgid-selector.component.html",
  styleUrls: ["./csgid-selector.component.scss"],
})
export class CsgidSelectorComponent implements OnInit {
  selectedCSGID: string;
  selectedCSGIDName: string;
  private subscriptions = new Subscription();
  csgids: CSGId[];
  lookerurl: string;
  route: boolean;
  hasToken: boolean = false;
  // Came from session token
  currentCSGID: string;
  currentCSGIDName: string;

  constructor(private actions$: Actions, public dialog: MatDialog, private store: Store<{ userState: UserState, gatewayState: GatewayState }>) {
    this.store.dispatch(GetCSGIDList());
  }

  ngOnInit(): void {
    // Scenerio where users logs back in with a token and didn't select anything from client-selection screen
    this.store
      .select((state) => state.userState.session)
      .subscribe((session) => {
        if (session.chainGroupId && !this.selectedCSGID) {
          this.currentCSGID = session.chainGroupId.toString();
          this.currentCSGIDName = session.chainGroupName;
        }
      });

    this.subscriptions.add(
      this.store
        .select((state) => state.userState.csgids)
        .pipe(filter((csgids) => csgids !== null))
        .subscribe((csgids) => {
          this.csgids = csgids;

          if (this.hasToken) {
            if (this.csgids.length == 1 && this.csgids[0].csgid != null) {
              this.store.dispatch(
                GetSelectedCSGID({
                  csgId: this.csgids[0].csgid,
                  name: this.csgids[0].name,
                })
              );
  
              this.selectedCSGID = this.csgids[0].csgid;
              this.selectedCSGIDName = this.csgids[0].name;
              this.getDashboard(this.selectedCSGID, this.selectedCSGIDName);
            }
            // nothing selected and user session has an associated csgid
            else if (!this.selectedCSGID && this.currentCSGID) {
              this.store.dispatch(
                GetSelectedCSGID({
                  csgId: this.currentCSGID,
                  name: this.currentCSGIDName
                })
              );
  
              this.selectedCSGID = this.currentCSGID;
              this.selectedCSGIDName = this.currentCSGIDName;
              this.getDashboard(this.selectedCSGID, this.selectedCSGIDName);
            }
            // all else fails
            else {
              this.showErrorScreen();
            }
          }
        })
    );

    this.subscriptions.add(
      // Routed back from clientID-Selection component
      this.store.select(state => state.userState.selectedCSGID).subscribe(val => {
        if (val) {
          this.route = false;
          this.selectedCSGID = val.csgid;
          this.selectedCSGIDName = val.name;

          // Scenario where user chooses anything but a valid GW CSGID
          if (this.selectedCSGID == '-1') {
            this.showErrorScreen();
          }
          else {
            this.getDashboard(this.selectedCSGID, this.selectedCSGIDName);
          }
        }
        // Didnt hit the clientId-selection component at all (Had a cached login token or only one csgid)  
        else {
         this.hasToken = true;
        }
      })
    );

    // Case where looker url returns an error for whatever reason
    this.actions$.pipe(ofType(GetLookerUrlError)).subscribe(() => {
      this.showErrorScreen();
    });
  }

  openDialog() {
    this.route = true;
    this.store.dispatch(ShowGatewayCsgids());
    this.store.dispatch(ShowClientSelection());
  }

  getDashboard(selectedCSGID: string, selectedCSGIDName: string) {
    let isGatewayCSGID: boolean = false;

    // Check if node is also GateWay
    this.csgids.find(gatewayChains => {
      if (gatewayChains.csgid == selectedCSGID) {
        isGatewayCSGID = true;
      }
    });

    if (isGatewayCSGID) {
      this.store.dispatch(
        GetGatewayChain({ CSGID: parseInt(selectedCSGID) })
      );
      this.subscriptions.add(
        this.store
          .select((store) => store.gatewayState.chainData)
          .pipe(filter((chainData) => chainData !== null))
          .subscribe((chainData) => {
            this.store.dispatch(
              GetChainSettings({ chainId: chainData.data.chainID })
            );
          })
      );
    }
    else {
      this.showErrorScreen();
    }
  }

  showErrorScreen() {
    const iframe1 = document.getElementById('iframeUrl') as HTMLIFrameElement;
    if (iframe1) {
      iframe1.srcdoc = "<!DOCTYPE html><style>h1{padding-left:20%;padding-top:15%;}</style><h1>No Gateway PIDs are assigned to the User.</h1>";
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
    if (!this.route) {
      this.store.dispatch(HideGatewayCsgids());
    }
  }
}
