import { catchError, concatMap, map, switchMap } from "rxjs/operators";
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { forkJoin, of as observableOf } from 'rxjs';
import { GatewayService } from "src/app/service/gateway/gateway.service";
import { AddDispenseFeeSchedule, AddDispenseFeeScheduleError, AddDispenseFeeScheduleResults, AddDispenseFeeSchedules, DeleteDispenseFeeSchedule, DeleteDispenseFeeScheduleError, DeleteDispenseFeeScheduleResults, GetChainSettings, GetChainSettingsError, GetChainSettingsResults, GetCoveredEntitiesForCSGID, GetCoveredEntitiesForCSGIDError, GetCoveredEntitiesForCSGIDResults, GetDispenseFeeFormularyCurrent, GetDispenseFeeFormularyCurrentError, GetDispenseFeeFormularyCurrentResults, GetDispenseFeeFormularySetup, GetDispenseFeeFormularySetupCSGIDs, GetDispenseFeeFormularySetupCSGIDsError, GetDispenseFeeFormularySetupCSGIDsResults, GetDispenseFeeFormularySetupError, GetDispenseFeeFormularySetupResults, GetDispenseFeeFormularyUploads, GetDispenseFeeFormularyUploadsError, GetDispenseFeeFormularyUploadsResults, GetDispenseFeeSchedule, GetDispenseFeeScheduleError, GetDispenseFeeScheduleHistory, GetDispenseFeeScheduleHistoryError, GetDispenseFeeScheduleHistoryResults, GetDispenseFeeScheduleResults, GetGatewayChain, GetGatewayChainError, GetGatewayChainResults, GetGatewayChainStoresGroups, GetGatewayChainStoresGroupsError, GetGatewayChainStoresGroupsResults, GetGatewayTreeList, GetGatewayTreeListError, GetGatewayTreeListResults, SetChainSettings, SetChainSettingsError, SetChainSettingsResults, UpdateDispenseFeeSchedule, UpdateDispenseFeeScheduleError, UpdateDispenseFeeScheduleResults } from "./gateway.actions";

@Injectable()
export class GatewayStoreEffects {

  constructor(private gatewayService: GatewayService, private actions$: Actions) {
  }

  GetGatewayChainStoresGroupsEffects$ = createEffect(() => this.actions$.pipe(
    ofType(GetGatewayChainStoresGroups),
    switchMap(action =>
      this.gatewayService.getChainStoresGroupsList()
        .pipe(
          map(
            data => {
              return GetGatewayChainStoresGroupsResults({ chainList: data })
            }
          ),
          catchError(
            error => observableOf(GetGatewayChainStoresGroupsError({ message: error }))
          )
        )
    )
  ));

  getGatewayTree$ = createEffect(() => this.actions$.pipe(
    ofType(GetGatewayTreeList),
    switchMap(action =>
      this.gatewayService.getGatewayTreeView(action.CSGID)
        .pipe(
          map(
            data => {
              return GetGatewayTreeListResults({ treeview: data })
            }
          ),
          catchError(
            error => observableOf(GetGatewayTreeListError({ message: error }))
          )

        )
    )
  ));

  GetChainSettingsEffect$ = createEffect(() => this.actions$.pipe(
    ofType(GetChainSettings),
    switchMap(action =>
      this.gatewayService.getChainSettings(action.chainId)
        .pipe(
          map(
            data => {
              return GetChainSettingsResults({ chainSettings: data })
            }
          ),
          catchError(
            error => observableOf(GetChainSettingsError({ message: error }))
          )
        )
    )
  ));

  SetChainSettingsEffect$ = createEffect(() => this.actions$.pipe(
    ofType(SetChainSettings),
    switchMap(action =>
      this.gatewayService.setChainSettings(action.chainSettingsRequest)
        .pipe(
          map(
            data => {
              return SetChainSettingsResults({ response: data })
            }
          ),
          catchError(
            error => observableOf(SetChainSettingsError({ message: error }))
          )
        )
    )
  ));

  getGatewayChain$ = createEffect(() => this.actions$.pipe(
    ofType(GetGatewayChain),
    switchMap(action =>
      this.gatewayService.getGatewayChain(action.CSGID)
        .pipe(
          map(
            data => {
              return GetGatewayChainResults({ chainData: data })
            }
          ),
          catchError(
            error => observableOf(GetGatewayChainError({ message: error }))
          )
        )
    )
  ));

  getDispenseFeeFormularyList$ = createEffect(() => this.actions$.pipe(
    ofType(GetDispenseFeeFormularySetup),
    switchMap(action =>
      this.gatewayService.getDispenseFeeFormularySetup(action.CSGID, action.effectiveDate)
        .pipe(
          map(
            data => {
              return GetDispenseFeeFormularySetupResults({ formularySetupData: data })
            }
          ),
          catchError(
            error => observableOf(GetDispenseFeeFormularySetupError({ message: error }))
          )
        )
    )
  ));

  getDispenseFeeFormularyListCSGIDs$ = createEffect(() => this.actions$.pipe(
    ofType(GetDispenseFeeFormularySetupCSGIDs),
    switchMap(action =>
      this.gatewayService.getDispenseFeeFormularySetupCSGIDs()
        .pipe(
          map(
            data => {
              return GetDispenseFeeFormularySetupCSGIDsResults({ formularySetupCSGIDs: data })
            }
          ),
          catchError(
            error => observableOf(GetDispenseFeeFormularySetupCSGIDsError({ message: error }))
          )
        )
    )
  ));

  addDispenseFeeSchedule$ = createEffect(() => this.actions$.pipe(
    ofType(AddDispenseFeeSchedule),
    concatMap(action =>
      this.gatewayService.addDispenseFeeSchedule(action.addDispenseFeeScheduleRequest)
        .pipe(
          map(
            data => {
              return AddDispenseFeeScheduleResults({ response: data })
            }
          ),
          catchError(
            error => observableOf(AddDispenseFeeScheduleError({ message: error }))
          )
        )
    )
  ));

  addDispenseFeeSchedules$ = createEffect(() => this.actions$.pipe(
    ofType(AddDispenseFeeSchedules),
    concatMap(action =>
      forkJoin(
        action.addDispenseFeeSchedulesRequest.map(schedule => this.gatewayService.addDispenseFeeSchedule(schedule)
    )             
    ).pipe(
      map(
        result => { 
          return AddDispenseFeeScheduleResults({response: result}) 
        }
      ),
      catchError(
        error => observableOf(AddDispenseFeeScheduleError({ message: error }))
      )
  ))));


  getDispenseFeeSchedule$ = createEffect(() => this.actions$.pipe(
    ofType(GetDispenseFeeSchedule),
    switchMap(action =>
      this.gatewayService.getDispenseFeeSchedule(action.CSGID)
        .pipe(
          map(
            data => {
              return GetDispenseFeeScheduleResults({ dispenseFeeScheduleResponse: data })
            }
          ),
          catchError(
            error => observableOf(GetDispenseFeeScheduleError({ message: error }))
          )
        )
    )
  ));

  getDispenseFeeScheduleHistory$ = createEffect(() => this.actions$.pipe(
    ofType(GetDispenseFeeScheduleHistory),
    switchMap(action =>
      this.gatewayService.getDispenseFeeScheduleHistory(action.CSGID, action.DrugType)
        .pipe(
          map(
            data => {
              return GetDispenseFeeScheduleHistoryResults({ dispenseFeeScheduleHistoryResponse: data })
            }
          ),
          catchError(
            error => observableOf(GetDispenseFeeScheduleHistoryError({ message: error }))
          )
        )
    )
  ));

  getDispenseFeeFormularyCurrent$ = createEffect(() => this.actions$.pipe(
    ofType(GetDispenseFeeFormularyCurrent),
    switchMap(action =>
      this.gatewayService.getDispenseFeeFormularyCurrent(action.CSGID)
        .pipe(
          map(
            data => {
              return GetDispenseFeeFormularyCurrentResults({ formularyCurrentResponse: data })
            }
          ),
          catchError(
            error => observableOf(GetDispenseFeeFormularyCurrentError({ message: error }))
          )
        )
    )
  ));

  getDispenseFeeFormularyUploads$ = createEffect(() => this.actions$.pipe(
    ofType(GetDispenseFeeFormularyUploads),
    switchMap(action =>
      this.gatewayService.getDispenseFeeFormularyUploads(action.CSGID)
        .pipe(
          map(
            data => {
              return GetDispenseFeeFormularyUploadsResults({ formularyUploadsResponse: data })
            }
          ),
          catchError(
            error => observableOf(GetDispenseFeeFormularyUploadsError({ message: error }))
          )
        )
    )
  ));

  updateDispenseFeeSchedule$ = createEffect(() => this.actions$.pipe(
    ofType(UpdateDispenseFeeSchedule),
    switchMap(action =>
      this.gatewayService.updateDispenseFeeSchedule(action.updateDispenseFeeScheduleRequest)
        .pipe(
          map(
            data => {
              return UpdateDispenseFeeScheduleResults({ response: data })
            }
          ),
          catchError(
            error => observableOf(UpdateDispenseFeeScheduleError({ message: error }))
          )
        )
    )
  ));

  deleteDispenseFeeSchedule$ = createEffect(() => this.actions$.pipe(
    ofType(DeleteDispenseFeeSchedule),
    switchMap(action =>
      this.gatewayService.deleteDispenseFeeSchedule(action.deleteDispenseFeeScheduleRequest)
        .pipe(
          map(
            data => {
              return DeleteDispenseFeeScheduleResults({ response: data })
            }
          ),
          catchError(
            error => observableOf(DeleteDispenseFeeScheduleError({ message: error }))
          )
        )
    )
  ));

  getCoveredEntitiesForCSGID$ = createEffect(() => this.actions$.pipe(
    ofType(GetCoveredEntitiesForCSGID),
    switchMap(action =>
      this.gatewayService.getCoveredEntitiesForCSGID(action.CSGID)
        .pipe(
          map(
            data => {
              return GetCoveredEntitiesForCSGIDResults({ coveredEntityResponse: data })
            }
          ),
          catchError(
            error => observableOf(GetCoveredEntitiesForCSGIDError({ message: error }))
          )
        )
    )
  ));
}