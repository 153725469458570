import { ApiReponse } from 'src/app/models/submit-response';
import { createAction, props } from '@ngrx/store';
import { TableGetOptions } from '../models/received-detail';
import { Manufacturer, Manufacturer_Details, MName, MSubscription, MHash, MFolder, Delete_Contact, PharmacyChainStore,
   SavePharmacyChainStore, SubmissionStatusInfo, ExclusionsRequest, ExclusionsInfo, ExclusionsSelection,
   ExclusionsSelectionsData, EspManufacturer, SubmissionHistoryInfo, SubmittedClaimsRequest, SubmittedClaim,
   AutoExclusionSettingsInfo,SaveAutoExclusionModel, ManufacturersNDC, newAutoExcludeSetting} from '../models/manufacturer';
import { OptInInfo, OptInSelection } from '../models/manufacturer';


export enum validate340bActionTypes {
  GET_MAN_DATA = '[Rest Call] [Validate340B] GetManufacturers',
  GET_MAN_DATA_RES = '[Rest Result] [Validate340B] GetManufacturerdataResults',
  GET_MAN_DATA_ERR = '[Rest Error] [Validate340B] GetManufacturerdataError',

  ADD_MANUFACTURER = '[Rest Call] [Validate340B] AddManufacturer',
  ADD_MANUFACTURER_RES = '[Rest Result] [Validate340B] AddManufacturerResults',
  ADD_MANUFACTURER_ERR = '[Rest Error] [Validate340B] AddManufacturerError',

  IS_UNIQUE_MNAME = '[Rest Call] [Validate340B] IsUniqueMName',
  IS_UNIQUE_MNAME_RES = '[Rest Result] [Validate340B] IsUniqueMNameResults',
  IS_UNIQUE_MNAME_ERR = '[Rest Error] [Validate340B] IsUniqueMNameError',

  IS_UNIQUE_MSUBKEY = '[Rest Call] [Validate340B] IsUniqueMSubkey',
  IS_UNIQUE_MSUBKEY_RES = '[Rest Result] [Validate340B] IsUniqueMSubkeyResults',
  IS_UNIQUE_MSUBKEY_ERR = '[Rest Error] [Validate340B] IsUniqueMSubkeyError',

  IS_UNIQUE_MFOLDER = '[Rest Call] [Validate340B] IsUniqueMFolder',
  IS_UNIQUE_MFOLDER_RES = '[Rest Result] [Validate340B] IsUniqueMFolderResults',
  IS_UNIQUE_MFOLDER_ERR = '[Rest Error] [Validate340B] IsUniqueMFolderError',

  GET_MDETAILS_BYID = '[Rest Call] [Validate340B] GetMViewDetails',
  GET_MDETAILS_BYID_RES = '[Rest Result] [Validate340B] GetMViewDetailResults',
  GET_MDETAILS_BYID_ERR = '[Rest Error] [Validate340B] GetMViewDetailError',

  UPDATE_MANUFACTURER = '[Rest Call] [Validate340B] UpdateManufacturer',
  UPDATE_MANUFACTURER_RES = '[Rest Result] [Validate340B] UpdateManufacturerResults',
  UPDATE_MANUFACTURER_ERR = '[Rest Error] [Validate340B] UpdateManufacturerError',

  DELETE_MCONTACT = '[Rest Call] [Validate340B] DeleteMContact',
  DELETE_MCONTACT_RES = '[Rest Result] [Validate340B] DeleteMContactResults',
  DELETE_MCONTACT_ERR = '[Rest Error] [Validate340B] DeleteMContactError',

  GET_CHAIN_STORE_LIST = '[Rest Call] [Validate340B] GetChainStoreList',
  GET_CHAIN_STORE_LIST_RES = '[Rest Result] [Validate340B] GetChainStoreListResults',
  GET_CHAIN_STORE_LIST_ERR = '[Rest Error] [Validate340B] GetChainStoreListError',

  GET_CHAIN_STORE_BY_NAME = '[Rest Call] [Validate340B] GetChainStoreByName',
  GET_CHAIN_STORE_BY_NAME_RES = '[Rest Result] [Validate340B] GetChainStoreByNameResults',
  GET_CHAIN_STORE_BY_NAME_ERR = '[Rest Error] [Validate340B] GetChainStoreByNameError',

  GET_CHAIN_STORE_BY_ID = '[Rest Call] [Validate340B] GetChainStoreById',
  GET_CHAIN_STORE_BY_ID_RES = '[Rest Result] [Validate340B] GetChainStoreByIdResults',
  GET_CHAIN_STORE_BY_ID_ERR = '[Rest Error] [Validate340B] GetChainStoreByIdError',

  UPDATE_CHAIN_STORE = '[Rest Call] [Validate340B] UpdateChainStore',
  UPDATE_CHAIN_STORE_RES = '[Rest Result] [Validate340B] UpdateChainStoreResults',
  UPDATE_CHAIN_STORE_ERR = '[Rest Error] [Validate340B] UpdateChainStoreError',

  CLEAR_MANUFACTURER_DETAILS = '[Validate340B] ClearManufacturerDetails',

  GET_OPT_IN_INFO_BY_MID = '[Rest Call] [DataSubmission] GetOptInInfoByMID',
  GET_OPT_IN_INFO_BY_MID_RES = '[Rest Result] [DataSubmission] GetOptInInfoByMIDResults',
  GET_OPT_IN_INFO_BY_MID_ERR = '[Rest Error] [DataSubmission] GetOptInInfoByMIDError',

  SUBMIT_OPT_IN_DATA = '[Rest Call] [DataSubmission] SubmitOptInData',
  SUBMIT_OPT_IN_DATA_RES = '[Rest Result] [DataSubmission] SubmitOptInDataResults',
  SUBMIT_OPT_IN_DATA_ERR = '[Rest Error] [DataSubmission] SubmitOptInDataError',

  GET_SUBMISSION_STATUS = '[Rest Call] [DataSubmission] GetSubmissionStatus',
  GET_SUBMISSION_STATUS_RES = '[Rest Result] [DataSubmission] GetSubmissionStatusResults',
  GET_SUBMISSION_STATUS_ERR = '[Rest Error] [DataSubmission] GetSubmissionStatusError',

  CLEAR_OPT_IN_DATA = '[Validate340B] ClearOptInData',

  GET_EXCLUSIONS_INFO = '[Rest Call] [BulkExclusions] GetExclusionsInfo',
  GET_EXCLUSIONS_INFO_RES = '[Rest Result] [BulkExclusions] GetExclusionsInfoResults',
  GET_EXCLUSIONS_INFO_ERR = '[Rest Error] [BulkExclusions] GetExclusionsInfoError',

  GET_EXCLUSIONS_WITH_DRUG_INFO = '[Rest Call] [BulkExclusions] GetExclusionsWithDrugInfo',
  GET_EXCLUSIONS_WITH_DRUG_INFO_RES = '[Rest Result] [BulkExclusions] GetExclusionsWithDrugInfoResults',
  GET_EXCLUSIONS_WITH_DRUG_INFO_ERR = '[Rest Error] [BulkExclusions] GetExclusionsWithDrugInfoError',

  SUBMIT_EXCLUSIONS_DATA = '[Rest Call] [BulkExclusions] SubmitExclusionsData',
  SUBMIT_EXCLUSIONS_DATA_RES = '[Rest Result] [BulkExclusions] SubmitExclusionsDataResults',
  SUBMIT_EXCLUSIONS_DATA_ERR = '[Rest Error] [BulkExclusions] SubmitExclusionsDataError',

  GET_ESP_MANUFACTURERS = '[Silent Rest Call] [BulkExclusions] GetEspManufacturers',
  GET_ESP_MANUFACTURERS_RES = '[Silent Rest Result] [BulkExclusions] GetEspManufacturersResults',
  GET_ESP_MANUFACTURERS_ERR = '[Rest Error] [BulkExclusions] GetEspManufacturersError',

  GET_PRICE_VARIANCE = '[BulkExclusions] GetPriceVariance',
  GET_PRICE_VARIANCE_RES = '[BulkExclusions] GetPriceVarianceResults',
  GET_PRICE_VARIANCE_ERR = '[BulkExclusions] GetPriceVarianceError',

  GET_MCKPHSCONTRACT_FLAG_VISIBILITY = '[BulkExclusion] GetMckPHSContractFlagVisibility',
  GET_MCKPHSCONTRACT_FLAG_VISIBILITY_RES = '[BulkExclusion] GetMckPHSContractFlagVisibilityResults',
  GET_MCKPHSCONTRACT_FLAG_VISIBILITY_ERR = '[BulkExclusion] GetMckPHSContractFlagVisibilityError',

  CLEAR_EXCLUSIONS_DATA = '[Validate340B] ClearExclusionsData',

  GET_SUBMISSION_HISTORY = '[Rest Call] [DataSubmission] GetSubmissionHistoryByHID',
  GET_SUBMISSION_HISTORY_RES = '[Rest Result] [DataSubmission] GetSubmissionHistoryByHIDResults',
  GET_SUBMISSION_HISTORY_ERR = '[Rest Error] [DataSubmission] GetSubmissionHistoryByHIDError',

  GET_SUBMISSION_HISTORY_FILE = '[Rest Call] [DataSubmission] GetSubmissionHistoryFile',
  GET_SUBMISSION_HISTORY_FILE_RES = '[Rest Result] [DataSubmission] GetSubmissionHistoryFileResults',
  GET_SUBMISSION_HISTORY_FILE_ERR = '[Rest Error] [DataSubmission] GetSubmissionHistoryFileError',

  GET_SUBMITTED_CLAIMS = '[SubmittedClaims] GetSubmittedClaims',
  GET_SUBMITTED_CLAIMS_RES = '[SubmittedClaims] GetSubmittedClaimsResults',
  GET_SUBMITTED_CLAIMS_ERR = '[SubmittedClaims] GetSubmittedClaimsError',

  CLEAR_SUBMITTED_CLAIMS_DATA = '[SubmittedClaims] ClearSubmittedClaims',

  GET_AUTO_EXCLUSIONS = '[Rest Call] [AutoExclusionsSettings] GetAutoExclusions',
  GET_AUTO_EXCLUSIONS_RES = '[Rest Result] [AutoExclusionsSettings] GetAutoExclusionsResults',
  GET_AUTO_EXCLUSIONS_ERR = '[Rest Error] [AutoExclusionsSettings] GetAutoExclusionsError',

  SAVE_AUTO_EXCLUSIONS_DATA = '[Rest Call] [AutoExclusionsSettings] SaveAutoExclusionsData',
  SAVE_AUTO_EXCLUSIONS_DATA_RES = '[Rest Result] [AutoExclusionsSettings] SaveAutoExclusionsDataResults',
  SAVE_AUTO_EXCLUSIONS_DATA_ERR = '[Rest Error] [AutoExclusionsSettings] SaveAutoExclusionsDataError',

  GET_MAN_NDC = '[Rest Call] [manufacturersNdcs] GetManufacturersNdcs',
  GET_MAN_NDC_RES = '[Rest Result] [manufacturersNdcs] GetManufacturersNdcsResults',
  GET_MAN_NDC_ERR = '[Rest Error] [manufacturersNdcs] GetManufacturersNdcsError',

  SAVE_NEW_AUTO_EXCLUSIONS_DATA = '[Rest Call] [AutoExclusionsSettings] SaveNewAutoExclusionsData',
  SAVE_NEW_AUTO_EXCLUSIONS_DATA_RES = '[Rest Result] [AutoExclusionsSettings] SaveNewAutoExclusionsDataResults',
  SAVE_NEW_AUTO_EXCLUSIONS_DATA_ERR = '[Rest Error] [AutoExclusionsSettings] SaveNewAutoExclusionsDataError'
}

export const GetManufacturers = createAction(validate340bActionTypes.GET_MAN_DATA, props<{ options: TableGetOptions }>());
export const GetManufacturerResults = createAction(validate340bActionTypes.GET_MAN_DATA_RES, props<{ manufacturers: Manufacturer[] }>());
export const GetManufacturerError = createAction(validate340bActionTypes.GET_MAN_DATA_ERR, props<{ message: string }>());

export const IsUniqueMName = createAction(validate340bActionTypes.IS_UNIQUE_MNAME, props<{ mname: MName }>());
export const IsUniqueMNameResults = createAction(validate340bActionTypes.IS_UNIQUE_MNAME_RES, props<{ countval: number }>());
export const IsUniqueMNameError = createAction(validate340bActionTypes.IS_UNIQUE_MNAME_ERR, props<{ message: string }>());

export const IsUniqueMSubkey = createAction(validate340bActionTypes.IS_UNIQUE_MSUBKEY, props<{ skey: MSubscription}>());
export const IsUniqueMSubkeyResults = createAction(validate340bActionTypes.IS_UNIQUE_MSUBKEY_RES, props<{ countval: number }>());
export const IsUniqueMSubkeyError = createAction(validate340bActionTypes.IS_UNIQUE_MSUBKEY_ERR, props<{ message: string }>());

export const IsUniqueMFolder = createAction(validate340bActionTypes.IS_UNIQUE_MFOLDER, props<{ mfolder: MFolder }>());
export const IsUniqueMFolderResults = createAction(validate340bActionTypes.IS_UNIQUE_MFOLDER_RES, props<{ countval: number }>());
export const IsUniqueMFolderError = createAction(validate340bActionTypes.IS_UNIQUE_MFOLDER_ERR, props<{ message: string }>());

export const AddManufacturer = createAction(validate340bActionTypes.ADD_MANUFACTURER, props<{ addData: Manufacturer_Details }>());
export const AddManufacturerResults = createAction(validate340bActionTypes.ADD_MANUFACTURER_RES, props<{ addData: Manufacturer_Details }>());
export const AddManufacturerError = createAction(validate340bActionTypes.ADD_MANUFACTURER_ERR, props<{ message: string }>());

export const GetMViewDetails = createAction(validate340bActionTypes.GET_MDETAILS_BYID, props<{ manId: number }>());
export const GetMViewDetailResults = createAction(validate340bActionTypes.GET_MDETAILS_BYID_RES, props<{ mdetails: Manufacturer_Details }>());
export const GetMViewDetailError = createAction(validate340bActionTypes.GET_MDETAILS_BYID_ERR, props<{ errorMessage: string }>());

export const UpdateManufacturer = createAction(validate340bActionTypes.UPDATE_MANUFACTURER, props<{ updatedtls: Manufacturer_Details }>());
export const UpdateManufacturerResults = createAction(validate340bActionTypes.UPDATE_MANUFACTURER_RES, props<{ updatedtls: Manufacturer_Details }>());
export const UpdateManufacturerError = createAction(validate340bActionTypes.UPDATE_MANUFACTURER_ERR, props<{ message: string }>());

export const DeleteMContact = createAction(validate340bActionTypes.DELETE_MCONTACT, props<{ delcontact: Delete_Contact }>());
export const DeleteMContactResults = createAction(validate340bActionTypes.DELETE_MCONTACT_RES, props<{ delcontact: Delete_Contact }>());
export const DeleteMContactError = createAction(validate340bActionTypes.DELETE_MCONTACT_ERR, props<{ message: string }>());

export const GetChainStoreList = createAction(validate340bActionTypes.GET_CHAIN_STORE_LIST);
export const GetChainStoreListResults = createAction(validate340bActionTypes.GET_CHAIN_STORE_LIST_RES, props<{ chainStoreList: PharmacyChainStore[] }>());
export const GetChainStoreListError = createAction(validate340bActionTypes.GET_CHAIN_STORE_LIST_ERR, props<{ errorMessage: string }>());

export const GetChainStoreByName = createAction(validate340bActionTypes.GET_CHAIN_STORE_BY_NAME, props<{ chainName: string }>());
export const GetChainStoreByNameResults = createAction(validate340bActionTypes.GET_CHAIN_STORE_BY_NAME_RES, props<{ chainStore: PharmacyChainStore }>());
export const GetChainStoreByNameError = createAction(validate340bActionTypes.GET_CHAIN_STORE_BY_NAME_ERR, props<{ errorMessage: string }>());

export const GetChainStoreById = createAction(validate340bActionTypes.GET_CHAIN_STORE_BY_ID, props<{ chainID: number }>());
export const GetChainStoreByIdResults = createAction(validate340bActionTypes.GET_CHAIN_STORE_BY_ID_RES, props<{ chainStore: PharmacyChainStore }>());
export const GetChainStoreByIdError = createAction(validate340bActionTypes.GET_CHAIN_STORE_BY_ID_ERR, props<{ errorMessage: string }>());

export const UpdateChainStore = createAction(validate340bActionTypes.UPDATE_CHAIN_STORE, props<{ updateChainStore: SavePharmacyChainStore }>());
export const UpdateChainStoreResults = createAction(validate340bActionTypes.UPDATE_CHAIN_STORE_RES, props<{ response: ApiReponse }>());
export const UpdateChainStoreError = createAction(validate340bActionTypes.UPDATE_CHAIN_STORE_ERR, props<{ errorMessage: string }>());

export const ClearManufacturerDetails = createAction(validate340bActionTypes.CLEAR_MANUFACTURER_DETAILS);

export const GetOptInInfoByMID = createAction(validate340bActionTypes.GET_OPT_IN_INFO_BY_MID, props<{ mid: number }>());
export const GetOptInInfoByMIDResults = createAction(validate340bActionTypes.GET_OPT_IN_INFO_BY_MID_RES, props<{ optInData: OptInInfo[] }>());
export const GetOptInInfoByMIDError = createAction(validate340bActionTypes.GET_OPT_IN_INFO_BY_MID_ERR, props<{ errorMessage: string }>());

export const SubmitOptInData = createAction(validate340bActionTypes.SUBMIT_OPT_IN_DATA, props<{ selections: OptInSelection[] }>());
export const SubmitOptInDataResults = createAction(validate340bActionTypes.SUBMIT_OPT_IN_DATA_RES, props<{ response: ApiReponse }>());
export const SubmitOptInDataError = createAction(validate340bActionTypes.SUBMIT_OPT_IN_DATA_ERR, props<{ errorMessage: string }>());

export const GetSubmissionStatus = createAction(validate340bActionTypes.GET_SUBMISSION_STATUS);
export const GetSubmissionStatusResults = createAction(validate340bActionTypes.GET_SUBMISSION_STATUS_RES, props<{ statuses: SubmissionStatusInfo[] }>());
export const GetSubmissionStatusError = createAction(validate340bActionTypes.GET_SUBMISSION_STATUS_ERR, props<{ errorMessage: string }>());

export const ClearOptInData = createAction(validate340bActionTypes.CLEAR_OPT_IN_DATA);

export const GetExclusionsInfo = createAction(validate340bActionTypes.GET_EXCLUSIONS_INFO, props<{ request: ExclusionsRequest }>());
export const GetExclusionsInfoResults = createAction(validate340bActionTypes.GET_EXCLUSIONS_INFO_RES, props<{ exclusionsData: ExclusionsInfo[] }>());
export const GetExclusionsInfoError = createAction(validate340bActionTypes.GET_EXCLUSIONS_INFO_ERR, props<{ errorMessage: string }>());

export const GetExclusionsWithDrugInfo = createAction(validate340bActionTypes.GET_EXCLUSIONS_WITH_DRUG_INFO, props<{ request: ExclusionsRequest }>());
export const GetExclusionsWithDrugInfoResults = createAction(validate340bActionTypes.GET_EXCLUSIONS_WITH_DRUG_INFO_RES, props<{ exclusionsData: ExclusionsInfo[] }>());
export const GetExclusionsWithDrugInfoError = createAction(validate340bActionTypes.GET_EXCLUSIONS_WITH_DRUG_INFO_ERR, props<{ errorMessage: string }>());

export const SubmitExclusionsData = createAction(validate340bActionTypes.SUBMIT_EXCLUSIONS_DATA, props<{ selections: ExclusionsSelectionsData }>());
export const SubmitExclusionsDataResults = createAction(validate340bActionTypes.SUBMIT_EXCLUSIONS_DATA_RES, props<{ response: ApiReponse }>());
export const SubmitExclusionsDataError = createAction(validate340bActionTypes.SUBMIT_EXCLUSIONS_DATA_ERR, props<{ errorMessage: string }>());

export const GetEspManufacturers = createAction(validate340bActionTypes.GET_ESP_MANUFACTURERS, props<{ ndcsourceone: boolean }>());
export const GetEspManufacturersResults = createAction(validate340bActionTypes.GET_ESP_MANUFACTURERS_RES, props<{ espmanData: EspManufacturer[] }>());
export const GetEspManufacturersError = createAction(validate340bActionTypes.GET_ESP_MANUFACTURERS_ERR, props<{ errorMessage: string }>());

export const GetPriceVariance = createAction(validate340bActionTypes.GET_PRICE_VARIANCE);
export const GetPriceVarianceResults = createAction(validate340bActionTypes.GET_PRICE_VARIANCE_RES, props<{ variance: string }>());
export const GetPriceVarianceError = createAction(validate340bActionTypes.GET_PRICE_VARIANCE_ERR, props<{ errorMessage: string }>());

export const GetMckPHSContractFlagVisibility = createAction(validate340bActionTypes.GET_MCKPHSCONTRACT_FLAG_VISIBILITY);
export const GetMckPHSContractFlagVisibilityResults = createAction(validate340bActionTypes.GET_MCKPHSCONTRACT_FLAG_VISIBILITY_RES, props<{ visible: boolean }>());
export const GetMckPHSContractFlagVisibilityError = createAction(validate340bActionTypes.GET_MCKPHSCONTRACT_FLAG_VISIBILITY_ERR, props<{ message: string }>());

export const ClearExclusionsData = createAction(validate340bActionTypes.CLEAR_EXCLUSIONS_DATA);

export const GetSubmissionHistoryByHID = createAction(validate340bActionTypes.GET_SUBMISSION_HISTORY, props<{ hid: number }>());
export const GetSubmissionHistoryByHIDResults = createAction(validate340bActionTypes.GET_SUBMISSION_HISTORY_RES, props<{ historyData: SubmissionHistoryInfo[] }>());
export const GetSubmissionHistoryByHIDError = createAction(validate340bActionTypes.GET_SUBMISSION_HISTORY_ERR, props<{ errorMessage: string }>());

export const GetSubmissionHistoryFile = createAction(validate340bActionTypes.GET_SUBMISSION_HISTORY_FILE, props<{ blobUrl: string, hid: number }>());
export const GetSubmissionHistoryFileResults = createAction(validate340bActionTypes.GET_SUBMISSION_HISTORY_FILE_RES, props<{ file: string }>());
export const GetSubmissionHistoryFileError = createAction(validate340bActionTypes.GET_SUBMISSION_HISTORY_FILE_ERR, props<{ errorMessage: string }>());

export const GetSubmittedClaims = createAction(validate340bActionTypes.GET_SUBMITTED_CLAIMS, props<{ request: SubmittedClaimsRequest }>());
export const GetSubmittedClaimsResults = createAction(validate340bActionTypes.GET_SUBMITTED_CLAIMS_RES, props<{ submittedClaims: SubmittedClaim[] }>());
export const GetSubmittedClaimsError = createAction(validate340bActionTypes.GET_SUBMITTED_CLAIMS_ERR, props<{ errorMessage: string }>());

export const ClearSubmittedClaimsData = createAction(validate340bActionTypes.CLEAR_SUBMITTED_CLAIMS_DATA);

export const GetAutoExclusions = createAction(validate340bActionTypes.GET_AUTO_EXCLUSIONS);
export const GetAutoExclusionsResults = createAction(validate340bActionTypes.GET_AUTO_EXCLUSIONS_RES, props<{ autoExclusionsData: AutoExclusionSettingsInfo[] }>());
export const GetAutoExclusionsError = createAction(validate340bActionTypes.GET_AUTO_EXCLUSIONS_ERR, props<{ errorMessage: string }>());

export const SaveAutoExclusionsData = createAction(validate340bActionTypes.SAVE_AUTO_EXCLUSIONS_DATA, props<{ selections: SaveAutoExclusionModel, ops: number }>());
export const SaveAutoExclusionsDataResults = createAction(validate340bActionTypes.SAVE_AUTO_EXCLUSIONS_DATA_RES, props<{ response: ApiReponse }>());
export const SaveAutoExclusionsDataError = createAction(validate340bActionTypes.SAVE_AUTO_EXCLUSIONS_DATA_ERR, props<{ errorMessage: string }>());

export const GetManufacturersNdcs = createAction(validate340bActionTypes.GET_MAN_NDC);
export const GetManufacturersNdcsResults = createAction(validate340bActionTypes.GET_MAN_NDC_RES, props< {response: ManufacturersNDC[] }>());
export const GetManufacturersNdcsError = createAction(validate340bActionTypes.GET_MAN_NDC_ERR, props<{ errorMessage: string }>());

export const SaveNewAutoExclusionsData = createAction(validate340bActionTypes.SAVE_NEW_AUTO_EXCLUSIONS_DATA, props<{ settings: newAutoExcludeSetting[] }>());
export const SaveNewAutoExclusionsDataResults = createAction(validate340bActionTypes.SAVE_NEW_AUTO_EXCLUSIONS_DATA_RES, props<{ response: ApiReponse }>());
export const SaveNewAutoExclusionsDataError = createAction(validate340bActionTypes.SAVE_NEW_AUTO_EXCLUSIONS_DATA_ERR, props<{ errorMessage: string }>());
