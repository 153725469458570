import { SubmitResponse } from 'src/app/models/submit-response';
import {  Manufacturer, Manufacturer_Details, Manufacturer_Contacts, MName, MSubscription, MHash, MFolder, Delete_Contact,
  PharmacyChainStore,
  OptInInfo,
  SubmissionStatusInfo,
  ExclusionsInfo,
  EspManufacturer,
  SubmissionHistoryInfo,
  SubmittedClaim,
  AutoExclusionSettingsInfo,
  ManufacturersNDC} from '../models/manufacturer';
import { validate340bActionTypes } from './validate340b.actions';

export interface Validate340BState {
  manufacturers: Manufacturer[],
  addData : Manufacturer_Details,
  chainStoreList: PharmacyChainStore[],
  chainStore: PharmacyChainStore,
  mname: MName,
  skey: MSubscription,
  mhash: MHash,
  responseUpdateChainStore: SubmitResponse,
  mfolder: MFolder,
  mdetails: Manufacturer_Details,
  updatedtls: Manufacturer_Details,
  delcontact: Delete_Contact,
  isUniqueMName: number;
  isUniqueMSubkey: number;
  isUniqueMFolder: number;
  optInData: OptInInfo[];
  submissionStatusData: SubmissionStatusInfo[];
  exclusionsData: ExclusionsInfo[];
  exclusionsDataWithDrugInfo: ExclusionsInfo[];
  espmanData: EspManufacturer[];
  variance: string;
  phsContractVisible: boolean;
  historyData: SubmissionHistoryInfo[];
  submittedClaims: SubmittedClaim[];
  autoExclusionsSettings: AutoExclusionSettingsInfo[];
  manufacturersNdcs: ManufacturersNDC[];
}

export const initialState = {
  manufacturers: null,
  addData: null,
  chainStoreList: null,
  chainStore: null,
  mname: null,
  skey: null,
  mhash: null,
  response: null,
  mfolder: null,
  mdetails: null,
  updatedtls: null,
  delcontact: null,
  responseUpdateChainStore: null,
  isUniqueMName: 0,
  isUniqueMSubkey: 0,
  isUniqueMFolder: 0,
  optInData: null,
  submissionStatusData: null,
  exclusionsData: null,
  exclusionsDataWithDrugInfo: null,
  espmanData: null,
  variance: null,
  historyData: null,
  submittedClaims: null,
  phsContractVisible: null,
  autoExclusionsSettings: null,
  manufacturersNdcs: null
}

export function Validate340BActionsReducer(state = initialState, action: any): Validate340BState {
  switch (action.type) {
    case validate340bActionTypes.GET_MAN_DATA_RES: {
      return { ...state, manufacturers: action.manufacturers };
    }
    case validate340bActionTypes.ADD_MANUFACTURER_RES: {
      return { ...state, addData: action.addData };
    }
    case validate340bActionTypes.GET_MDETAILS_BYID_RES: {
      return { ...state, mdetails: action.mdetails }
    }
    case validate340bActionTypes.GET_CHAIN_STORE_BY_ID_RES: {
      return { ...state, chainStore: action.chainStore}
    }
    case validate340bActionTypes.GET_CHAIN_STORE_BY_NAME_RES: {
      return { ...state, chainStore: action.chainStore}
    }
    case validate340bActionTypes.GET_CHAIN_STORE_LIST_RES: {
      return { ...state, chainStoreList: action.chainStoreList}
    }
    case validate340bActionTypes.UPDATE_CHAIN_STORE: {
      return { ...state, responseUpdateChainStore: action.response };
    }
    case validate340bActionTypes.IS_UNIQUE_MNAME_RES: {
      return { ...state, isUniqueMName: action.countval };
    }
    case validate340bActionTypes.IS_UNIQUE_MSUBKEY_RES: {
      return { ...state, isUniqueMSubkey: action.countval };
    }
    case validate340bActionTypes.IS_UNIQUE_MFOLDER_RES: {
      return { ...state, isUniqueMFolder: action.countval };
    }
    case validate340bActionTypes.CLEAR_MANUFACTURER_DETAILS: {
      return { ...state, mdetails: null, isUniqueMFolder: 0, isUniqueMName: 0, isUniqueMSubkey: 0 };
    }
    case validate340bActionTypes.GET_OPT_IN_INFO_BY_MID_RES: {
      return { ...state, optInData: action.optInData };
    }
    case validate340bActionTypes.GET_SUBMISSION_STATUS_RES: {
      return { ...state, submissionStatusData: action.statuses };
    }
    case validate340bActionTypes.CLEAR_OPT_IN_DATA: {
      return { ...state, optInData: null };
    }
    case validate340bActionTypes.GET_EXCLUSIONS_INFO_RES: {
      return { ...state, exclusionsData: action.exclusionsData };
    }
    case validate340bActionTypes.GET_EXCLUSIONS_WITH_DRUG_INFO_RES: {
      return { ...state, exclusionsDataWithDrugInfo: action.exclusionsData };
    }
    case validate340bActionTypes.GET_ESP_MANUFACTURERS_RES: {
      return { ...state, espmanData: action.espmanData };
    }
    case validate340bActionTypes.GET_PRICE_VARIANCE_RES: {
      return { ...state, variance: action.variance };
    }
    case validate340bActionTypes.GET_MCKPHSCONTRACT_FLAG_VISIBILITY_RES: {
      return { ...state, phsContractVisible: action.visible };
    }
    case validate340bActionTypes.CLEAR_EXCLUSIONS_DATA: {
      return { ...state, exclusionsData: null, exclusionsDataWithDrugInfo: null };
    }
    case validate340bActionTypes.GET_SUBMITTED_CLAIMS_RES: {
      return { ...state, submittedClaims: action.submittedClaims };
    }
    case validate340bActionTypes.CLEAR_SUBMITTED_CLAIMS_DATA: {
      return { ...state, submittedClaims: null };
    }
    case validate340bActionTypes.GET_AUTO_EXCLUSIONS_RES: {
      return { ...state, autoExclusionsSettings: action.autoExclusionsData };
    }
    case validate340bActionTypes.GET_MAN_NDC_RES: {
      return {...state, manufacturersNdcs: action.response}
    }
    default:
      return state;
    }
}


