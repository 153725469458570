import { TableGetOptions } from './../models/received-detail';
import { Injectable } from '@angular/core';
import * as jwt_decode from 'jwt-decode';
import {
  SaveUserSettings, UiUser, UserSession, UserSettings,
  SaveUserSession, UserEmailSettings, SaveUserQuickAccessPage, UserAlternateLoginName,
  SaveUsersTrackHistory, SaveUserClass, PageInfo, SaveUserMHISettings,
  UsersPublicationSelection, SaveUserPubicationSelection, UserAccessToken, UserApplications,
  NotificationItem, UpdateNotification, Vendor, AddUserAlternateLoginName, NotificationItemHistory,
  NDCAPharmacy, DisclaimerItem, UserApplicationPage, CSGID, LookerUrl, UserTokenRequest, UserApplicationInfo,
  UserHelpUrl
} from '../models/user';
import { Observable, throwError } from 'rxjs';
import { BaseMhService } from './basemh.service';
import { catchError, map } from 'rxjs/operators';
import { SubmitResponse } from '../models/submit-response';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { UserState } from '../store/user.reducer';
import { Pharmacy, PharmacyGetOptions, UAMPharmacy } from '../models/pharmacy';
import { Application, ApplicationMenuItem } from '../models/applications';
import { AppListActionTypes } from '../store/application-list.actions';
import { SubmittedSupportTicket } from '../models/sales-force-ticket';
import { environment } from '../../environments/environment';
import { JwtHelperService } from '@auth0/angular-jwt';
import { B2cLoginService } from './b2clogin.service';
import { Router } from '@angular/router';
import { HealthSystemValidationResponse } from '../models/health-system-view/health-system';


@Injectable({
  providedIn: 'root'
})
export class UserService extends BaseMhService {


  constructor(protected http: HttpClient, protected store: Store<{ userState: UserState }>
    , private b2cLoginService: B2cLoginService, private router: Router) {
    super(http, store);
  }

  public initUser(): Observable<UiUser> {

    return new Observable<UiUser>(subscriber => {

      let user: UiUser = new UiUser();
      const jwtHelper = new JwtHelperService();

      const b2cToken = this.b2cLoginService.getB2cIdToken();

      if (b2cToken) {

        user.jwtString = b2cToken;

        if (!jwtHelper.isTokenExpired(b2cToken)) {
          this.parseB2cJWT(user);
          this.createCookie('token', user.jwtString, jwtHelper.getTokenExpirationDate(user.jwtString));

          if (user.userEmail) {
            subscriber.next(user);
          }
        }
      }

      if (!user || !user.userEmail) {
        subscriber.error('No Valid Token');
      }

      subscriber.complete();

    });
  }

  public getUserSettings() {
    return this.http
      .get(environment.apiBaseUrl + environment.UAMUserApiEndpoint + 'v1/settings', { headers: this.getHeaders() })
      .pipe(
        map((response: UserSettings) => response),
        catchError(error => this.errorHandler({ error, operation: "Error while Retrieving User Settings" }))
      );
  }

  public updateUserSettings(saveData: SaveUserSettings) {
    return this.http
      .patch(environment.apiBaseUrl + environment.UAMUserApiEndpoint + 'v1/settings', saveData, { headers: this.getHeaders() })
      .pipe(
        map((response: SubmitResponse) => {
          if (response.status === '200')
            return saveData;
          else
            throwError(response.message);
        }),
        catchError(error => this.errorHandler({ error, operation: "Error while Updating User Settings" }))
      );
  }

  public updateUser340BPopup(displayDate: number) {
    let userSetting: SaveUserSettings
    return this.http
      .post(environment.apiBaseUrl + environment.UAMUserApiEndpoint + 'v1/settings', displayDate, { headers: this.getHeaders() })
      .pipe(
        map((response: SubmitResponse) => {
          if (response.status === '200')
            return userSetting;
          else
            throwError(response.message);
        }),
        catchError(error => this.errorHandler({ error, operation: "Error while Updating User 340B Popup Display date" }))
      );
  }

  public getUserSession() {
    return this.http
      .get(environment.apiBaseUrl +  environment.UAMUserApiEndpoint + 'v1/pharmacysession', { headers: this.getHeaders() })
      .pipe(
        map((response: UserSession) => response),
        catchError(error => this.errorHandler({ error, operation: "Error while Retrieving User Session" }))
      );
  }

  public updateUserSession(sessionData: SaveUserSession) {
    return this.http
      .put(environment.apiBaseUrl +  environment.UAMUserApiEndpoint + 'v1/pharmacysession', sessionData, { headers: this.getHeaders() })
      .pipe(
        map((response: UserSession) => response),
        catchError(error => this.errorHandler({ error, operation: "Error while Updating User Session" }))
      );
  }
  public updateRecalculate(sessionData: SaveUserSession) {
    return this.http
      .post(environment.apiBaseUrl +  environment.UAMUserApiEndpoint + 'v1/pharmacysession/recalculate', sessionData, { headers: this.getHeaders() })
      .pipe(
        map((response: UserSession) => response),
        catchError(error => this.errorHandler({ error, operation: "Error while Updating Recalculate" }))
      );
  }
  public updateUserApplication(appId: number) {
    return this.http
      .put(environment.apiBaseUrl
        + environment.UAMUserApiEndpoint + 'v1/settings/uamapplication/' + appId, null, { headers: this.getHeaders() })
      .pipe(
        map((response: SubmitResponse) => {
          if (response.status === '200')
            return appId;
          else
            throwError(response.message);
        }),
        catchError(error => this.errorHandler({ error, operation: "Error while Updating the User's Application" }))
      );
  }
   
  public getAllPharmacies(options: PharmacyGetOptions) {
    const settings: UserSettings = this.getStoreUserSettings();
    options.applicationId = settings.lastApp;

    let params: HttpParams = new HttpParams();
    if (options.pageNumber)
      params = params.append('pageNumber', '' + options.pageNumber);
    if (options.rowsPerPage)
      params = params.append('rowsPerPage', '' + options.rowsPerPage);
    if (options.searchText)
      params = params.append('searchText', options.searchText);
    if (options.sortColumn)
      params = params.append('sortColumn', options.sortColumn);
    if (options.sortOrder)
      params = params.append('sortOrder', options.sortOrder);

    const url = environment.apiBaseUrl + environment.UAMUserApiEndpoint + 'v1/pharmacysession/allpharmacies';
    return this.http
      .get(url, { headers: this.getHeaders(), params })
      .pipe(
        map((response: Pharmacy[]) => response),
        //tap(returnedData => console.log(returnedData)),
        catchError(error => this.errorHandler({ error, operation: "Error while Retrieving All Pharmacies for user" }))
      );
  }

  public getUserEmailSettings() {
    const session: UserSession = this.getStoreUserSession();
    return this.http
      .get(environment.apiBaseUrl + environment.UAMUserApiEndpoint + 'v1/email-settings/get/pid/' + session.pid, { headers: this.getHeaders() })
      .pipe(
        map((response: UserEmailSettings) => response),
        catchError(error => this.errorHandler({ error, operation: "Error while Retrieving User Email Settings" }))        
      );
  }

  public updateUserEmailSettings(saveData: UserEmailSettings) {
    const session: UserSession = this.getStoreUserSession();
    return this.http
      .patch(environment.apiBaseUrl + environment.UAMUserApiEndpoint + 'v1/email-settings/update/pid/' + session.pid, saveData, { headers: this.getHeaders() })
      .pipe(
        map((response: SubmitResponse) => {
          if (response.status === '200')
            return saveData;
          else
            throwError(response.message);
        }),
        catchError(error => this.errorHandler({ error, operation: "Error while Updating User Email Settings" }))
      );
  }

  public updateUserQuickAccessPage(saveUserQuickAccess: SaveUserQuickAccessPage) {
    return this.http
      .patch(environment.apiBaseUrl
        +  environment.UAMUserApiEndpoint + 'v1/settings/update-quick-access-page', saveUserQuickAccess, { headers: this.getHeaders() })
      .pipe(
        map((response: SubmitResponse) => {
          if (response.status === '200') {
            return response.message;
          } else {
            throwError(response.message);
          }
        }),
        catchError(error => this.errorHandler({ error, operation: "Error while Updating User Quick Access Page" }))
      );

  }

  public userTrackHistory_Insert(saveUsersTrackHistory: SaveUsersTrackHistory) {
    return this.http
      .post(environment.apiBaseUrl + environment.UAMUserApiEndpoint + 'v1/settings/insert-user-track-history', saveUsersTrackHistory, { headers: this.getHeaders() })
      .pipe(
        map((response: SubmitResponse) => {
          if (response.status === '200') {
            return response.message
          } else {
            throwError(response.message)
          }
        }),
        catchError(error => this.errorHandler({ error, operation: "Error while Inserting User Track History Record" }))
      );
  }

  public updateUserClass(saveUserClass: SaveUserClass) {
    return this.http
    .patch(environment.apiBaseUrl + environment.UAMUserApiEndpoint + 'v1/settings/update-user-class',
          saveUserClass, {headers: this.getHeaders()})
    .pipe(
      map((response: SubmitResponse) => {
        if(response != null) {
          return saveUserClass;
        }
      }),
      catchError(error => this.errorHandler({ error, operation: "Error while Updating User Class" }))
    );
  }

  public getPageId(application: Application) {
    const userSession: UiUser = this.getStoreUiUser();
    const applicationId = application.appID;
    return this.http
      .get(environment.apiBaseUrl + environment.UAMUserApiEndpoint + 'v1/settings/get-page-id/appId/' + applicationId, { headers: this.getHeaders() })
      .pipe(
        map((response: PageInfo) => response),
        catchError(error => this.errorHandler({ error, operation: "Error while Retrieving Page Id for Application" }))
      );
  }

  public getSubmittedSupportTickets(options: TableGetOptions) {
    const settings: UserSettings = this.getStoreUserSettings();
    options.applicationId = settings.lastApp;
    let params: HttpParams = new HttpParams();
    if (options.pageNumber)
      params = params.append('pageNumber', '' + options.pageNumber);
    if (options.rowsPerPage)
      params = params.append('rowsPerPage', '' + options.rowsPerPage);
    if (options.searchText)
      params = params.append('searchText', options.searchText);
    if (options.sortColumn)
      params = params.append('sortColumn', options.sortColumn);
    if (options.sortOrder)
      params = params.append('sortOrder', options.sortOrder);

    const url = environment.apiBaseUrl + environment.UAMUserApiEndpoint + 'v1/submitted-tickets/get-ticket-list';
    return this.http
      .get(url, { headers: this.getHeaders(), params })
      .pipe(
        map((response: SubmittedSupportTicket[]) => response),
        catchError(error => this.errorHandler({ error, operation: "Error while Retrieving Submitted Support Tickets" }))
      );
  }

  public updateUserMHISettings(saveUserMHISettings: SaveUserMHISettings) {
    return this.http
      .patch(environment.apiBaseUrl + environment.UAMUserApiEndpoint + 'v1/users-mhi-settings',
        saveUserMHISettings, { headers: this.getHeaders() })
      .pipe(
        map((response: SubmitResponse) => {
          if (response.status === '200') {
            return saveUserMHISettings;
          } else {
            throwError(response.message)
          }
        }),
        catchError(error => this.errorHandler({ error, operation: "Error while Updating User MHI Settings" }))
      );
  }

  public getUserPublicationSelection() {
    return this.http
      .get(environment.apiBaseUrl + environment.UAMUserApiEndpoint + 'v1/users-publication-selection', { headers: this.getHeaders() })
      .pipe(
        map((response: UsersPublicationSelection[]) => response.sort((a, b) => (a.name > b.name) ? 1 : -1)),
        catchError(error => this.errorHandler({ error, operation: "Error while Retrieving User Publication Selection" }))
      );
  }

  public updateUserPublicationSelection(saveUsersPublicationSelections: SaveUserPubicationSelection[]) {
    return this.http
      .put(environment.apiBaseUrl + environment.UAMUserApiEndpoint + 'v1/users-publication-selection', saveUsersPublicationSelections,
        { headers: this.getHeaders() })
      .pipe(
        map((response: SubmitResponse) => {
          if (response.status === '200') {
            return response
          } else {
            throwError(response.message)
          }
        }),
        catchError(error => this.errorHandler({ error, operation: "Error while Updating User Publication Selection" }))
      );
  }

  // Displaying Notification
  public getNotificationList() {
    return this.http
      .get(environment.apiBaseUrl + environment.UAMUserApiEndpoint + 'v1/user-notifications', { headers: this.getHeaders() })
      .pipe(
        map((response: NotificationItem[]) => response),
        catchError(error => this.errorHandler({ error, operation: "Error while Retrieving Notification List" }))
      );
  }

  // update the NotifictaionList
  public updateNotificationList() {
    return this.http
      .patch(environment.apiBaseUrl + environment.UAMUserApiEndpoint + 'v1/user-notifications', {}, { headers: this.getHeaders() })
      .pipe(
        map((response: SubmitResponse) => {
          if (response.status === '200') {
            let clearNotifications = new UpdateNotification();
            clearNotifications.updatedNotificationList = [];
            return clearNotifications;
          } else {
            throwError(response.message);
          }
        }),
        catchError(error => this.errorHandler({ error, operation: "Error while Updating Notification List" }))
      );
  }

  // update the NotifictaionList selected
  public updateSelectedNotificationList(notificationList: NotificationItem[], nid: number) {
    return this.http
      .patch(environment.apiBaseUrl + environment.UAMUserApiEndpoint + 'v1/user-notifications/update/NoteSentID/' + nid, {}, { headers: this.getHeaders() })
      .pipe(
        map((response: SubmitResponse) => {
          if (response.status === '200') {
            const updateitem = new UpdateNotification();
            updateitem.updateNoteSentID = nid;
            updateitem.updatedNotificationList = notificationList.filter(n => n.noteSentID != nid);
            return updateitem;
          } else {
            throwError(response.message)
          }
        }),
        catchError(error => this.errorHandler({ error, operation: "Error while Updating Selected Item in Notification List" }))
      );
  }

  // History Displaying Notification
  public getNotificationHistoryList() {
    return this.http
      .get(environment.apiBaseUrl + environment.UAMUserApiEndpoint + 'v1/user-notifications/HistoryNotification', { headers: this.getHeaders() })
      .pipe(
        map((response: NotificationItemHistory[]) => response),
        catchError(error => this.errorHandler({ error, operation: "Error while Retrieving Notification History" }))
      );
  }

  public getUserAlternateLoginName(options: TableGetOptions) {
    let params: HttpParams = new HttpParams();
    if (options.pageNumber)
      params = params.append('pageNumber', '' + options.pageNumber);
    if (options.rowsPerPage)
      params = params.append('rowsPerPage', '' + options.rowsPerPage);
    if (options.searchText)
      params = params.append('searchText', options.searchText);
    if (options.sortColumn)
      params = params.append('sortColumn', options.sortColumn);
    if (options.sortOrder)
      params = params.append('sortOrder', options.sortOrder);

    const url = environment.apiBaseUrl + environment.UAMUserApiEndpoint + 'v1/user-alternate-logins';
    return this.http
      .get(url, { headers: this.getHeaders(), params })
      .pipe(
        map((response: UserAlternateLoginName[]) => response),
        catchError(error => this.errorHandler({ error, operation: "Error while Retrieving Alternate Login Name" }))
      );
  }

  public getVendorList() {
    return this.http
      .get(environment.apiBaseUrl + environment.UAMUserApiEndpoint + 'v1/vendor/get-vendors-list', { headers: this.getHeaders() })
      .pipe(
        map((response: Vendor[]) => response),
        catchError(error => this.errorHandler({ error, operation: "Error while Retrieving Vendor List" }))
      );
  }

  public AddNewUserAlternateLogin(newUserAlternateLogin: AddUserAlternateLoginName) {
    return this.http
      .post(environment.apiBaseUrl + environment.UAMUserApiEndpoint + 'v1/users-vendors-login-name/add-new-users-vendors-login-name', newUserAlternateLogin,
        { headers: this.getHeaders() })
      .pipe(map((response: SubmitResponse) => response),
        catchError(error => this.errorHandler({ error, operation: "Error while Adding New USer Alternate Login" }))
      );
  }

  public getCSGID() {
    return this.http
      .get(environment.apiBaseUrl + environment.UAMUserApiEndpoint + 'v1/gateway-dashboard/user-csgid', { headers: this.getHeaders() })
      .pipe(
        map((response: CSGID[]) => response),
        catchError(error => this.errorHandler({ error, operation: "Error while getting CSGIds" }))
      );
  }

  public getLookerUrl(csgid: string, dashboardType: string) {
    return this.http
      .get(environment.apiBaseUrl + 'user/v1/gateway-dashboard/looker-' + dashboardType + '-url/'+ csgid , { headers: this.getHeaders() })
      .pipe(
        map((response: LookerUrl) => response),
        catchError(error => this.errorHandler({ error, operation: "Error while getting Looker Url based on CSGID" }))
      );
  }

  public getNDCAPharmacy() {
    let pid = this.getStoreUserSession().pid;
    return this.http
      .get(environment.apiBaseUrl + environment.UAMUserApiEndpoint + 'v1/pharmacysession/ndcapharmacy/' + pid, { headers: this.getHeaders() })
      .pipe(
        map((response: NDCAPharmacy) => response),
        catchError(error => this.errorHandler({ error, operation: "Error while Retrieving NDCA Pharmacy Info" }))
      );
  }

  public resetPassword(email: string) {
    return this.http.post<any>(environment.apiBaseUrl + '/user/v1/login/resetpassword', { email }, { headers: this.getHeaders() })
      .pipe(
        map((res: Response) => res),
        catchError(error => this.errorHandler({ error, operation: "Error while Resetting Password" }))
      );
  }

  public getDisclaimer(pageId: number) {
    return this.http
      .get(environment.apiBaseUrl + environment.UAMUserApiEndpoint + 'v1/disclaimers/' + pageId, { headers: this.getHeaders() })
      .pipe(
        map((response: DisclaimerItem) => response),
        catchError(error => this.errorHandler({ error, operation: "Error while Retrieving User Disclaimer" }))
      );
  }

  public acknowledgeDisclaimer(disclaimerId: number) {
    const { uid: userId }: UserSession = this.getStoreUserSession();
    let pharmacyId = this.getStoreUserSession().pid;
    return this.http
      .put(environment.apiBaseUrl + environment.UAMUserApiEndpoint + 'v1/disclaimers', { userId, disclaimerId,pharmacyId }, { headers: this.getHeaders() })
      .pipe(
        map((response: SubmitResponse) => response),
        catchError(error => this.errorHandler({ error, operation: "Error while acknowledging User Disclaimer" }))
      );
  }

  public getMck340BIAccess() {
    let pid = this.getStoreUserSession().pid;
    return this.http
      .get(environment.apiBaseUrl + environment.UAMUserApiEndpoint + 'v1/login/mCk340BIAccess/' + pid, { headers: this.getHeaders() })
      .pipe(
        map((response: boolean) => response),
        catchError(error => this.errorHandler({ error, operation: "Error while Retrieving Access 340BI Info" }))
      );
  }

  public getUserLogout() {
    return this.http
      .get(environment.apiBaseUrl + environment.UAMUserApiEndpoint + 'v1/login/logout', { headers: this.getHeaders() })
      .pipe(
        map((response: SubmitResponse) => response),
        catchError(error => this.errorHandler({ error, operation: "Error while logging out." }))
      );
  }

  public getCorporateParentValidationStatus() {
    const session: UserSession = this.getStoreUserSession();

    return this.http
      .get(environment.apiBaseUrl + 'v1/CorporateParent/GetHospitalSystemViewValidationStatus?CorpId=' + session.corpID, { headers: this.getHeaders() })
      .pipe(
        map((response: HealthSystemValidationResponse) => response),
        catchError(error => this.errorHandler({ error, operation: "Error while retrieving Corporate Parent Validation Status data" }))
      );

  }
  
  public regenerateUserToken(request: UserTokenRequest) {
    let headers: HttpHeaders;
    let authEndpoint: string;
    const userSession: UserSession = this.getStoreUserSession();
    // use b2c header endpoint for first token generation, auth header for refreshing token
    if (userSession == null) {
      headers = this.getIdHeaders();
      authEndpoint = '/regeneratetoken';
    }
    else {
      headers = this.getHeaders();
      authEndpoint = '/refreshtoken'
    }
    return this.http
      .post(environment.apiBaseUrl + environment.UAMUserApiEndpoint + 'v1/login' + authEndpoint, request, { headers: headers })
      .pipe(
        map((response: UserAccessToken) => response),
        catchError(error => this.authErrorHandler({ error, operation: "Error while generating an authorization token" }))
      );
  }

  public regenerateTokenOnAppChange(pid: number){
    let session = this.getStoreUserSession();
    let clientId = session.corpID > 0 ? session.corpID : session.chainGroupId;
    let request: UserTokenRequest = { pid: pid ?? session.pid, clientId, isCorp: session.corpID > 0 }
    return this.http
      .post(environment.apiBaseUrl + environment.UAMUserApiEndpoint + 'v1/login/refreshToken', request, { headers: this.getHeaders() })
      .pipe(
        map((response: UserAccessToken) => response),
        catchError(error => this.errorHandler({ error, operation: "Error refreshing user token" }))
      );
  }

  public getUserPharmacySession(){
    return this.http
      .get(environment.apiBaseUrl + environment.UAMUserApiEndpoint + 'v1/pharmacysession/uam', { headers: this.getHeaders() })
      .pipe(
        map((response: UserSession) => response),
        catchError(error => this.errorHandler({ error, operation: "Error while Retrieving User Session" }))
      );
  }

  public getUAMUserApplicationPages(){
    return this.http
      .get(environment.apiBaseUrl +  environment.UAMUserApiEndpoint + 'v1/login/userapps', { headers: this.getHeaders() })
      .pipe(
        map((response: UserApplicationInfo) => response),
        catchError(error => this.errorHandler({ error, operation: "Error while Retrieving User Application pages" }))
      );
  }

  public getUAMPharmacyList(searchText: string, appChange: number) {
    let params: HttpParams = new HttpParams();
    if (searchText){
      params = params.append('searchText', searchText);
    }
    return this.http
      .get(environment.apiBaseUrl + environment.UAMUserApiEndpoint + 'v1/pharmacysession/pharmacylist/' + false + '/appChange/' + appChange, { headers: this.getHeaders(), params })
      .pipe(
        map((response: UAMPharmacy[]) => response),
        catchError(error => this.errorHandler({ error, operation: "Error while Retrieving User's Pharmacies" }))
      );
  }

  public getUAMAllPharmacyList() {
    return this.http
      .get(environment.apiBaseUrl + environment.UAMUserApiEndpoint + 'v1/pharmacysession/pharmacylist/' + true + '/appChange/0', { headers: this.getHeaders() })
      .pipe(
        map((response: UAMPharmacy[]) => response),
        catchError(error => this.errorHandler({ error, operation: "Error while Retrieving User's Pharmacies" }))
      );
  }

  private parseJWT(user: UiUser) {
    user.jwtToken = jwt_decode(user.jwtString);
    user.userId = user.jwtToken.nameid;
    user.userEmail = user.jwtToken.email;
    user.expiration = new Date(user.jwtToken.exp * 1000);
  }

  private parseB2cJWT(user: UiUser) {
    user.jwtToken = jwt_decode(user.jwtString);
    user.userId = user.jwtToken.uid;
    user.userEmail = user.jwtToken.email;
    user.expiration = new Date(user.jwtToken.exp * 1000);
  }

  public createCookie(name, value, expiration) {
    let expires;
    if (expiration) {
      expires = '; expires=' + expiration.toUTCString();
    } else {
      expires = '';
    }
    document.cookie = name + '=' + value + expires + '; path=/';
  }

  public readCookie(name): string {
    const nameEQ = name + '=';
    const ca = document.cookie.split(';');
    // for (let i = 0; i < ca.length; i++) {
    for (let c of ca) {
      // let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1, c.length);
      }
      if (c.indexOf(nameEQ) === 0) {
        return c.substring(nameEQ.length, c.length);
      }
    }
    return null;
  }

  public getHelpApplicationUrl() {
    return this.http
      .get(environment.apiBaseUrl + 'user/v1/login/helpUrl', { headers: this.getHeaders() })
      .pipe(
        map((response: UserHelpUrl) => response),
        catchError(error => this.authErrorHandler({ error, operation: "Error while getting the Help Url for Selected Application" }))
      );
  }

  protected authErrorHandler({ error, operation }: { error: any; operation: string; }): Observable<never> {
    let message = operation;//'Something bad happened; please try again later.';

    if (error instanceof HttpErrorResponse) {
      // if service is unavailable, app is in maintenance mode and we need to redirect to login
      if (([401, 403, 500, 503]).includes(error.status)){
        this.getUserLogout();
        this.b2cLoginService.logout();
        return throwError(operation + " : " + message);
      }
      else{
        message = error.message + " : " + JSON.stringify(error.error);
        if (error.error)
          message = error.error.message != null ? error.error.message : message;
        else if (error.error && error.error.error)
          message = error.error.error.message != null ? error.error.error.message : message;
        else
          message = error.message != null ? error.message : message
      } 
    }
    else if (error instanceof Error) {
      message = error.message != null ? error.message : message;
    } else if (error instanceof ErrorEvent) {
      message = error.error.message != null ? error.error.message : message;
    } else {
      message = `Backend returned code ${error}`;
    }
    return throwError(operation + " : " + message);

  }
}
