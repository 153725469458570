import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';

import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { Store, StoreModule } from '@ngrx/store';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { UserService } from 'src/app/service/user.service';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { TextMaskModule } from 'angular2-text-mask';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
// Navbar Settings, Help, and User Settings Buttons
import { TopNavComponent } from './shared/top-nav/top-nav.component';
import { CancelButtonComponent } from './shared/top-nav/cancel-button/cancel-button.component';
import { MatDialogModule } from '@angular/material/dialog';
import { GeneralSettingsComponent } from './shared/top-nav/navbar-buttons/general-settings/general-settings.component';
import { AboutPopupComponent } from './shared/top-nav/navbar-buttons/help-button/about-popup/about-popup.component';
import { SubmitTicketPopupComponent } from './shared/top-nav/navbar-buttons/help-button/submit-ticket-popup/submit-ticket-popup.component';
import { ConfirmDialogComponent } from './shared/confirm-dialog/confirm-dialog.component';
import { HelpGuideComponent } from './shared/top-nav/navbar-buttons/help-button/help-guide/help-guide.component';

// Application Navbar and LeftMenu
import { HorizontalMenuComponent } from './shared/horizontal-menu/horizontal-menu.component'
import { LeftMenuComponent } from './shared/left-menu/left-menu.component';
import { DisclaimerComponent } from './shared/disclaimer/disclaimer.component';

// tslint:disable-next-line:max-line-length

import { applicationsReducer } from './store/application-list.reducer';
import { uiSteteReducer } from './store/ui-state.reducer';
import { userActionsReducer } from './store/user.reducer';
import { shellActionsReducer } from './store/shell.reducer';
import { automatedReferralReducer } from './store/automated-referral.reducer';
import { analyticsStateReducer } from './store/analytics.reducer';
import { OrderActionsReducer } from './store/order.reducer';
import { educationStateReducer } from './store/education.reducer';
import { qualificationFilterStateReducer } from './store/qualification-filter.reducer';

import { PidselectorComponent } from './shared/pidselector/pidselector.component';
import { MhMenuGroupDirective } from './shared/navbar-buttons/mh-menu-group.directive';
import { NavbarButtonsComponent } from './shared/navbar-buttons/navbar-buttons.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MaterialModule } from './material.module';

import { MatTableComponent } from './shared/mat-table/mat-table.component';
import { EffectsModule } from '@ngrx/effects';
import { UserStoreEffects } from './store/user.effect';
import { ShellService } from './service/shell.service';
import { OrderService } from './service/order.service';
import { ShellStoreEffects } from './store/shell.effect';
import { EmailSettingsComponent } from './shared/top-nav/navbar-buttons/user-profile-button/email-settings/email-settings.component';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from 'src/environments/environment';
import { NgxMaskModule } from 'ngx-mask';
import { OrderEffects } from './store/order.effects';
import { ApplicationStoreEffects } from './store/application-list.effects';
import { ReferralStoreEffects } from './store/automated-referral.effects';
import { AnalyticsStoreEffects } from './store/analytics.effects';
import { MessageDialogComponent } from './shared/message-dialog/message-dialog.component';

import { AppInsightsService } from './service/appinsights.service';
import { ViewProfileComponent } from './shared/top-nav/navbar-buttons/user-profile-button/view-profile/view-profile.component';
import { UserPharmaciesTableComponent } from './shared/top-nav/navbar-buttons/user-profile-button/user-pharmacies-table/user-pharmacies-table.component';
import { UserNotificationSettingsTabComponent } from './shared/top-nav/navbar-buttons/user-profile-button/user-notification-settings-tab/user-notification-settings-tab.component';
import { UserSubmittedTicketsTableComponent } from './shared/top-nav/navbar-buttons/user-profile-button/user-submitted-tickets-table/user-submitted-tickets-table.component';
import { UserAlternateLoginNameTableComponent } from './shared/top-nav/navbar-buttons/user-profile-button/user-alternate-login-name-table/user-alternate-login-name-table.component';
import { AddNewUserLoginNameDialogComponent } from './shared/top-nav/navbar-buttons/user-profile-button/add-new-user-login-name-dialog/add-new-user-login-name-dialog.component';
import { NotificationHistoryDialogComponent } from './shared/top-nav/navbar-buttons/bell-button/notification-history-dialog/notification-history-dialog.component';
import { TimeoutPopupComponent } from './shared/timeout-popup/timeout-popup.component';
import { ResetPasswordComponent } from './shared/top-nav/navbar-buttons/user-profile-button/reset-password/reset-password.component';
import { CookieService } from 'ngx-cookie-service';

import { MsalBroadcastService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG } from '@azure/msal-angular';

import { MSALGuardConfigFactory, MSALInstanceFactory, MSALInterceptorConfigFactory } from './app-config';
import { MsalGuard, MsalInterceptor, MsalModule, MsalService } from '@azure/msal-angular';
import { CommonModule } from '@angular/common';
import { MatTableModule } from '@angular/material/table';
import { Validate340BService } from 'src/app/service/validate340b.service';
import { Validate340BEffects } from './store/validate340b.effects';
import { Validate340BActionsReducer } from './store/validate340b.reducer';
import { AuthTokenRefresherComponent } from './shared/auth-token-refresher/auth-token-refresher.component';
import { prescriberListReducer } from './store/prescriber-list.reducer';
import { PrescriberStoreEffects } from './store/prescriber-list.effects';
import { FileUploadComponent } from './shared/file-upload/file-upload.component';
import { DatePipe } from '@angular/common';
import { HL7Reducer } from './store/hl7.reducer';
import { HL7StoreEffects } from './store/hl7.effects';
import { HL7Service } from './service/hl7.service';

import { PAPService } from 'src/app/service/pap.service';
import { PAPStoreEffects } from './store/pap.effects';
import { PAPReducer } from './store/pap.reducer';
import { EducationEffects } from './store/education.effects';
import { QualificationFilterEffects } from './store/qualification-filter.effects';
import { QualificationFilterService } from './service/qualification-filter.service';
import { corpIdListReducer } from './store/corpid-list/corpid-list.reducer';
import { hIdListReducer } from './store/hid-list/hid-list.reducer';
import { corpIdStoreEffects } from './store/corpid-list/corpid-list.effects';
import { hIdStoreEffects } from './store/hid-list/hid-list.effects';
import { HospitalListReducer } from './store/hid-pharamcy/hospital-list/hospital-list.reducer';
import { hospitalStoreEffects } from './store/hid-pharamcy/hospital-list/hospital-list.effects';
import { PharmacyListReducer } from './store/hid-pharamcy/pharmacy-list/pharmacy-list.reducer';
import { PharmacyStoreEffects } from './store/hid-pharamcy/pharmacy-list/pharmacy-list.effects';
import { branchCodeListReducer } from './store/branchcode-list/branchcode-list.reducer';
import { branchCodeStoreEffects } from './store/branchcode-list/branchcode-list.effects';
import { CustomPipesModule } from './shared/pipes/custompipes.module';
import { HealthSystemViewStoreEffects } from './store/health-system-view/health-system-view.effects';
import { MatExpansionModule } from '@angular/material/expansion';
import { QualRulesStoreEffects } from './store/qual-rules/qual-rules.effects';
import { qualRulesListReducer } from './store/qual-rules/qual-rules.reducer';
import { QualRulesListService } from './service/qual-rules.service';
import { SharedDataService } from './service/shared-data.service';
import { UAMEffects } from './store/uam/uam.effects';
import { uamStateReducer } from './store/uam/uam.reducer';
import { BlankPageComponent } from './shared/blank-page/blank-page.component';
import { ClientIDSelectionComponent } from './shared/clientId-selection/clientID-selection.component';
import { AccessDeniedComponent } from './shared/access-denied-page/access-denied.component';
import { CsgidSelectorComponent } from './modules/340b-gateway/csgid-selector/csgid-selector.component'
import { GatewayStoreEffects } from './store/gateway/gateway.effects';
import { gatewayReducer } from './store/gateway/gateway.reducer';
import { PendingPackagesEffects } from './store/pending-packages/pending-packages.effects';
import { PendingPackageReducer } from './store/pending-packages/pending-packages..reducer';


@NgModule({
  declarations: [
    AppComponent,
    BlankPageComponent,
    HorizontalMenuComponent,
    LeftMenuComponent,
    DisclaimerComponent,
    MatTableComponent,
    TopNavComponent,
    AboutPopupComponent,
    SubmitTicketPopupComponent,
    PidselectorComponent,
    GeneralSettingsComponent,
    CancelButtonComponent,
    MhMenuGroupDirective,
    HelpGuideComponent,
    NavbarButtonsComponent,
    EmailSettingsComponent,
    ViewProfileComponent,
    UserPharmaciesTableComponent,
    MessageDialogComponent,
    UserNotificationSettingsTabComponent,
    UserSubmittedTicketsTableComponent,
    UserAlternateLoginNameTableComponent,
    AddNewUserLoginNameDialogComponent,
    UserSubmittedTicketsTableComponent,
    NotificationHistoryDialogComponent,
    TimeoutPopupComponent,
    ResetPasswordComponent,
    AuthTokenRefresherComponent,
    ConfirmDialogComponent,
    FileUploadComponent,
    ClientIDSelectionComponent,
    AccessDeniedComponent,
    CsgidSelectorComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    // tslint:disable-next-line:max-line-length
    StoreModule.forRoot({
      applications: applicationsReducer,
      uiState: uiSteteReducer,
      userState: userActionsReducer,
      shellState: shellActionsReducer,
      orderState: OrderActionsReducer,
      referralState: automatedReferralReducer,
      analyticsState: analyticsStateReducer,
      validate340BState: Validate340BActionsReducer,
      papState: PAPReducer,
      prescriberState: prescriberListReducer,
      educationState: educationStateReducer,
      corpIdState: corpIdListReducer,
      hIdState: hIdListReducer,
      hospitalListState: HospitalListReducer,
      pharmacyListState: PharmacyListReducer,
      branchCodeIdState: branchCodeListReducer,
      HealthSystemViewState: corpIdListReducer,
      HL7State: HL7Reducer,
      QualificationFilterState: qualificationFilterStateReducer,
      qualRulesState: qualRulesListReducer,
      uamState: uamStateReducer,
      gatewayState: gatewayReducer,
      pendingPackagesState: PendingPackageReducer
    }),
    EffectsModule.forRoot(
      [UserStoreEffects,
        ShellStoreEffects,
        OrderEffects,
        ApplicationStoreEffects,
        ReferralStoreEffects,
        AnalyticsStoreEffects,
        Validate340BEffects,
        PAPStoreEffects,
        PrescriberStoreEffects,
        PrescriberStoreEffects,
        corpIdStoreEffects,
        hIdStoreEffects,
        hospitalStoreEffects,
        PharmacyStoreEffects,
        branchCodeStoreEffects,
        EducationEffects,
        HealthSystemViewStoreEffects,
        QualificationFilterEffects,
        QualRulesStoreEffects,
        HL7StoreEffects,
        UAMEffects,
        GatewayStoreEffects,
        PendingPackagesEffects

      ]),
    AngularSvgIconModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    TextMaskModule,
    BrowserAnimationsModule,
    MatTooltipModule,
    NgxMaskModule,
    MaterialModule,
    DragDropModule,
    MatDialogModule,
    MatTableModule,
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: environment.production, // Restrict extension to log-only mode
    }),
    MsalModule,
    CommonModule,
    CustomPipesModule
  ],
  providers: [
    UserService,
    ShellService,
    OrderService,
    Store,
    AppInsightsService,
    Title,
    UserSubmittedTicketsTableComponent,
    Validate340BService,
    DatePipe,
    PAPService,
    HL7Service,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    MsalService,
    CookieService,
    MsalGuard,
    MsalBroadcastService,
    QualificationFilterService,
    QualRulesListService,
    SharedDataService,
  ],
  bootstrap: [AppComponent]
})

export class AppModule {
}
