import { ErrorResponse } from "../models/prescriber-list/error-response";
import { ClientList, PortRequestList } from "../models/hl7";
import { HL7ActionTypes } from "./hl7.actions";

//State to hold all HL7 List data
export interface HL7State 
{
  HL7ClientList: ClientList[],
  HL7NewClientList: ClientList[],
  HL7PortRequestList: PortRequestList[],
}

//Default initial state
export const initialState =
{
  HL7ClientList: null,
  HL7NewClientList: null,
  HL7PortRequestList: null,
}

export function HL7Reducer(state = initialState, action: any): HL7State {
  switch (action.type) {
    case HL7ActionTypes.GET_HL7CLIENT_LIST_RES: {
      return { ...state, HL7ClientList: action.HL7ClientList }
    }
    case HL7ActionTypes.GET_HL7PORTREQUEST_LIST_RES: {
      return { ...state, HL7PortRequestList: action.HL7ClientList }
    }
    default: {
      return state;
    }
  }
}
