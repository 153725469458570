import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of as observableOf } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { GetPAPCoveredEntityList, GetPAPCoveredEntityResults, GetPAPCoveredEntityError, 
          GetPAPBillingSetupList, GetPAPBillingSetupResults, GetPAPBillingSetupError, 
          GetPAPBillingSetupBillToHIDList, GetPAPBillingSetupBillToHIDResults, GetPAPBillingSetupBillToHIDError, 
          GetPAPBillingSetupBillingTypesList, GetPAPBillingSetupBillingTypesResults, GetPAPBillingSetupBillingTypesError, 
          UpdatePAPBillingTypeBillingHID, UpdatePAPBillingTypeBillingHIDResults, UpdatePAPBillingTypeBillingHIDError,
          GetPAPCorpGroupSummaryList, GetPAPCorpGroupSummaryResults, GetPAPCorpGroupSummaryError, 
          GetPAPCorpGroupDetails, GetPAPCorpGroupDetailsResults, GetPAPCorpGroupDetailsError, 
          GetPapCorporateGroupHIDs, GetPapCorporateGroupHIDsResults, GetPapCorporateGroupHIDsError, 
          UpdatePAPCorpGroupDetails, UpdatePAPCorpGroupDetailsError, UpdatePAPCorpGroupDetailsResults,
          PAPActionTypes,
          GetPapCorporateGroupMemberHIDs, GetPapCorporateGroupMemberHIDsResults, GetPapCorporateGroupMemberHIDsError,
          InsertCorpMemberHIDsInfo, InsertCorpMemberHIDsInfoError, InsertCorpMemberHIDsInfoResults, AddPAPCorpGroupDetails, AddPAPCorpGroupDetailsResults, AddPAPCorpGroupDetailsError, UpdateCorpMemberHIDsInfo, UpdateCorpMemberHIDsInfoResults, UpdateCorpMemberHIDsInfoError,
        } from './pap.actions';
import { PAPService } from 'src/app/service/pap.service';

@Injectable()
export class PAPStoreEffects {

  constructor(private papService: PAPService, private actions$: Actions, private store: Store<{}>) {
  }

  GetPAPCoveredEntityListEffect$ = createEffect(() => this.actions$.pipe(
    ofType(GetPAPCoveredEntityList),
    switchMap(action =>
      this.papService.getPAPCoveredEntityList()
        .pipe(
          map(
            data => {
              return GetPAPCoveredEntityResults({ papCoveredEntityList: data })
            }
          ),
          catchError(
            error => observableOf(GetPAPCoveredEntityError({ message: error }))
          )
        )
    )
  ))



  GetPAPBillingSetupListEffect$ = createEffect(() => this.actions$.pipe(
    ofType(GetPAPBillingSetupList),
    switchMap(action =>
      this.papService.getPAPBillingSetupList(action.hid.toString())
        .pipe(
          map(
            data => {
              return GetPAPBillingSetupResults({ papBillingSetupList: data })
            }
          ),
          catchError(
            error => observableOf(GetPAPBillingSetupError({ message: error }))
          )
        )
    )
  ))



  GetPAPBillingSetupBillToHIDListEffect$ = createEffect(() => this.actions$.pipe(
    ofType(GetPAPBillingSetupBillToHIDList),
    switchMap(action =>
      this.papService.getPAPBillingSetupBillToHIDList(action.hid, action.corpGroupID)
        .pipe(
          map(
            data => {
              return GetPAPBillingSetupBillToHIDResults({ papBillingSetupBillToHIDList: data })
            }
          ),
          catchError(
            error => observableOf(GetPAPBillingSetupBillToHIDError({ message: error }))
          )
        )
    )
  ))
  


  GetPAPBillingSetupBillingTypesListEffect$ = createEffect(() => this.actions$.pipe(
    ofType(GetPAPBillingSetupBillingTypesList),
    switchMap(action =>
      this.papService.getPAPBillingSetupBillingTypesList()
        .pipe(
          map(
            data => {
              return GetPAPBillingSetupBillingTypesResults({ papBillingSetupBillingTypesList: data })
            }
          ),
          catchError(
            error => observableOf(GetPAPBillingSetupBillingTypesError({ message: error }))
          )
        )
    )
  ))

  UpdatePAPBillingHIDAndBillingType$ = createEffect(() => this.actions$.pipe(
    ofType(UpdatePAPBillingTypeBillingHID),
    switchMap(action =>
      this.papService.savePAPBillingSetup(action.saveData)
        .pipe(
          map(         
            data => {
              return UpdatePAPBillingTypeBillingHIDResults({ response: data })
            }
          ),
          catchError(
            error => observableOf(UpdatePAPBillingTypeBillingHIDError({ message: error }))
          )
        )
    )
  ))


  GetPAPCorpGroupSummaryListEffect$ = createEffect(() => this.actions$.pipe(
    ofType(GetPAPCorpGroupSummaryList),
    switchMap(action =>
      this.papService.getPAPCorpGroupSummaryList()
        .pipe(
          map(
            data => {
              return GetPAPCorpGroupSummaryResults({ papCorpGroupSummaryList: data })
            }
          ),
          catchError(
            error => observableOf(GetPAPCorpGroupSummaryError({ message: error }))
          )
        )
    )
  ))

  GetPAPCorpGroupDetailsEffect$ = createEffect(() => this.actions$.pipe(
    ofType(GetPAPCorpGroupDetails),
    switchMap(action =>
      this.papService.getPAPCorpGroupDetails(action.corpGroupID)
      .pipe(
        map(
          data => {
            return GetPAPCorpGroupDetailsResults({ papCorpGroupDetails: data})
          }
        ),
        catchError(
          error => observableOf(GetPAPCorpGroupDetailsError({ message: error }))
        )
      ))
  ))

  AddPAPCorpGroupDetailsEffect$ = createEffect(() => this.actions$.pipe(
    ofType(AddPAPCorpGroupDetails),
    switchMap(action =>
      this.papService.savePAPCorporateGroupDetails_Add(action.CorpGroupName, action.AddedBy)
      .pipe(
        map(
          data => {
            return AddPAPCorpGroupDetailsResults({ papCorpGroupDetails: data })
          }
        ),
        catchError(
          error => observableOf(AddPAPCorpGroupDetailsError({ message: error }))
        )
      ))
  ))

  UpdatePAPCorpGroupDetailsEffect$ = createEffect(() => this.actions$.pipe(
    ofType(UpdatePAPCorpGroupDetails),
    switchMap(action =>
      this.papService.savePAPCorporateGroupDetails(action.saveDetails)
      .pipe(
        map(
          data => {
            return UpdatePAPCorpGroupDetailsResults({ saveData: data })
          }
        ),
        catchError(
          error => observableOf(UpdatePAPCorpGroupDetailsError({ message: error }))
        )
      ))
  ))

  GetPAPCorpGroupHIDsEffect$ = createEffect(() => this.actions$.pipe(
    ofType(GetPapCorporateGroupHIDs),
    switchMap(action =>
      this.papService.getPAPCorporateGroupDetailMemberHIDs(action.corpGroupID)
      .pipe(
        map(
          data => {
            return GetPapCorporateGroupHIDsResults({ papCorpGroupHids: data})
          }
        ),
        catchError(
          error => observableOf(GetPapCorporateGroupHIDsError({ message: error }))
        )
      ))
  ))

  GetPAPCorpGroupMemberHIDsEffect$ = createEffect(() => this.actions$.pipe(
    ofType(GetPapCorporateGroupMemberHIDs),
    switchMap(action =>
      this.papService.getPAPCorporateGroupMemberHIDs(action.corpID)
      .pipe(
        map(
          data => {
            return GetPapCorporateGroupMemberHIDsResults({ papCorpGroupMemberHids: data})
          }
        ),
        catchError(
          error => observableOf(GetPapCorporateGroupMemberHIDsError({ message: error }))
        )
      ))
  ))

  InsertCorpMemberHIDsInfoEffect$ = createEffect(() => this.actions$.pipe(
    ofType(InsertCorpMemberHIDsInfo),
    switchMap(action =>
      this.papService.insertPAPCorporateGroupMemberHIDs(action.saveData)
        .pipe(
          map(         
            data => {
              return InsertCorpMemberHIDsInfoResults({ response: data })
            }
          ),
          catchError(
            error => observableOf(InsertCorpMemberHIDsInfoError({ message: error }))
          )
        )
    )
  ))
        
  UpdateCorpMemberHIDsInfoEffect$ = createEffect(() => this.actions$.pipe(
    ofType(UpdateCorpMemberHIDsInfo),
    switchMap(action =>
      this.papService.updatePAPCorporateGroupMemberHIDs(action.saveData)
        .pipe(
          map(         
            data => {
              return UpdateCorpMemberHIDsInfoResults({ response: data })
            }
          ),
          catchError(
            error => observableOf(UpdateCorpMemberHIDsInfoError({ message: error }))
          )
        )
    )
  ))
}
