import {
  AppListActionTypes,
  GetApplicationsResults,
  GetBackupPage,
  GetBackupPageError,
  GetBackupPageResults
} from 'src/app/store/application-list.actions';
import { ShellService } from 'src/app/service/shell.service';
import { Injectable } from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import { of as observableOf } from 'rxjs';
import {catchError, map, mapTo, switchMap} from 'rxjs/operators';
import {ErrShow} from './ui-state.actions';
import {  AppSelected, AppSelectedResults } from './application-list.actions';
import { Title } from '@angular/platform-browser';

@Injectable()
export class ApplicationStoreEffects {

  constructor(private shellService: ShellService, private actions$: Actions, private store: Store<{}>, private titleService: Title ) {
  }

  InitStateEffect$ = createEffect(() => this.actions$.pipe(
    ofType( AppSelected),
    switchMap(action => {
      this.titleService.setTitle(action.app.appName);
      return this.shellService.getApplicationPageList(action.app.appID)
        .pipe(
          map(
            data => {
              return AppSelectedResults( { applicationPageList: data } );
            }
          ),
          catchError(error => {
            return observableOf( ErrShow({ message: error} ) )
          })
        )
      }
    )
        
  ));

  GetApplicationsEffect$ = createEffect(() => this.actions$.pipe(
    ofType(AppListActionTypes.GET_APPLICATIONS),
    switchMap(action =>
      this.shellService.getApplications()
        .pipe(
          map(
            data => {
              return GetApplicationsResults( { applications: data } );
            }
          ),
          catchError(error => {
            return observableOf( ErrShow({ message: error} ) )
          })
        )
    )
  ));

  GetBackupPageEffect$ = createEffect(() => this.actions$.pipe(
    ofType(GetBackupPage),
    switchMap(action =>
      this.shellService.getBackupPage(action.appId, action.pageId)
        .pipe(
          map(
            data => {
              return GetBackupPageResults( { page: data } );
            }
          ),
          catchError(
            error => observableOf(GetBackupPageError({ message: error }))
          )
        )
    )
  ));


}
