import { Component, OnDestroy, OnInit } from '@angular/core';
import { UserService } from 'src/app/service/user.service';
import { Store } from '@ngrx/store';
import { UiState } from './store/ui-state.reducer';
import { MenuExpand, MenuColapse, InfoHide, InitDone, RefreshAsp } from './store/ui-state.actions';
import { MatSnackBar } from '@angular/material/snack-bar';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { take, withLatestFrom } from 'rxjs/operators';
import {
  InitUser, Update340BPopup, UpdateUserApplication, GetUserSettingsError, GetDisclaimer, RegenerateUserToken, ShowClientSelection
} from './store/user.actions';
import { UserState } from './store/user.reducer';
import { AppSelected, GetApplications, PageSelected } from './store/application-list.actions';
import { ApplicationMenuItem, Applications } from './models/applications';
import { Actions, ofType } from '@ngrx/effects';
import { Router } from '@angular/router';
import { UserSettings, NDCAPharmacy, UAMUserApplicationPage } from './models/user';
import { AppInsightsService } from './service/appinsights.service'
import { Pages } from "./app-pages";
import { environment } from "../environments/environment";
import { LogTrackingEvent } from "./store/shell.actions";
import { CheckReferralMenu } from './store/automated-referral.actions';
import { B2cLoginService } from './service/b2clogin.service';
import { MsalService } from '@azure/msal-angular'; 
import { CookieService } from 'ngx-cookie-service';
import { b2cPolicies, loginRequest } from './app-config';
import { MatDialog } from '@angular/material/dialog';
import { OrderState } from 'src/app/store/order.reducer';
import { TableGetOptions } from 'src/app/models/received-detail';
import { GetCentralSplitData } from './store/order.actions';
import { MsalBroadcastService } from '@azure/msal-angular';
import { EventType } from '@azure/msal-browser';
import { Subject } from 'rxjs';
import * as Bowser from 'bowser';
import { PAPState } from 'src/app/store/pap.reducer'
import { CorpIdState } from './store/corpid-list/corpid-list.reducer';
import { BulkupdatequalrulesComponent } from './modules/340b-architect/adminsetup/bulkupdatequalrules/bulkupdatequalrules.component';
import { BatchBinExclusionComponent } from './modules/340b-architect/datareview/qualificationfilters/batch-bin-exclusion/batch-bin-exclusion.component';

import { HealthSystemValidationResponse } from './models/health-system-view/health-system';
import { MessageDialogComponent } from './shared/message-dialog/message-dialog.component';
import { SharedDataService } from './service/shared-data.service';
import { UamState } from './store/uam/uam.reducer';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit, OnDestroy {

  public version: string;
  public uiState$: Observable<UiState> = null;
  private infoVisible$: Observable<boolean>;
  private infoMessage$: Observable<string>;

  public appInitialized: boolean = false;
  public callInProgress: boolean = false;
  public clientIdCookie: string;
  public showClientPage: boolean = false;
  public showAccessDenied: boolean = false;

  private firstMenuLoaded = false;
  private firstPageLoaded = false;

  private userDisplayHeight = 450;
  private ndcPharmacy = new NDCAPharmacy();
  private selectedAppId: number;
  public inError: boolean = false;

  isIframe = false;
  loggedIn = false;
  public subscriptions: Subscription[] = [];
  public b2cMessage: string;
  public pwdMessage: string;
  public validateMessage: string;
  public hasMessage: boolean;
  public hasPwdMessage: boolean;
  public hasValidateMessage: boolean;
  seconds: number = 10;
  counterStart: Date;
  counterEnd: Date;
  LastMfaPwdSetOn: Date;
  today: Date;
  offsetdate: Date;
  pwdExpireFlag: boolean;
  popUpFlag: boolean;
  private readonly _destroying$ = new Subject<void>();
  msgButtonLabel: string = "Ok";
  browser = Bowser.getParser(window.navigator.userAgent);
  browserName = this.browser.getBrowser().name;
  healthSystemValidationResponse: HealthSystemValidationResponse;
  isValidated: boolean;
  isMHIEmployee: boolean;

  constructor(private userService: UserService, private snackBar: MatSnackBar, private router: Router, private actions$: Actions
    , private appInsights: AppInsightsService, private store: Store<{
      pidValue: String, uiState: UiState
      , userState: UserState, applications: Applications, orderState: OrderState, corporateParentState: CorpIdState, uamState: UamState
    }>, private b2cloginSrv: B2cLoginService, public dialog: MatDialog
    , private authService: MsalService
    , private cookieService: CookieService
    , private broadcastService: MsalBroadcastService
    , private sharedDataService: SharedDataService
  ) {
    this.isIframe = window !== window.parent && !window.opener;

    this.b2cMessage = '';

    this.hasMessage = this.b2cMessage.length > 0;

    let forceLogout = this.b2cLogin();

    if (forceLogout == false) {
      this.loadUI(0);
    }
    else {
      this.appInitialized = false;
      this.hasMessage = (this.b2cMessage.length > 0);
    }
  }

  configure340Token() {

    this.version = environment.appVersion;

    this.store.dispatch(InitUser());

    this.clientIdCookie = this.userService.readCookie("ClientId");

    this.store.select(state => state.uamState.initialClientList).subscribe((data) => {
      if (data != null) {
        let clientCount = data.chains.length + data.corps.length;
        // Send to access denied if empty list is sent back
        if (clientCount < 1) {
          this.showAccessDenied = true;
        }
        else if (!this.clientIdCookie && clientCount > 1) {
          this.store.dispatch(ShowClientSelection());
        }
        else {
          // user has only 1 corpid or chainId, or has already selected a corpid (second part of the cookie represents whether corpid or chaingroupid)
          let id = Number(this.clientIdCookie?.split('-')[0]) || data.chains[0]?.id || data.corps[0]?.id;
          this.store.dispatch(RegenerateUserToken({ request: {pid: 0, clientId: id, isCorp: this.clientIdCookie ? !!Number(this.clientIdCookie.split('-')[1]) : data.corps?.length > 0} }));
        }
      }
    });

    // show/hide client selection page
    this.store.select(state => state.userState.showClientSelectionPage).subscribe(val => {
      if (val !== null){
        this.showClientPage = val;
      }
    });

    // show/hide Access Denied page
    this.store.select(state => state.userState.showAccessDeniedPage).subscribe(val => {
      if (val !== null){
        this.showAccessDenied = val;
      }
    });


    this.actions$.pipe(ofType(GetUserSettingsError)).subscribe((data) => {
      this.b2cMessage = "Application could not be loaded. Please try logging in again or contact Support.";
      this.hasMessage = true;
      this.msgButtonLabel = "Ok";
    });

    this.store.select(state => state.userState.session).subscribe((val) => {
      if (val != null) {
        //Once user is initialized, check if Referral Matching menu option should show for current PID
        this.store.dispatch(CheckReferralMenu({ uid: val.uid, pid: val.pid }));
        this.store.dispatch(GetCentralSplitData({ options: new TableGetOptions() }));
      }
    });

    this.store.select(state => state.userState.settings).subscribe(userSettings => {
      if (userSettings != null) {
        this.userDisplayHeight = userSettings.displayHeight;
        this.isMHIEmployee = userSettings.isMHIUser;
        if (userSettings.remainingDays <= 5 && userSettings.remainingDays > 0 && userSettings.lastloggedin != 0 && !userSettings.isMHIUser) {
          this.pwdMessage = "Your password will expire in " + userSettings.remainingDays + " days. Please reset your password using the link either in your user profile or on the sign in page.";
          this.pwdExpireFlag = true;
        }
        // PopUp Modal feature is disabled for now
        //if (!userSettings.isMHIUser && userSettings.mfrAudit == 0 && userSettings.display340BMsg <= 0) {
        //  this.popUpFlag = true;
        //}
      }
    })

    this.store.select(state => state.uiState.CallInProgress).subscribe((val) => {
      setTimeout(() => {
        this.callInProgress = val;
      });
    });

    this.store.select(state => state.uiState.InitDone).subscribe((val) => {
      if (val == true && this.appInitialized == false) {
        if (this.pwdExpireFlag) { this.hasPwdMessage = true; }
        if (this.popUpFlag) { this.hasValidateMessage = true; }
        setTimeout(() => {
          this.appInitialized = true;
        });
      }
    });

    var menuState = this.userService.readCookie("MH_Menu_State");

    if (menuState != null && menuState == "SHOW") {
      this.store.dispatch(MenuExpand());
    } else {
      this.store.dispatch(MenuColapse());
    }

    this.uiState$ = this.store.select(state => state.uiState);

    this.store.select(state => state.uiState.MenuVisible).subscribe((val) => this.saveUiState(val));

    this.infoVisible$ = this.store.select(state => state.uiState.InfoVisible);
    this.infoMessage$ = this.store.select(state => state.uiState.InfoMessage);
    this.infoVisible$.pipe(withLatestFrom(this.infoMessage$)).subscribe(([flag, message]) => this.showInfo(flag, message));

    // Get navigation events from ASP-PAGE
    window.addEventListener('message', this.handleMessage.bind(this), false);

    // APP INIT LOGIC.
    combineLatest([this.store.select(state => state.applications), this.store.select(state => state.userState), this.uiState$])
      .subscribe(([apps, user, uiState]) => {

        // WAIT HERE UNTILL WE HAVE ENOUGH DATA LOADED TO SHOW ANY PAGE!!!!
        if (user.userAccessToken == null || user.session == null || user.settings == null || user.userApps == null)
          return;

        this.store.select(state => state.userState.session).subscribe((val) => {
          if (val != null)
            this.ndcPharmacy = val.ndcPharmacyInfo;
        });

        if (!this.firstMenuLoaded && apps.Items != null && user.settings != null) {
          this.appInsights.initializeAppInsights(user.settings.email, user.settings.uid + "");
          this.firstMenuLoaded = true;
          var appLoaded = false;
          if (apps.Selected == null) {
            if (!apps.restoredFromUrl) {
              for (var i = 0; i < apps.Items.length; i++)
                if (apps.Items[i].appID == user.userApps.lastApp) {
                  this.selectedAppId = user.userApps.lastApp;
                  this.store.dispatch(AppSelected({ app: apps.Items[i] }));
                  appLoaded = true;
                  break;
                }
            } else {
              var path = window.location.hash;
              for (var i = 0; i < apps.Items.length; i++)
                if (window.location.hash.includes(apps.Items[i].routePath)) {
                  this.selectedAppId = apps.Items[i].appID;
                  this.store.dispatch(AppSelected({ app: apps.Items[i] }));
                  this.store.dispatch(UpdateUserApplication({ appId: apps.Items[i].appID }));
                  appLoaded = true;
                  break;
                }
            }
            if (!appLoaded) {
              for (var i = 0; i < apps.Items.length; i++)
                if (apps.Items[i].appID == user.userApps.lastApp) {
                  this.selectedAppId = user.userApps.lastApp;
                  this.store.dispatch(AppSelected({ app: apps.Items[i] }));
                  break;
                }
            }
          }
        } else if (!this.firstPageLoaded) {
          var page;
          if (apps.Menu != null && apps.Menu.Sections != null && user.settings != null) {
            if (apps.restoredFromUrl) {
              // FIrst try to go back to the page by the URL, if not successfull, go to quick access page.
              for (var i = 0; i < apps.Menu.Sections.length; i++)
                for (var k = 0; k < apps.Menu.Sections[i].items.length; k++)
                  if (window.location.hash.includes(apps.Menu.Sections[i].items[k].ngPageURL)) {
                    page = apps.Menu.Sections[i].items[k];
                    break;
                  }
              const urlString = window.location.hash.replace("#/", "");
              if (page == null)
                page = Pages[urlString];
              if (page == null)
                page = this.getQuickAccessPage(apps, user.userAppPages, user.settings, this.ndcPharmacy);
            } else {
              page = this.getQuickAccessPage(apps, user.userAppPages, user.settings, this.ndcPharmacy);
            }

            this.store.dispatch(PageSelected({ page: page }));
            if (page.pageID == 70) {
              this.store.dispatch(GetDisclaimer({ pageId: page.pageID }));
            }
            if (window.location.hash.indexOf("?") > 0) {
              var parts = window.location.hash.split("?");
              var queryParams = this.exctractQueryParams(parts[1]);
              //console.log('queryParams: ', queryParams)
              this.router.navigate([page.ngPageURL], { queryParams: queryParams, state: { menuItem: page, application: apps.Selected } });
            } else {
              this.router.navigate([page.ngPageURL], { state: { menuItem: page, application: apps.Selected } });
            }

            this.store.dispatch(InitDone());
            this.firstPageLoaded = true;

            this.store.dispatch(LogTrackingEvent({ eventName: "USER_LOGGED_IN" }))
          }

        } else if (uiState.InitDone && apps.Menu != null && apps.Menu.Sections != null && apps.Menu.Selected == null && user.settings != null) {
          // every time application changes.
          var page2 = this.getQuickAccessPage(apps, user.userAppPages, user.settings, this.ndcPharmacy);
          if (page2 != undefined) {
            this.store.dispatch(PageSelected({ page: page2 }));
            this.router.navigate([page2.ngPageURL], { state: { menuItem: page2, application: apps.Selected } });
          }
        }
      });
  }

  handleMessage(e) {
    // we are listening for navigation messages from ASP page, which should come from mhiapps.com domain.
    //console.log(e);
    if (window.location.protocol + "//" + window.location.host != e.origin
      && (e.origin.includes("localhost") || e.origin.includes("mhiapps.com"))) {
      var route = e.data;

      
      // ASP page has encountered an error, potentially reload page
      if (route.includes("SessionCheck")) {
        this.store.dispatch(RefreshAsp());
      }
      else {
        if (route.includes("bulkupdatequalrules")) {
          this.sharedDataService.QualRulesAccess = true;
           //If Health System Details are not validated external users cannot access  Qualification Rules
              this.store.select(userState => userState.userState.healthSystemValidationResponse).subscribe((res) => {
              if (res && this.sharedDataService.QualRulesAccess) {
                 const daysSinceValidation = res.data.healthSystemViewValidationData[0].daysFromValidation;
                 this.isValidated = daysSinceValidation !== null && daysSinceValidation <= 90;
              if (!this.isValidated && !this.isMHIEmployee) {
                this.sharedDataService.QualRulesAccess = false;
                const dialog = this.dialog.open(MessageDialogComponent, {
                  data: {
                    title: "Bulk Update Qualification Rules Error",
                    message: "Your health system must be validated by going to Admin Setup > Health System View > Validate Health System",
                    btnok: true,
                    btntext: "OK",
                    pannelClass:'no-scroll'
                  },
                  width: '40%'
                });            
              }          
              else
              {
                this.sharedDataService.QualRulesAccess = false;
                const dialog = this.dialog.open(BulkupdatequalrulesComponent, {
                height: '650px',
                width: '950px',
                disableClose: true,
                autoFocus: false,
                restoreFocus: false,
                });
                 return;
              }
            } 
          })      
          return; 
        }
        
        if (route != null && route.includes("?")) {
          var parts = route.split("?");
          route = parts[0];
          var queryParams = this.exctractQueryParams(parts[1]);
        }

        if (route.includes('BulkBinExclusion')) {
          let recordList = route.replace("BulkBinExclusion","");
          let dialogRef = this.dialog.open(BatchBinExclusionComponent, {
            data: { modalTitle: 'Excluded by BIN Batch Update', binList: recordList.length > 0 ? recordList : null },
            height: '655px',
            width: '950px',
            minHeight: '655px',
            minWidth: '900px',
            panelClass: 'bulk-bin-dialog',
            disableClose: true
          });
          return;
        }

        /*
        MH-13979
        Author: Tri Nguyen (eid: edmx8g3)
        Redirect the user to Charges Work Queue when they click on either green or yellow button on NDCA Dashboard
        */
        if (this.selectedAppId === 3) {
          if (!(this.ndcPharmacy?.finalBillingInstall === 1 || this.ndcPharmacy?.finalBillingInstall === 2)) {
            route = '/NDCArchitect/charge-work-queue';
          } else {
            route = '/NDCArchitect/claims-analysis';
          }
        }

        this.store.select(state => state.applications).pipe(take(1)).subscribe(apps => {
          var page;
          for (var i = 0; i < apps.Menu.Sections.length; i++)
            for (var k = 0; k < apps.Menu.Sections[i].items.length; k++)
              if (route.includes(apps.Menu.Sections[i].items[k].ngPageURL)) {
                page = apps.Menu.Sections[i].items[k];
                break;
              }
          this.router.navigate([route], { queryParams: queryParams, state: { menuItem: page, application: apps.Selected } });
        });
        //}
      }

    }
  }

  exctractQueryParams(params) {
    var data = params.split("&");
    var queryParams = {};
    for (var i = 0; i < data.length; i++) {
      var parm = data[i].split("=");
      if (parm[0] != "token")
        queryParams[parm[0]] = decodeURIComponent(parm[1]);
    }
    return queryParams;
  }

  getQuickAccessPage(apps: Applications, pages: UAMUserApplicationPage[], settings: UserSettings, ndcPharmacy: NDCAPharmacy): ApplicationMenuItem {
    let selectedAppAllowedPageIds = pages.filter(x => x.appID == apps.Selected.appID).map(x => x.pageID);
    let appMenuItems = apps.Menu.Sections.map(s => s.items).reduce((prev, curr) => prev.concat(curr));
    // get favorite page if available
      let page = appMenuItems.find(p => p.pageID == selectedAppAllowedPageIds.find(x => x == settings.architect340BQuickAccessPageID || x == settings.macroManagerQuickAccessPageID || x == settings.gatewayQuickAccessPageID));
    //default to first page user has available within the app if no favorite found
    if (page == null){
      page = appMenuItems.find(p => selectedAppAllowedPageIds.includes(p.pageID));
    }

    /*
    Special case:
    Set Data Review / Claims Analysis as the default landing page on NDCA if user's final billing install === 1 or 2.
    */
    if (apps.Selected.appID === 3) {
      if (selectedAppAllowedPageIds.includes(Pages.CLAIM_ANALYSIS_MENU_ITEM_NDC.pageID) && (ndcPharmacy?.finalBillingInstall === 1 || ndcPharmacy?.finalBillingInstall === 2)){
        page = Pages.CLAIM_ANALYSIS_MENU_ITEM_NDC;
      }
    }


    /*
    Set Access Management as default landing page for User Access Management
    */
    if (page == null && apps.Selected.appID == 8) {
      page = apps.Menu.Sections[0].items[0];
    }

    return page;
  }

  saveUiState(state: boolean) {
    if (state) {
      this.userService.createCookie("MH_Menu_State", "SHOW", null);
    }
    else {
      this.userService.createCookie("MH_Menu_State", "HIDE", null);

    }
  }

  showInfo(state: boolean, message: string) {
    if (state && message === 'Your default landing page has been updated') {
      var ref = this.snackBar.open(message, "Close", { duration: 5000, horizontalPosition: 'right', verticalPosition: 'top', panelClass: 'snackbar-precision' });
      //      ref.afterDismissed().subscribe( () => { this.store.dispatch( new InfoHide() ) } );
    }
    /*
    else if (state && message === 'Your recalculation request has been submitted. We�ll notify you when it�s complete.') {
      var ref = this.snackBar.open(message, "Close", { duration: 5000, horizontalPosition: 'right', verticalPosition: 'top', panelClass: 'snackbar-precision' });
      //      ref.afterDismissed().subscribe( () => { this.store.dispatch( new InfoHide() ) } );

    }
    */
    else if (state) {
      var ref = this.snackBar.open(message, "Close", { duration: 10000, horizontalPosition: 'right', verticalPosition: 'top', panelClass: 'snackbar-precision' });
      //      ref.afterDismissed().subscribe( () => { this.store.dispatch( new InfoHide() ) } );

    }
    this.store.dispatch(InfoHide());
  }

  setAppHeight() {
    switch (this.userDisplayHeight) {
      case 450:
        return 80;
      case 500:
        return 81;
      case 550:
        return 82;
      case 600:
        return 83;
      case 650:
        return 84;
      case 700:
        return 85;
      case 750:
        return 86;
      case 800:
        return 87;
    }
  }

  /*  b2c logic start */

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

  ngOnInit() {
  }

  loadUI(i: number) {


    this.loggedIn = this.b2cloginSrv.hasB2cToken();

    if (this.loggedIn) {
      this.configure340Token();
    }
    else {

      setTimeout(() => {
        if (!this.loggedIn) {
          this.loadUI(i++);
        } else {
          this.configure340Token();
        }
      }, 3000);

    }
  }

  b2cLogin(): boolean {

    const uri = document.location.toString();
    let loginSuccessSubscription: Subscription;
    let forceLogout: boolean = false;
    this.b2cMessage = '';
    let domain: string;
    let b2cLoginError;

    this.isIframe = window !== window.parent && !window.opener;

    //signup new user
    if (uri.indexOf('?') > 0
      && uri.toLowerCase().indexOf('_onboardingsignup/oauth2/v2.0/authorize?client_id=') > 0
      && uri.toLowerCase().indexOf('&id_token_hint=') > 0) {
      let id_token_hint = uri.substring(uri.indexOf('id_token_hint=') + 14);
      this.b2cloginSrv.signupredirect(id_token_hint);
      return false;
    }
    else if (uri.indexOf('?') > 0 && uri.toLowerCase().indexOf('&domain_hint=mckessonconnect.com') > 0) {
      this.b2cloginSrv.connectSignIn();
      return false;
    }

    loginSuccessSubscription = this.broadcastService.msalSubject$
      .subscribe((result) => {

        //console.log(result);
        if (result.eventType === EventType.HANDLE_REDIRECT_START && this.b2cloginSrv.readCookie("mh_userAuth") === null) {
          this.b2cloginSrv.LogoutCleanup();
          this.b2cloginSrv.createCookie("mh_userAuth", Date.now().toString(), null);
        }
        else if (result.eventType === EventType.LOGIN_FAILURE) {
          b2cLoginError = result.error.message;
          if (b2cLoginError.indexOf('AADB2C90118') > -1) {
            //this.b2cloginSrv.ForgotPassword();
            forceLogout = false;
          }

          console.log(b2cLoginError);
        }

        if (result.eventType === EventType.LOGIN_SUCCESS || result.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) {
          let pl: any = result?.payload;
          let tk: any = pl?.idToken;
          let acr: any = pl.account.idTokenClaims.acr;

          if (acr) {
            this.b2cMessage = '';
            if (acr === b2cPolicies.names.resetMfa.toLowerCase().trim()) {
              this.b2cMessage = 'Your 2FA option has been reset successfully. You must now sign in again.';
            }
            else if (acr === b2cPolicies.names.resetPassword.toLowerCase().trim()) {
              this.b2cMessage = 'Password has been reset successfully.  You must now sign in again.';
            }
            else if (acr === b2cPolicies.names.forgotpassword.toLowerCase().trim()) {
              this.b2cMessage = 'Password has been reset successfully.  You must now sign in again.';
              this.b2cloginSrv.LogoutCleanup();
            }
            else if (acr === b2cPolicies.names.signup.toLowerCase().trim()) {
              this.b2cMessage = "Your account has been created successfully.  You must now sign in again.";
              this.b2cloginSrv.LogoutCleanup();
            }
            else {
              this.b2cloginSrv.setB2cIdToken(tk);
              forceLogout = false;
              this.b2cloginSrv.createB2cUser();
            }

            this.hasMessage = (this.b2cMessage.length > 0);

            if (this.hasMessage) {
              forceLogout = true;
              this.appInitialized = false;
              this.msgButtonLabel = "Sign In";
            }
          }
        }
      });


    //if (result.eventType === EventType.ACQUIRE_TOKEN_FAILURE || result.eventType === EventType.SSO_SILENT_FAILURE) {
    //  this.b2cloginSrv.LogoutCleanup();
    //}

    //});

    this.authService.instance.handleRedirectPromise()
      .catch(error => {

        console.log(error);

        if (error) {
          if (error.toString().indexOf('AADB2C90118') > -1) {
            this.b2cloginSrv.ForgotPassword();
            forceLogout = false;
          }
          else if (error.toString().indexOf('AADB2C90091') > -1 || error.toString().indexOf('AADB2C90208') > -1) {
            this.b2cloginSrv.login();
          }
        }
      });

    this.loggedIn = this.b2cloginSrv.hasB2cToken();

    if (!this.loggedIn && !forceLogout) {
      this.authService.loginRedirect().toPromise().catch(err => {
        console.log(err);
      });
    }

    this.subscriptions.push(loginSuccessSubscription);

    return forceLogout;
  }

  forceLogin(flg: number) {
    if (flg == 1) {
      this.b2cloginSrv.LogoutCleanup();
      this.b2cloginSrv.login();
    }
    else if (flg == 2) {
      this.b2cloginSrv.LogoutCleanup();
      this.b2cloginSrv.logout();
    }
    else if (flg == 3) {
      this.b2cloginSrv.logout();
    }

  }

  closeMsg() {
    this.hasPwdMessage = false;
  }

  NotInterested() {
    //set Display340BDate as null
    this.store.dispatch(Update340BPopup({ displayDate: 0 }));
    this.hasValidateMessage = false;
  }
  RemindinWeek() {
    //set Display340BDate as getdate + 5
    this.store.dispatch(Update340BPopup({ displayDate: 1 }));
    this.hasValidateMessage = false;
  }
  OpenHelpJuice() {
    var url = environment.MacroHelixSupportUrl;
    if (this.browserName === 'Internet Explorer') {
      let params = 'scrollbars=yes, fullscreen=yes, resizable=yes, status=no, location=no, toolbar=no, menubar=no, width=800,height=800,left=' + (window.screenX + 300) + ',top=' + (window.screenY + 100);
      window.open(url, 'Macro Helix Support', params);
    } else if (this.browserName === 'Chrome') {
      let params = 'scrollbars=yes, fullscreen=yes, resizable=yes, status=no, location=no, toolbar=no, menubar=no, width=800,height=800,left=' + (window.screenX + 100) + ',top=600';
      window.open(url, 'Macro Helix Support', params);
    } else {
      let params = 'scrollbars=yes, fullscreen=yes, resizable=yes, status=no, location=no, toolbar=no, menubar=no, width=800,height=800,left=' + (window.screenX + 300) + ',top=' + (window.screenY + 100);
      window.open(url, 'Macro Helix Support', params);
    }

    this.store.dispatch(Update340BPopup({ displayDate: 1 }));
    this.hasValidateMessage = false;
  }

  /* b2c logic end */

}
