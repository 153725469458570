import { Injectable } from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import { of as observableOf } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import {PbiEmbeddingService} from 'src/app/service/pbiembedding.service';
import {
  AnalyticsActionTypes,
  EmbedDashboard,
  EmbedDashboardError,
  EmbedDashboardResult,
  EmbedReport,
  EmbedReportError,
  EmbedReportResult,
  EmbedReportSilent,
  EmbedReportSilentResult,
  EmbedTile, EmbedTileError, EmbedTileResult,
  GetDashboardsError,
  GetDashboardsResults,
  GetReportPages,
  GetReportPagesError,
  GetReportPagesResults,
  GetReportsError,
  GetReportsResults,
  GetTilesError,
  GetTilesResults
} from './analytics.actions';

@Injectable()
export class AnalyticsStoreEffects {
  constructor(private embedingService: PbiEmbeddingService, private actions$: Actions) {}

  loadReportsEffect$ = createEffect(() => this.actions$.pipe(
    ofType( AnalyticsActionTypes.REPORTS_GET ),
    switchMap(action =>
      this.embedingService
        .getListOfReports()
        .pipe(
          map(
            items => GetReportsResults( { data: items } )
          ),
          catchError(error => {
            return observableOf(GetReportsError( { errorMessage: error } ))
          })
        )
    )
  ));

  loadReportPagesEffect$ = createEffect(() => this.actions$.pipe(
    ofType( GetReportPages ),
    switchMap(action =>
      this.embedingService
        .getListOfReportPages(action.id, action.pid)
        .pipe(
          map(
            items => GetReportPagesResults( { data: items } )
          ),
          catchError(error => {
            return observableOf(GetReportPagesError( { errorMessage: error } ))
          })
        )
    )
  ));


  loadDashboardsEffect$ = createEffect(() => this.actions$.pipe(
    ofType( AnalyticsActionTypes.DASHBOARDS_GET ),
    switchMap(action =>
      this.embedingService
        .getListOfDashboards()
        .pipe(
          map(
            items => GetDashboardsResults( { data: items } )
          ),
          catchError(error => {
            return observableOf(GetDashboardsError({ errorMessage: error}))
          })
        )
    )
  ));

  loadTilesEffect$ = createEffect(() => this.actions$.pipe(
    ofType( AnalyticsActionTypes.TILES_GET ),
    switchMap(action =>
      this.embedingService
        .getListOfTiles()
        .pipe(
          map(
            items => GetTilesResults( { data:items } )
          ),
          catchError(error => {
            return observableOf( GetTilesError({ errorMessage:error }) )
          })
        )
    )
  ));


  embedReportsEffect$ = createEffect(() => this.actions$.pipe(
    ofType( EmbedReport ) ,
    switchMap(action =>
      this.embedingService
        .getReportsEmbed( action.id, action.pid )
        .pipe(
          map(
            items => EmbedReportResult( { data: items } )
          ),
          catchError(error => {
            return observableOf( EmbedReportError( { errorMessage: error } ))
          })
        )
    )
  ));

  embedReportsSilentEffect$ = createEffect(() => this.actions$.pipe(
    ofType( EmbedReportSilent ) ,
    switchMap(action =>
      this.embedingService
        .getReportsEmbed( action.id, action.pid )
        .pipe(
          map(
            items => EmbedReportSilentResult( { data: items } )
          ),
          catchError(error => {
            return observableOf( EmbedReportError( { errorMessage: error } ))
          })
        )
    )
  ));


  embedDashboardEffect$ = createEffect(() => this.actions$.pipe(
    ofType( EmbedDashboard ) ,
    switchMap(action =>
      this.embedingService
        .getDashboardEmbed( action.id )
        .pipe(
          map(
            items => EmbedDashboardResult( { data: items } )
          ),
          catchError(error => {
            return observableOf( EmbedDashboardError( { errorMessage: error } ))
          })
        )
    )
  ));


  embedTileEffect$ = createEffect(() => this.actions$.pipe(
    ofType( EmbedTile ) ,
    switchMap(action =>
      this.embedingService
        .getTileEmbed( action.dashid ,action.id )
        .pipe(
          map(
            items => EmbedTileResult( { data: items } )
          ),
          catchError(error => {
            return observableOf( EmbedTileError( { errorMessage: error } ))
          })
        )
    )
  ));

}
