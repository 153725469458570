import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { catchError, map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { UserSession } from "../models/user";
import { UserState } from "../store/user.reducer";
import { BaseMhService } from "./basemh.service";

@Injectable({
  providedIn: 'root'
})

export class healthSystemViewService extends BaseMhService {

  constructor(protected http: HttpClient, protected store: Store<{ userState: UserState }>) {
    super(http, store);
  }

  public getCorporateParentTreeview(corpId: number) {
    return this.http
      .get(environment.apiBaseUrl + 'v1/CorporateParent/GetCorporateParentTreeViewByCorpId?CorpId=' + corpId, { headers: this.getHeaders() })
      .pipe(
        map((response: any) => response),
        catchError(error => this.errorHandler({ error, operation: "Error while retrieving CorpID data" }))
      );

  }

  public UpdateCorporateParentValidationStatus() {
    const session: UserSession = this.getStoreUserSession();
    let corpID = session.corpID;
    let uID = session.uid;
    return this.http
    .post(environment.apiBaseUrl + 'v1/CorporateParent/HealthSystemViewValidation?CorpID=' + corpID + '&UID=' + uID, null,{ headers: this.getHeaders() })
      .pipe(
        map((response: any) => response),
        catchError(error => this.errorHandler({ error, operation: "Error while updating Corporate Parent Validation Status" }))
      );

  }
}
