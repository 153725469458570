import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogData {
  title: '';
  message: '';
  param1: '';
  param2: '';
  param3: '';
  needParam1: false;
  needParam2: false;
  needParam3: false;
  msg1: '';
  msg2: '';
  msg3: '';
  btnok: true;
  btntext: '';
  showCancelBtn?: false;
  btnCancelText?: '';
}

/**
 * @title Injecting data when opening a dialog
 */
@Component({
  selector: 'message-dialog',
  templateUrl: './message-dialog.component.html',
  styleUrls: ['./message-dialog.component.scss'],
})

export class MessageDialogComponent {
  public flag1: boolean = false;
  public flag2: boolean = false;
  public flag3: boolean = false;
  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData) {
    //console.log("button " + data.btnok);
    if ((data.param1 == '' || data.param1 == undefined) && data.needParam1) { this.flag1 = false; } else { this.flag1 = true; }
    if ((data.param2 == '' || data.param2 == undefined) && data.needParam2) { this.flag2 = false; } else { this.flag2 = true; }
    if ((data.param3 == '' || data.param3 == undefined) && data.needParam3) { this.flag3 = false; } else { this.flag3 = true; }
  }
}


